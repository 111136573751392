import Axios from "./CustomAxios";

export interface ImportStudentResponse {
	externalId: string;
	name: string;
	birthDate: string;
	startDate: string;
	className: string;
	action: string; //skip, create, update
}

export const ImportFromCSV = async (csvFileContent: string): Promise<ImportStudentResponse[]> => {
	let result = (await Axios.post(`/rsvidnesbyrd/import/importFromCSV`, csvFileContent)).data;
	return result.data.map((s: string): ImportStudentResponse => {
		s = s.replace(/[\n\r]/g, "");
		let json = JSON.parse(s);
		return {
			externalId: json["externalId"],
			name: json["name"],
			birthDate: json["birthDate"],
			startDate: json["startDate"],
			className: json["className"],
			action: json["action"],
		};
	});
};

export const ReadUploadedFileAsText = (inputFile: File) => {
	const temporaryFileReader = new FileReader();
	return new Promise((resolve, reject) => {
		temporaryFileReader.onerror = () => {
			temporaryFileReader.abort();
			reject(new DOMException("Problem parsing input file."));
		};

		temporaryFileReader.onload = () => {
			let result = temporaryFileReader.result;
			resolve(result);
		};
		//TODO the encoding may not work when people come with different encodings
		temporaryFileReader.readAsText(inputFile, "windows-1252");
	});
};
