import Year from "../model/Year";
import Axios from "./CustomAxios";

export const AdvanceYear = async (): Promise<Year> => {
	return (await Axios.post(`/rsvidnesbyrd/year/advance`, {})).data;
};

export const GetCurrentSchoolYear = async (): Promise<Year> => {
	return (await Axios.get(`/rsvidnesbyrd/year/current`, {})).data;
};

export const GetAllYears = async (): Promise<Year[]> => {
	return (await Axios.get(`/rsvidnesbyrd/year/all`, {})).data;
};

export const SetCurrentYear = async (year: Year): Promise<any> => {
	return (await Axios.put(`/rsvidnesbyrd/year/setCurrentYear`, year)).data;
};
