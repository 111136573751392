import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import * as Constants from "../Constants";
import User from "../model/User";
import { ShowEditUserModal } from "../pages/admin/user/ManageUserModal";
import { GetCourse } from "../services/Course";
import ProfileColoredCircle from "./ProfileColoredCircle";

/**
 * @param onUserInfoUpdate when the user is updated by clicking your name in header and modifying it, trigger this event
 */
type HeaderProps = { onRoleChange: (role: Constants.UserRoles) => any; user: User | undefined; onUserInfoUpdate: () => any };

export default function Header({ onRoleChange, user, onUserInfoUpdate }: HeaderProps) {
	const location = useLocation();
	const { t } = useTranslation();
	const [pageName, setPageName] = useState("");
	const [selectedRole, setSelectedRole] = useState<{ label: string; value: Constants.UserRoles }>();

	useEffect(() => {
		// select the first role automatically, this also triggers the side menu rendering which sets up the side menu
		if (user && user.roles.length > 0) {
			OnRoleSelect(user.roles[0]);
		}
	}, [user]);

	//this effect updates page name in header each time location changes
	useEffect(() => {
		const pageNames = [
			//admin

			{ route: "/adminOverview", name: t("Overview") },
			{ route: "/adminUsers", name: t("Manage users") },
			{ route: "/adminSubjects", name: t("Manage subjects") },
			{ route: "/adminCourses", name: t("Manage courses") },
			{ route: "/adminClasses", name: t("Manage classes") },
			{ route: "/adminYearTasks", name: t("Manage year tasks") },
			{ route: "/adminTaskSSO", name: t("Manage SSO") },
			{ route: "/adminTaskEmneopgaver", name: t("Manage Emneopgaver") },
			{ route: "/adminStudents", name: t("Manage students") },
			{ route: "/adminImport", name: t("Import data") },
			{ route: "/adminGeneratePDF", name: t("Generate PDF") },
			{ route: "/manageYear", name: t("Manage school years") },
			{ route: "/adminOther", name: t("Manage other data") },
			{ route: "/adminEditTaskSSO", name: t("SSO") },
			{ route: "/adminEditTaskEmneopgaver", name: t("Emneopgaver") },
			{ route: "/adminEditYearTasks", name: t("Year task") },

			//proofreader
			{ route: "/proofReadOverview", name: t("Overview") },
			{ route: "/proofReadCourses", name: t("Proofread courses") },
			{ route: "/proofReadEvaluations", name: t("Proofread evaluations") },
			{ route: "/proofReadYearTasks", name: t("Proofread year tasks") },
			{ route: "/proofReadTaskSSO", name: t("Proofread SSO") },
			{ route: "/proofReadTaskEmneopgaver", name: t("Proofread emneopgaver") },

			//teacher
			{ route: "/teacherOverview", name: t("Courses overview") },
			{ route: "/teacherYearTasks", name: t("Year task") },
			{ route: "/teacherTaskSSO", name: t("SSO") },
			{ route: "/teacherTaskEmneopgaver", name: t("Emneopgaver") },
			{ route: "/noCourses", name: t("No courses") },

			//others
			{ route: "/noRoles", name: t("No roles") },
			{ route: "/", name: t("Loading") + "..." },
		];

		try {
			setPageName(pageNames.filter((n) => n.route === location.pathname)[0].name);
		} catch (e) {
			setPageName(`Define page name in Header.tsx with route ${location.pathname}`);
		}

		//special header name for course pages
		//fetch the course and use it's name in header
		if (location.pathname.includes("/course/")) {
			setPageName("");
			let courseId = parseInt(location.pathname.replaceAll("/course/", ""));
			GetCourse(courseId).then((course) => {
				setPageName(course.name);
			});
		}
	}, [location.pathname]);

	const LogOut = () => {
		window.localStorage.removeItem(Constants.LocalStorageKeys.credentials);
		window.localStorage.removeItem(Constants.LocalStorageKeys.currentUser);
		window.location.href = Constants.clientUrl; //user will sign in again
	};

	const OnRoleSelect = (role: Constants.UserRoles) => {
		setSelectedRole({ label: t(role), value: role });
		onRoleChange(role);
	};

	const EditUserDetails = () => {
		if (!user) return;
		ShowEditUserModal(user.id, onUserInfoUpdate, true);
	};

	let roleHTML;
	if (user?.roles.length === 0) {
		roleHTML = <div style={{ color: "#666", fontSize: "0.9rem" }}>{t("No roles")}</div>;
	}
	if (user?.roles.length === 1) {
		roleHTML = <div style={{ color: "#666", fontSize: "0.9rem" }}>{t(user?.roles[0])}</div>;
	}
	if (user && user?.roles.length > 1) {
		let selectOptions = user.roles.map((r) => ({ label: t(r), value: r }));

		roleHTML = (
			<div style={{ minWidth: 100 }}>
				<Select
					value={selectedRole}
					options={selectOptions}
					styles={Constants.HeaderRoleDropdownStyle}
					isSearchable={false}
					onChange={(selection) => {
						if (!selection?.value) return;
						OnRoleSelect(selection?.value);
					}}
				/>
			</div>
		);
	}

	return (
		<div className="header">
			<div className="page-name">{pageName}</div>
			{user && <ProfileColoredCircle size="36px" username={user.name} onClick={EditUserDetails} />}
			<div style={{ marginRight: 32, marginLeft: 8 }}>
				<div style={{ fontWeight: 500, cursor: "pointer" }} onClick={EditUserDetails}>
					{user?.name}
				</div>
				{roleHTML}
			</div>
			<button className="btn" onClick={() => LogOut()}>
				<Icon.LogOut />
				{t("Log out")}
			</button>
		</div>
	);
}
