import { useTranslation } from "react-i18next";
import { DescriptionStatus } from "../Constants";

// colors are defined here because they are also used in teacher overview charts
export const DescriptionStatusColor = [
	"#9e9e9e" /* created, grey */,
	"#ff8f00" /* ready for pr, yellow */,
	"#2196f3" /* proofread, blue */,
	"#4caf50" /* done, green */,
];

type DescriptionStatusBadgeProperties = { status: DescriptionStatus };
export default function DescriptionStatusBadge({ status }: DescriptionStatusBadgeProperties) {
	const { t } = useTranslation();

	switch (status) {
		case DescriptionStatus.Created:
			return (
				<div className="badge grey" style={{ backgroundColor: DescriptionStatusColor[0] }}>
					{t(`descriptionStatus.${status}`)}
				</div>
			);
		case DescriptionStatus.ReadyForProofRead:
			return (
				<div className="badge yellow" style={{ backgroundColor: DescriptionStatusColor[1] }}>
					{t(`descriptionStatus.${status}`)}
				</div>
			);
		case DescriptionStatus.ProofRead:
			return (
				<div className="badge blue" style={{ backgroundColor: DescriptionStatusColor[2] }}>
					{t(`descriptionStatus.${status}`)}
				</div>
			);
		case DescriptionStatus.Done:
			return (
				<div className="badge green" style={{ backgroundColor: DescriptionStatusColor[3] }}>
					{t(`descriptionStatus.${status}`)}
				</div>
			);
	}
}
