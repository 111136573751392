import { TaskType, UserRoles } from "../Constants";
import Task from "../model/Task";
import Axios from "./CustomAxios";

/**
 * Get all available year tasks from the same year as the last school year and from the same school as the signed in user.
 */
export const GetAllAvailableForAdmin = async (taskType: TaskType): Promise<Task[]> => {
	return (await Axios.get(`/rsvidnesbyrd/task/allForAdmin/${taskType}`, {})).data;
};

/**
 * Similar to @see {GetAllAvailable}, but this returns them for a single teacher (the current user making the request)
 */
export const GetAllAvailableForTeacher = async (taskType: TaskType): Promise<Task[]> => {
	return (await Axios.get(`/rsvidnesbyrd/task/allForTeacher/${taskType}`, {})).data;
};

/**
 * Similar to @see {GetAllAvailable}, but this returns them for a single proofreader (the current user making the request).
 * Only proofread and readyForProfread statuses are included.
 */
export const GetAllAvailableForProofreader = async (taskType: TaskType): Promise<Task[]> => {
	return (await Axios.get(`/rsvidnesbyrd/task/allForProofreader/${taskType}`, {})).data;
};


export const GetAllAvailableTasks = async (taskType: TaskType, userRole : UserRoles): Promise<Task[]> => {
	if(userRole === UserRoles.Admin){
		return (await Axios.get(`/rsvidnesbyrd/task/allForAdmin/${taskType}`, {})).data;
	} else if(userRole === UserRoles.ProofReader) {
		return (await Axios.get(`/rsvidnesbyrd/task/allForProofreader/${taskType}`, {})).data;
	} else {
		return (await Axios.get(`/rsvidnesbyrd/task/allForTeacher/${taskType}`, {})).data;
	}
};
export const GetById = async (id: number): Promise<Task> => {
	return (await Axios.get(`/rsvidnesbyrd/task/${id}`, {})).data;
};

export const Update = async (yearTask: Task): Promise<any> => {
	return (await Axios.post(`/rsvidnesbyrd/task/update`, yearTask)).data;
};

export const UpdateDefaultTexts = async (taskType: TaskType, texts: { description: string; studentDescription: string }) => {
	return (await Axios.post(`/rsvidnesbyrd/task/defaultTexts/update/${taskType}`, texts)).data;
};

export const GetDefaultTexts = async (taskType: TaskType): Promise<{ description: string; studentDescription: string }> => {
	return (await Axios.get(`/rsvidnesbyrd/task/defaultTexts/${taskType}`, {})).data;
};

export const GetOverview = async (userRole : UserRoles): Promise<any> => {
	if (userRole === UserRoles.Admin){
		return (await Axios.get(`/rsvidnesbyrd/task/overview`)).data;
	} else if(userRole === UserRoles.Teacher){
		return (await Axios.get(`/rsvidnesbyrd/task/overviewTeacher`)).data;
	} else if(userRole === UserRoles.ProofReader){
		return (await Axios.get(`/rsvidnesbyrd/task/overviewProofReader`)).data;
	}
};

export const getTaskPage = async (taskType : TaskType, userRole : UserRoles): Promise<any> => {

	if(taskType === TaskType.YearTask){
		if (userRole === UserRoles.Admin){
			return '/adminEditYearTasks';
		} else if(userRole === UserRoles.Teacher){
			return '/teacherYearTasks';
		} else if(userRole === UserRoles.ProofReader){
			return '/proofReadYearTasks';
		}
	} else if(taskType === TaskType.SSO){
		if (userRole === UserRoles.Admin){
			return '/adminEditTaskSSO';
		} else if(userRole === UserRoles.Teacher){
			return '/teacherTaskSSO';
		} else if(userRole === UserRoles.ProofReader){
			return '/proofReadTaskSSO';
		}
	} else if(taskType === TaskType.Emneopgave){
		if (userRole === UserRoles.Admin){
			return '/adminEditTaskEmneopgaver';
		} else if(userRole === UserRoles.Teacher){
			return '/teacherTaskEmneopgaver';
		} else if(userRole === UserRoles.ProofReader){
			return '/proofReadTaskEmneopgaver';
		}
	}
};	
