import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { CloseGeneralModal } from "../../components/GeneralModal";
import NormalButton from "../../components/input/NormalButton";
import { TaskType } from "../../Constants";
import { GetDefaultTexts, UpdateDefaultTexts } from "../../services/Task";

interface Props {
	taskType: TaskType;
}
export default function EditDefaultTextsModal({ taskType }: Props) {
	const { t } = useTranslation();
	const [isSaving, setIsSaving] = useState(false);
	const [description, setDescription] = useState("");
	const [studentDescription, setStudentDescription] = useState("");

	useEffect(() => {
		GetDefaultTexts(taskType).then((texts) => {
			setDescription(texts.description);
			setStudentDescription(texts.studentDescription);
		});
	}, [taskType]);

	const SaveTexts = async () => {
		setIsSaving(true);
		await UpdateDefaultTexts(taskType, { description, studentDescription });
		setIsSaving(false);
		CloseGeneralModal();
	};

	return (
		<div style={{ width: "50vw" }}>
			<div className="independent-label">
				{t(`taskType.${taskType}`)} {t("description")}
			</div>
			<textarea value={description} onChange={(e) => setDescription(e.currentTarget.value)} style={{ width: "100%", height: "45vh" }}></textarea>
			<div className="independent-label" style={{ marginTop: 12 }}>
				{t(`taskType.${taskType}`)} {t("student description")}
			</div>
			<textarea value={studentDescription} onChange={(e) => setStudentDescription(e.currentTarget.value)} style={{ width: "100%", height: "15vh" }}></textarea>
			<div className="info-text">{t("This text appears in the PDF if the student description is not completed")}</div>
			<div style={{ marginTop: 12, textAlign: "right" }}>
				<NormalButton icon={<Icon.Save />} text={t("Save")} onClick={SaveTexts} loading={isSaving} />
			</div>
		</div>
	);
}
