import Comment from "../model/Comment";
import ProfileColoredCircle from "./ProfileColoredCircle";
import moment from "moment";

type CommentProps = { comment: Comment };

export default function CommentComponent({ comment }: CommentProps) {
	return (
		<div style={{ marginBottom: 24, display: "flex" }}>
			<div style={{ width: 32, marginRight: 8 }}>
				<ProfileColoredCircle username={comment.user?.name || ""} size="32px" />
			</div>
			<div>
				<div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
					<div style={{ fontWeight: 500 }}>{comment.user?.name}</div>
					&nbsp;&#8226;&nbsp;
					<div className="info-text">{moment(comment.timestamp).format("DD-MM-YYYY HH:mm")}</div>
				</div>
				{/* whiteSpace:pre is neccessary to display \n correctly */}
				<div style={{ whiteSpace: "pre-line" }}>{comment.text}</div>
			</div>
		</div>
	);
}
