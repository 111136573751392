import Axios from "./CustomAxios";
import Description from "../model/Description";
import { DescriptionStatus } from "../Constants";
import DescriptionText from "../model/DescriptionText";

export const UpdateDescription = async (description: Description): Promise<Description> => {
	return (await Axios.put(`/rsvidnesbyrd/description/update`, description)).data;
};

/**
 * The server will create new text, or update existing text on it's own.
 * @param descText should contain only the text, and byTeacher.
 */
export const SaveOrUpdateDescriptionText = async (descriptionId: number, descText: DescriptionText) => {
	return (await Axios.post(`/rsvidnesbyrd/description/${descriptionId}/saveOrUpdateText`, descText)).data;
};

export const UpdateDescriptionStatus = async (descriptionId: number, status: String): Promise<any> => {
	const descriptionStatus = { "status": status };
	return (await Axios.put(`/rsvidnesbyrd/description/updateStatus/${descriptionId}`, descriptionStatus)).data;
};
