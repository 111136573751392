import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Cell, Pie, PieChart } from "recharts";
import DescriptionStatusBadge, { DescriptionStatusColor } from "../../../components/DescriptionStatusBadge";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import { DescriptionStatus, TaskType } from "../../../Constants";
import { GetOverview, getTaskPage } from "../../../services/Task";
import { RootState } from "../../../app/store";
import { useSelector } from 'react-redux';

/**
 * Displays a list of task overviews (SSO, Arsopgaven, Emneopgaver) 
 */
export default function TasksSection() {
	const { t } = useTranslation();
	const history = useHistory();
	const selectOptions = [
		{ label: t("All"), value: undefined },
		{ label: t(`taskType.${TaskType.Emneopgave}`), value: TaskType.Emneopgave },
		{ label: t(`taskType.${TaskType.SSO}`), value: TaskType.SSO },
		{ label: t(`taskType.${TaskType.YearTask}`), value: TaskType.YearTask },
	];
	const outerChartRadius = 50;
	const innerChartRadius = 30;

	const [filterOption, setFilterOption] = useState(selectOptions[0]);
	const [overviewData, setOverviewData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const userRole = useSelector((state: RootState) => state.activeRole);

	useEffect(() => {
		setIsLoading(true);
		GetOverview(userRole)
			.then((overview) => {
				setOverviewData(overview);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [userRole]);

	const displayTasks = async (taskType : TaskType) => {
		history.push(await getTaskPage(taskType, userRole))
	};

	const GetOverviewColumn = (taskType: TaskType) => {
		// data still loading, return nothing
		if (!overviewData || !(overviewData as any)[taskType]) return <div></div>;

		const data = (overviewData as any)[taskType];

		const chartData = [
			{
				name: t(`descriptionStatus.${DescriptionStatus.Created}`),
				value: Object.values(data).filter((v) => v === DescriptionStatus.Created).length,
			},
			{
				name: t(`descriptionStatus.${DescriptionStatus.ReadyForProofRead}`),
				value: Object.values(data).filter((v) => v === DescriptionStatus.ReadyForProofRead).length,
			},
			{
				name: t(`descriptionStatus.${DescriptionStatus.ProofRead}`),
				value: Object.values(data).filter((v) => v === DescriptionStatus.ProofRead).length,
			},
			{
				name: t(`descriptionStatus.${DescriptionStatus.Done}`),
				value: Object.values(data).filter((v) => v === DescriptionStatus.Done).length,
			},
		];

		/** Builds a row in the table with the legend for the pie chart */
		const StudentRow = (index: number) => {
			return (
				<tr>
					<td style={{ textAlign: "right", paddingRight: 6 }}>{chartData[index].name}</td>
					<td>
						<div className="badge" style={{ backgroundColor: DescriptionStatusColor[index], minWidth: 20, textAlign: "center" }}>
							{chartData[index].value}
						</div>
					</td>
				</tr>
			);
		};

		return (
			<div className="card" style={{ marginRight: 16 }}  onClick={() => displayTasks(taskType)}>
				<div className="title">{t(`taskType.${taskType}`)}</div>

				<div
					style={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "center",
						borderTop: "1px solid #D8D8D8",
						borderBottom: "1px solid #D8D8D8",
						padding: "12px 0px",
						margin: "12px 0",
					}}
				>
					<div style={{ marginRight: 12 }}>
						<table>
							<tbody>
								{StudentRow(0)}
								{StudentRow(1)}
								{StudentRow(2)}
								{StudentRow(3)}
							</tbody>
						</table>
					</div>
					<PieChart width={outerChartRadius * 2} height={outerChartRadius * 2} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
						<Pie
							data={chartData}
							cx={outerChartRadius}
							cy={outerChartRadius}
							innerRadius={innerChartRadius}
							outerRadius={outerChartRadius}
							paddingAngle={2}
							dataKey="value"
							isAnimationActive={false}
						>
							{chartData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={DescriptionStatusColor[index]} />
							))}
						</Pie>
					</PieChart>
				</div>

				<table>
					<tbody>
						{Object.keys(data)
							.sort()
							.map((studentName) => {
								const descriptionStatus: DescriptionStatus = data[studentName];
								return (
									<tr key={studentName}>
										<td style={{ paddingRight: 8 }}>
											<div style={{ display: "flex", alignItems: "center", marginTop: 4, marginBottom: 4 }}>
												<ProfileColoredCircle username={studentName} size="24px" />
												&nbsp;<div>{studentName}</div>
											</div>
										</td>
										<td>
											<DescriptionStatusBadge status={descriptionStatus} />
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<div>
			{isLoading && <LoadingIndicator />}
			<div style={{ width: 200 }}>
				<Select
					options={selectOptions}
					value={filterOption}
					onChange={(v) => {
						if (v) setFilterOption(v);
					}}
				/>
			</div>
			<div style={{ display: "flex", alignItems: "flex-start", marginTop: 12 }}>
				{(!filterOption.value || filterOption.value === TaskType.Emneopgave) && GetOverviewColumn(TaskType.Emneopgave)}
				{(!filterOption.value || filterOption.value === TaskType.SSO) && GetOverviewColumn(TaskType.SSO)}
				{(!filterOption.value || filterOption.value === TaskType.YearTask) && GetOverviewColumn(TaskType.YearTask)}
			</div>
		</div>
	);
}
