import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowDeleteModal } from "../../../components/ConfirmationModal";
import IconButton from "../../../components/input/IconButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import { ManageModalAction, Size } from "../../../Constants";
import Course from "../../../model/Course";
import Subject from "../../../model/Subject";
import User from "../../../model/User";
import * as CourseService from "../../../services/Course";
import { ShowManageCourseModal } from "./ManageCourseModal";

/**
 * Displays the list of courses in a table for the admin.
 */
export default function ManageCoursesPage() {
	const { t } = useTranslation();
	const [courses, setCourses] = useState<Course[]>([]);
	const [filterSubjectSelectOptions, setFilterSubjectSelectOptions] = useState<{ label: string; value: Subject }[]>([]); // label => name of the subject
	const [subjectFilterValue, setSubjectFilterValue] = useState<{ label: string; value: Subject } | undefined | null>(); // label => name of the subject
	const [filterTeacherSelectOptions, setFilterTeacherSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => teacher name
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => teacher name
	const [filterProofreaderSelectOptions, setFilterProofreaderSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => proofreader name
	const [proofreaderFilterValue, setProofreaderFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => proofreader name
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading inicator

	useEffect(() => {
		LoadCourses();
	}, []);

	const LoadCourses = () => {
		setIsLoading(true);
		CourseService.GetAllCourses().then((_courses) => {
			setCourses(_courses);
			let subjects: Subject[] = [];
			let teachers: User[] = [];
			let proofreaders: User[] = [];

			// populate the subjects, teachers and proofreaders arrays
			_courses.forEach((course) => {
				if (course.subject) subjects.push(course.subject);
				if (course.teachers) course.teachers.forEach((t) => teachers.push(t));
				if (course.proofreader) proofreaders.push(course.proofreader);
			});

			// only keep the unique values in these arrays
			subjects = _.uniqBy(subjects, "id");
			teachers = _.uniqBy(teachers, "id");
			proofreaders = _.uniqBy(proofreaders, "id");

			// populate the subjects filter
			setFilterSubjectSelectOptions(
				subjects.map((s) => {
					return {
						value: s,
						label: s.name,
					};
				})
			);
			// populate the teachers filter
			setFilterTeacherSelectOptions(
				teachers.map((t) => {
					return {
						value: t,
						label: t.name,
					};
				})
			);
			// populate the proofreaders filter
			setFilterProofreaderSelectOptions(
				proofreaders.map((t) => {
					return {
						value: t,
						label: t.name,
					};
				})
			);
			setIsLoading(false);
		});
	};

	const DeleteCourse = (course: Course) => {
		ShowDeleteModal(course.name, () => {
			setCourses(courses.filter((c) => c.id !== course.id));
			CourseService.DeleteCourse(course.id).catch((e) => console.error("Failed to delete the course", e));
		});
	};

	const CoursesHTMLList = courses
		.filter((course) => {
			// filter by subject
			if (!subjectFilterValue) return true;
			return course.subject?.id === subjectFilterValue.value.id;
		})
		.filter((course) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!course.teachers) return true;
			return course.teachers.map((t) => t.id).includes(teacherFilterValue.value.id);
		})
		.filter((course) => {
			// filter by proofreader
			if (!proofreaderFilterValue) return true;
			return course.proofreader?.id === proofreaderFilterValue.value.id;
		})
		.map((course) => {
			return (
				<tr key={course.id}>
					<td>
						{course.name}
						<div className="info-text">
							{course.subject?.name} ({course.subject?.subjectCategory?.name})
						</div>
					</td>
					<td>
						{course.schoolClassNames?.map((className) => (
							<div>{className}</div>
						))}
					</td>
					<td>
						{course.teachers
							?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
							.map((t) => (
								<div key={t.id} style={{ display: "flex", alignItems: "center", marginTop: 4, marginBottom: 4 }}>
									<ProfileColoredCircle username={t.name || ""} size="24px" />
									&nbsp;<div>{t.name}</div>
								</div>
							))}
					</td>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle username={course.proofreader?.name || ""} size="24px" />
							&nbsp; {course.proofreader?.name}
						</div>
					</td>
					<td>
						{course.durationHours === null || course.durationHours === 0 ? (
							<div>{t("No hours duration")}</div>
						) : (
							<div>
								{course.durationHours} {course.durationHours === 1 ? t("hour") : t("hours")}
							</div>
						)}

						<div className="info-text">
							{course.durationYears} {course.durationYears === 1 ? t("year") : t("years")}
						</div>
					</td>
					<td>{course.hf && course.hfLetter}</td>
					<td>
						<button className="btn blue text" onClick={() => ShowManageCourseModal(ManageModalAction.Edit, course.id, LoadCourses)}>
							<Icon.Edit />
							{t("Edit")}
						</button>
					</td>
					<td>
						<IconButton
							size={Size.SM}
							color="red"
							icon={<Icon.Trash2 />}
							onClick={() => {
								DeleteCourse(course);
							}}
						/>
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div className="controls-above-table">
				<div className="filter">
					<Select placeholder={t("Filter by subject")} options={filterSubjectSelectOptions} isClearable={true} onChange={setSubjectFilterValue} />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by teacher")} options={filterTeacherSelectOptions} isClearable={true} onChange={setTeacherFilterValue} />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by proofreader")} options={filterProofreaderSelectOptions} isClearable={true} onChange={setProofreaderFilterValue} />
				</div>
				<div className="actions">
					<button onClick={() => ShowManageCourseModal(ManageModalAction.Create, -1, LoadCourses)} className="btn blue">
						<Icon.PlusSquare />
						{t("Create")}
					</button>
				</div>
			</div>
			<table className="table">
				<thead>
					<tr>
						<th style={{ minWidth: "250px", position: "relative" }}>
							{t("Course")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Class(es)")}</th>
						<th>{t("Teacher(s)")}</th>
						<th>{t("Proofreader")}</th>
						<th>{t("Duration")}</th>
						<th>{t("HF")}</th>
						<th>{t("Actions")}</th>
						<th>{t("Delete")}</th>
					</tr>
				</thead>
				<tbody>{CoursesHTMLList}</tbody>
			</table>

			{CoursesHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
