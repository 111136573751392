import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowGeneralModal } from "../../../components/GeneralModal";
import TextButton from "../../../components/input/TextButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import { TaskType } from "../../../Constants";
import User from "../../../model/User";
import YearTask from "../../../model/YearTask";
import EditDefaultTextsModal from "../EditDefaultTextsModal";
import * as YearTaskService from "./../../../services/YearTask";
import ManageYearTaskModal from "./ManageYearTaskModal";

export default function ManageYearTaskPage() {
	const { t } = useTranslation();
	const [yearTasks, setYearTasks] = useState<YearTask[]>([]);
	const [isLoading, setIsLoading] = useState(false); // toggle the loading indicator in table
	const [filterName, setFilterName] = useState("");
	const [filterTeacherSelectOptions, setFilterTeacherSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => teacher name
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => teacher name
	const [filterProofreaderSelectOptions, setFilterProofreaderSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => proofreader name
	const [proofreaderFilterValue, setProofreaderFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => proofreader name

	useEffect(() => {
		LoadYearTasks();
	}, []);

	const LoadYearTasks = async () => {
		setIsLoading(true);
		let _yearTasks = await YearTaskService.GetAllAvailable();

		let teachers: User[] = [];
		let proofreaders: User[] = [];

		// populate the subjects, teachers and proofreaders arrays
		_yearTasks.forEach((_yearTask) => {
			if (_yearTask.teachers) _yearTask.teachers.forEach((t) => teachers.push(t));
			if (_yearTask.proofreader) proofreaders.push(_yearTask.proofreader);
		});

		// only keep the unique values in these arrays
		teachers = _.uniqBy(teachers, "id");
		proofreaders = _.uniqBy(proofreaders, "id");

		// populate the teachers filter
		setFilterTeacherSelectOptions(
			teachers.map((t) => {
				return {
					value: t,
					label: t.name,
				};
			})
		);
		// populate the proofreaders filter
		setFilterProofreaderSelectOptions(
			proofreaders.map((t) => {
				return {
					value: t,
					label: t.name,
				};
			})
		);

		setYearTasks(_yearTasks);
		setIsLoading(false);
	};

	// show modal to edit default evaluation info and comment
	const ShowEditDefaultTextsModal = () => {
		ShowGeneralModal({
			content: <EditDefaultTextsModal taskType={TaskType.YearTask} />,
			title: t(`taskType.${TaskType.YearTask}`) + " " + t("default texts"),
		});
	};

	const EditYearTask = (yearTask: YearTask) => {
		ShowGeneralModal({
			title: t("Edit year task"),
			content: <ManageYearTaskModal yearTaskId={yearTask.id} onSave={LoadYearTasks}/>,
		});
	};

	const YearTasksHTMLList = yearTasks
		.filter((yearTask) => {
			//filter by student name
			if (!filterName) return true;
			return yearTask.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((yearTask) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!yearTask.teachers) return true;
			return yearTask.teachers.map((t) => t.id).includes(teacherFilterValue.value.id);
		})
		.filter((course) => {
			// filter by proofreader
			if (!proofreaderFilterValue) return true;
			return course.proofreader?.id === proofreaderFilterValue.value.id;
		})
		.map((yearTask) => (
			<tr key={yearTask.id}>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ProfileColoredCircle size="32px" username={yearTask.student?.name || ""} />
						<div style={{ marginLeft: 8 }}>
							<div>{yearTask.student?.name}</div>
							<div className="info-text">{yearTask.title}</div>
						</div>
					</div>
				</td>
				<td>
					{yearTask.teachers
						?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
						.map((t) => (
							<div style={{ display: "flex", alignItems: "center", marginTop: 4, marginBottom: 4 }}>
								<ProfileColoredCircle username={t.name || ""} size="24px" />
								&nbsp;<div>{t.name}</div>
							</div>
						))}
				</td>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ProfileColoredCircle username={yearTask.proofreader?.name || ""} size="24px" />
						&nbsp; {yearTask.proofreader?.name}
					</div>
				</td>
				<td>
					<TextButton icon={<Icon.Edit />} text={t("Edit")} onClick={() => EditYearTask(yearTask)} />
				</td>
			</tr>
		));

	return (
		<div style={{ display: "inline-block", minWidth: 900 }}>
			<div className="controls-above-table">
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by teacher")} options={filterTeacherSelectOptions} isClearable={true} onChange={setTeacherFilterValue} />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by proofreader")} options={filterProofreaderSelectOptions} isClearable={true} onChange={setProofreaderFilterValue} />
				</div>
				<div className="actions">
					<button onClick={ShowEditDefaultTextsModal} className="btn blue">
						<Icon.Edit />
						{t("Default texts")}
					</button>
				</div>
			</div>

			<table className="table">
				<thead>
					<tr>
						<th style={{ minWidth: "250px", position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Teacher(s)")}</th>
						<th>{t("Proofreader")}</th>
						<th>{t("Actions")}</th>
					</tr>
				</thead>
				<tbody>{YearTasksHTMLList}</tbody>
			</table>

			{YearTasksHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}

			<div className="info-text">
				{t("Year tasks are created when the student is added to a 2HF class. If you removed the student from the class, the year task is deleted.")}
			</div>
		</div>
	);
}
