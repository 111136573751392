import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ManageModalAction, Size } from "../../../Constants";
import Subject from "../../../model/Subject";
import { ShowManageCategoriesModal } from "./ManageSubjectCategoriesModal";
import { ShowManageSubjectModal } from "./ManageSubjectModal";
import * as SubjectService from "../../../services/Subject";
import * as SubjectCategoryService from "../../../services/SubjectCategory";
import SubjectCategory from "../../../model/SubjectCategory";
import * as Icon from "react-feather";
import LoadingIndicator from "../../../components/LoadingIndicator";
import TextButton from "../../../components/input/TextButton";
import { CloseGeneralModal, ShowGeneralModal } from "../../../components/GeneralModal";
import ReorderModal from "./ReorderingModal";
import IconButton from "../../../components/input/IconButton";
import { ShowDeleteModal } from "../../../components/ConfirmationModal";

/**
 * Displays the list of subjects in a table for the admin.
 */
export default function ManageSubjectsPage() {
	const { t } = useTranslation();
	const [subjects, setSubjects] = useState<Subject[]>([]);
	const [subjectCategories, setSubjectCategories] = useState<{ value: SubjectCategory; label: string }[]>([]);
	const [filterSubjectCategory, setFilterSubjectCategory] = useState<{ value: SubjectCategory; label: string } | null>();
	const [loadingSubjects, setLoadingSubjects] = useState(false);

	useEffect(() => {
		LoadSubjects();
		LoadSubjectsCategories();
	}, []);

	const LoadSubjects = () => {
		setLoadingSubjects(true);
		SubjectService.GetSubjects().then((subjects) => {
			setSubjects(subjects);
			setLoadingSubjects(false);
		});
	};

	const LoadSubjectsCategories = () => {
		SubjectCategoryService.GetSubjectCategories(false).then((scs) => {
			let formatted: { value: SubjectCategory; label: string }[] = [];
			scs.forEach((s) => formatted.push({ value: s, label: s.name }));
			setSubjectCategories(formatted);
		});
	};

	const ShowReorderModal = () => {
		ShowGeneralModal({
			content: <ReorderModal onSave={CloseGeneralModal} />,
			title: t("Order"),
		});
	};

	const DeleteSubject = (subject: Subject) => {
		ShowDeleteModal(subject.name, () => {
			setSubjects(subjects.filter((s) => s.id !== subject.id));
			SubjectService.DeleteSubject(subject.id).catch((e) => console.error("Failed to delete the subject", e));
		});
	};

	const subjectListHtml = subjects
		.filter((subject) => {
			if (!filterSubjectCategory) return true;
			return subject.subjectCategory?.id === filterSubjectCategory.value.id;
		})
		.map((subject) => {
			return (
				<tr key={subject.id}>
					<td>
						{subject.name}
						<div className="info-text">{subject.subjectCategory?.name}</div>
					</td>
					<td>{subject.hf && <Icon.Check />}</td>
					<td>
						<button className="btn blue text" onClick={() => ShowManageSubjectModal(ManageModalAction.Edit, subject.id, LoadSubjects)}>
							<Icon.Edit />
							{t("Edit")}
						</button>
					</td>
					<td>
						<IconButton
							size={Size.SM}
							color="red"
							icon={<Icon.Trash2 />}
							onClick={() => {
								DeleteSubject(subject);
							}}
						/>
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div className="controls-above-table">
				<div className="filter">
					<Select
						placeholder={t("Filter by category...")}
						options={subjectCategories}
						value={filterSubjectCategory}
						onChange={setFilterSubjectCategory}
						isClearable={true}
					/>
				</div>
				<div className="actions">
					<button onClick={() => ShowManageSubjectModal(ManageModalAction.Create, -1, LoadSubjects)} className="btn blue">
						<Icon.PlusSquare />
						{t("Create")}
					</button>
				</div>
			</div>
			<table className="table">
				<thead>
					<tr>
						<th style={{ minWidth: "250px", position: "relative" }}>
							{t("Subject")} {loadingSubjects && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("HF")}</th>
						<th>{t("Actions")}</th>
						<th>{t("Delete")}</th>
					</tr>
				</thead>
				<tbody>{subjectListHtml}</tbody>
			</table>

			{subjectListHtml.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}

			<div style={{ textAlign: "right", marginTop: 8 }}>
				<TextButton onClick={() => ShowReorderModal()} text={t("Order")} icon={<Icon.Move />} />
				<TextButton onClick={() => ShowManageCategoriesModal(() => {})} text={t("Manage categories")} icon={<Icon.Menu />} />
			</div>
		</div>
	);
}
