type ProfileColoredCircleProps = { username: string; size: string; onClick?: () => any };

export default function ProfileColoredCircle({ username, size, onClick }: ProfileColoredCircleProps) {
	if (username === "" || username === undefined) return <div></div>;
	let colors = ["#d32f2f", "#7b1fa2", "#303f9f", "#0288d1", "#00796b", "#388e3c", "#ef6c00"];
	//genererate hashcode base on the name
	let hashCode = username.split("").reduce((a, b) => {
		a = (a << 5) - a + b.charCodeAt(0);
		return a & a;
	}, 0);
	//Make a string with uppercase initials, for ex: Frodo Baggins becomes FB
	let initials = username
		.split(" ")
		.filter((e) => e.length > 0)
		.map((e) => e[0])
		.join("")
		.toUpperCase();
	//if people have more then 2 names they will have more than 2 initials, we should keep only first and last initial
	if (initials.length > 2) initials = initials[0] + initials[initials.length - 1];

	let color = colors[Math.abs(hashCode % colors.length)];
	let fontSize = "1rem";
	if (size === "28px") fontSize = "0.9rem";
	if (size === "24px") fontSize = "0.8rem";
	return (
		<div
			className="profile-colored-circle"
			style={{ width: size, height: size, lineHeight: size, backgroundColor: color, fontSize: fontSize, cursor: onClick ? "pointer" : "default" }}
			onClick={() => {
				if (onClick) onClick();
			}}
		>
			{initials}
		</div>
	);
}
