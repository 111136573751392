import React from "react";
import LoadingIndicator from "../LoadingIndicator";

type TextButtonProps = { text: string; onClick: () => any; icon?: React.ReactNode; color?: string; loading?: boolean; iconAtEnd?: boolean };

export default function TextButton({ text, onClick, icon, color, loading, iconAtEnd }: TextButtonProps) {
	return (
		<button className={`btn text ${color ? color : "blue"} ${iconAtEnd ? "icon-at-end" : ""}`} onClick={onClick}>
			{loading && <LoadingIndicator />}
			{!loading && !iconAtEnd && icon}
			{text}
			{!loading && iconAtEnd && icon}
		</button>
	);
}
