/** The majority of methods in this service are only for the administrator */
import User from "../model/User";
import Axios from "./CustomAxios";
import * as Constants from "../Constants";

/** Fetches all the users (admin, teachers, proofreaders) in the users's school. Deleted users are not included, and the results are sorted alphabetically. */
export const GetAllUsers = async (filterByRole?: Constants.UserRoles): Promise<User[]> => {
	let users: User[] = (await Axios.get(`/rsvidnesbyrd/user/all`)).data;
	if (!filterByRole) return users;
	return users.filter((u) => u.roles.includes(filterByRole));
};

export const GetUser = async (userId: string): Promise<User> => {
	return (await Axios.get(`/rsvidnesbyrd/user/${userId}`)).data;
};

/** Returns the current authneticated user. Also caches it in local storage, so only the first request after log in hits the API, rest are almost instant. */
export const GetAuthenticatedUser = async (): Promise<User> => {
	let currentUserJson = window.localStorage.getItem(Constants.LocalStorageKeys.currentUser);
	if (currentUserJson) {
		let parseCurrentUser: User = JSON.parse(currentUserJson);
		return parseCurrentUser;
	} else {
		let user: User = (await Axios.get(`/rsvidnesbyrd/user/me`)).data;
		return user;
	}
};

export const CreateUser = async (user: User) => {
	return (await Axios.post(`/rsvidnesbyrd/user/create`, user)).data;
};

export const UpdateUser = async (user: User) => {
	return (await Axios.put(`/rsvidnesbyrd/user/update`, user)).data;
};

export const DeleteUser = async (userId: string) => {
	return await Axios.delete(`/rsvidnesbyrd/user/${userId}`);
};
