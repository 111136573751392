import { UserRoles } from "../Constants";
import YearTask from "../model/YearTask";
import Axios from "./CustomAxios";

/**
 * Get all available year tasks from the same year as the last school year and from the same school as the signed in user.
 */
export const GetAllAvailable = async (): Promise<YearTask[]> => {
	return (await Axios.get(`/rsvidnesbyrd/yearTask/all`, {})).data;
};

/**
 * Similar to @see {GetAllAvailable}, but this returns them for a single teacher (the current user making the request)
 */
export const GetAllAvailableForTeacher = async (): Promise<YearTask[]> => {
	return (await Axios.get(`/rsvidnesbyrd/yearTask/allForTeacher`, {})).data;
};

/**
 * Similar to @see {GetAllAvailable}, but this returns them for a single proofreader (the current user making the request).
 * Only proofread and readyForProfread statuses are included.
 */
export const GetAllAvailableForProofreader = async (): Promise<YearTask[]> => {
	return (await Axios.get(`/rsvidnesbyrd/yearTask/allForProofreader`, {})).data;
};


export const GetAllAvailableYearTasks = async (userRole : UserRoles): Promise<YearTask[]> => {
	if(userRole === UserRoles.Admin){
		return (await Axios.get(`/rsvidnesbyrd/yearTask/all`, {})).data;
	} else if(userRole === UserRoles.ProofReader) {
		return (await Axios.get(`/rsvidnesbyrd/yearTask/allForProofreader`, {})).data;
	} else {
		return (await Axios.get(`/rsvidnesbyrd/yearTask/allForTeacher`, {})).data;
	}
};

export const GetYearTaskById = async (id: number): Promise<YearTask> => {
	return (await Axios.get(`/rsvidnesbyrd/yearTask/${id}`, {})).data;
};

export const UpdateYearTask = async (yearTask: YearTask): Promise<any> => {
	return (await Axios.post(`/rsvidnesbyrd/yearTask/update`, yearTask)).data;
};
