import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { GetSchoolData, SchoolData, initialSchoolState } from '../../services/School';
import LoadingIndicator from "../../components/LoadingIndicator";
import OverviewCard from '../../components/OverviewCard';
import TasksSection from '../admin/overview/TasksSection';
import * as CourseService from "../../services/Course";
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { UserRoles } from '../../Constants';

export default function OverviewPage()  {
    const { t } = useTranslation();
	const [overviewData, setOverviewData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [schoolData, setSchoolData] = useState<SchoolData>(initialSchoolState);
	const [selectedTab, setSelectedTab] = useState(0);
	const [viewOnlyIncomplete, setViewOnlyComplete] = useState(false);
	const userRole = useSelector((state: RootState) => state.activeRole);

	useEffect(() => {
		setIsLoading(true);
		LoadSchoolData();
        CourseService.GetCoursesOverview(userRole).then((data) => {
			setOverviewData(data);
			setIsLoading(false);
		});
	}, [userRole]);

	const LoadSchoolData = async () => {
		let data = await GetSchoolData();
		setSchoolData(data);
	};

	return (
		<>
			<div>
				<div className="tab-buttons">
					<div className={`${selectedTab === 0 ? "active" : ""}`} onClick={() => setSelectedTab(0)}>
						{t("Courses")}
					</div>
					<div className={`${selectedTab === 1 ? "active" : ""}`} onClick={() => setSelectedTab(1)}>
						{t("Tasks")}
					</div>
				</div>
			</div>
			<div style={{ display: "inline-block", marginTop: 24 }}>
				{selectedTab === 0 && (
					<div style={{ display: "inline-block" }}>
						{isLoading && <LoadingIndicator />}
						{userRole === UserRoles.Admin && !isLoading && 
							<div>
							<div style={{ cursor: "pointer", userSelect: "none", display: "inline-flex", marginBottom: 12 }}>
								<input
									type="checkbox"
									id="filterCheckboxId"
									checked={viewOnlyIncomplete}
									onChange={(e) => {
										setViewOnlyComplete(e.currentTarget.checked);
									}}
								/>
								<label htmlFor="filterCheckboxId">{t("view only incomplete")}</label>
							</div>
							{overviewData
								.filter((courseData) => {
									if (!viewOnlyIncomplete) return true;
									// a course is not complete if:
									// 1. not all absences are filled
									// 2. not all evaluation description statuses are in done
									// 3. course description status is not in done 
									return (
										courseData.filledAbsences !== courseData.totalStudentsForAbsence ||
										courseData.students_done !== courseData.totalStudents ||
										!courseData.courseDescriptionDone
									);
								})
								.map((courseData) => (
									<OverviewCard key ={courseData.id} courseData={courseData} schoolData = {schoolData} />
								))}
						</div>
						}
						{!isLoading && userRole !== UserRoles.Admin && (
							<div>
								{overviewData.map((courseData) => (
									<OverviewCard courseData={courseData}  schoolData={schoolData} />
								))}
							</div>
						)}
						{!isLoading && overviewData.length === 0 && <div>{t("No courses to display")}</div>}
					</div>
				)}
				{selectedTab === 1 && <TasksSection/>}
			</div>
		</>
	);
}

