import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import NormalButton from "../../../components/input/NormalButton";
import { HFType, ManageModalAction } from "../../../Constants";
import Student from "../../../model/Student";
import * as StudentService from "../../../services/Student";

const ManageModalContainer = document.querySelector("#manageStudentModalContainer");
type ManageStudentModalProps = { action: ManageModalAction; studentId: number; onComplete: Function };

export function ShowManageStudentModal(action: ManageModalAction, studentId: number, onComplete: Function) {
	ReactDOM.render(<ManageStudentModal action={action} studentId={studentId} onComplete={onComplete} />, ManageModalContainer);
}

function ManageStudentModal({ action, studentId, onComplete }: ManageStudentModalProps) {
	const { t } = useTranslation();
	const hfTypeValues = [
		{ value: HFType.Extended, label: t(`hfType.${HFType.Extended}`) },
		{ value: HFType.Basic, label: t(`hfType.${HFType.Basic}`) },
	];

	// student fields
	const [name, setName] = useState("");
	const [startDate, setStartDate] = useState<Date | undefined>(new Date());
	const [birthDate, setBirthDate] = useState<Date | undefined>(new Date());
	const [hasHfBevis, setHasHfBevis] = useState(true);
	const [hfType, setHfType] = useState(hfTypeValues[0]);

	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState("");
	const firstInput = useRef<HTMLInputElement>(null);

	//if editing, fetch the student we are editing and fill the fields
	useEffect(() => {
		firstInput.current?.focus();
		if (action === ManageModalAction.Edit)
			StudentService.GetStudent(studentId).then((student) => {
				setName(student.name);
				//if the dates don't exist, set them as undefined. Otherwise, the dates come from Java as a string, and should be converted to a Date object.
				setStartDate(student.startDate ? new Date(student.startDate) : undefined);
				setBirthDate(student.birthDate ? new Date(student.birthDate) : undefined);
				setHasHfBevis(student.hasHfBevis);
				setHfType(hfTypeValues.filter((v) => v.value === student.hfType)[0]);
			});
	}, [action, studentId]);

	const CloseModal = () => {
		if (ManageModalContainer != null) ReactDOM.unmountComponentAtNode(ManageModalContainer);
	};

	/** Returns a Student with data from the popup fields. */
	const CreateStudentObjectFromFields = (): Student => {
		return {
			id: action === ManageModalAction.Edit ? studentId : -1, //if we are creating the student the id field is not required
			name: name,
			startDate: startDate,
			birthDate: birthDate,
			hasHfBevis: hasHfBevis,
			hfType: hfType.value,
		};
	};

	const CreateStudent = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let student = CreateStudentObjectFromFields();
		let result;
		try {
			result = await StudentService.CreateStudent(student);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseModal();
		} catch (e : any) {
			let response = e.response.data;
			console.log("Student create error", response);
			ParseErrorCode(response);
			setIsSaving(false);
		}
	};

	const EditStudent = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let student = CreateStudentObjectFromFields();
		let result;
		try {
			result = await StudentService.UpdateStudent(student);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseModal();
		} catch (e : any) {
			let response = e.response.data;
			console.log("Student update error", response);
			ParseErrorCode(response);
			setIsSaving(false);
		}
	};

	const ParseErrorCode = (response: { code: string; message: string }) => {
		switch (response.code) {
			case "missing_name":
				setError("Please add the student name!");
				break;
			default:
				setError(response.message);
		}
	};

	return (
		<div className="modal">
			<div className="content" style={{ width: "400px" }}>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>

				<div className="title">{action === ManageModalAction.Edit ? t("Edit student") : t("Create student")}</div>

				<table className="table-with-inputs vertically-centered-labels">
					<tbody>
						<tr>
							<td>{t("Name")}</td>
							<td>
								<input ref={firstInput} type="text" placeholder={t("Student name")} value={name} onChange={(v) => setName(v.currentTarget.value)} />
							</td>
						</tr>
						<tr>
							<td>{t("Start date")}</td>
							<td>
								<DatePicker locale="da" dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => setStartDate(date as Date)} />
							</td>
						</tr>
						<tr>
							<td>{t("Birthdate")}</td>
							<td>
								<DatePicker locale="da" dateFormat="dd/MM/yyyy" selected={birthDate} onChange={(date) => setBirthDate(date as Date)} />
							</td>
						</tr>
						<tr>
							<td>{t("HF-Bevis")}</td>
							<td>
								<input
									type="checkbox"
									checked={hasHfBevis}
									onChange={(e) => {
										setHasHfBevis(e.currentTarget.checked);
									}}
								/>
							</td>
						</tr>
						{hasHfBevis && (
							<tr>
								<td>{t("HF type")}</td>
								<td>
									<Select
										placeholder={t("Select teacher(s)...")}
										options={hfTypeValues}
										value={hfType}
										onChange={(v) => {
											if (v) setHfType(v);
										}}
									/>
								</td>
							</tr>
						)}
					</tbody>
				</table>

				{error.length > 0 && <div className="error-banner">{error}</div>}

				<div style={{ textAlign: "right" }}>
					{action === ManageModalAction.Edit ? (
						<NormalButton onClick={EditStudent} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
					) : (
						<NormalButton onClick={CreateStudent} loading={isSaving} icon={<Icon.Save />} text={t("Create")} />
					)}
				</div>
			</div>
		</div>
	);
}
