import { Size } from "../../Constants";

type TextButtonProps = { icon: React.ReactNode; onClick: () => any; color?: string; size?: Size };

export default function IconButton({ onClick, icon, color, size }: TextButtonProps) {
	let sizeClass = "";
	if (size === Size.SM) sizeClass = "sm";
	return (
		<button className={`btn icon ${sizeClass} ${color ? color : ""}`} onClick={onClick}>
			{icon}
		</button>
	);
}
