import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { ManageModalAction, Size } from "../../../Constants";
import Student from "../../../model/Student";
import * as StudentService from "../../../services/Student";
import * as Icon from "react-feather";
import { ReactSortable } from "react-sortablejs";
import SubjectCategory from "../../../model/SubjectCategory";
import * as SubjectCategoryService from "../../../services/SubjectCategory";
import * as SubjectService from "../../../services/Subject";
import Subject from "../../../model/Subject";
import { getConstantValue } from "typescript";
import _ from "lodash";
import NormalButton from "../../../components/input/NormalButton";
import { ShowGeneralModal } from "../../../components/GeneralModal";
import TextButton from "../../../components/input/TextButton";
import IconButton from "../../../components/input/IconButton";

type ReorderModalProps = { onSave: () => any };

export default function ReorderModal({ onSave }: ReorderModalProps) {
	const { t } = useTranslation();
	const [subjectCategories, setSubjectCategories] = useState<SubjectCategory[]>([]);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		LoadData();
	}, []);

	const LoadData = async () => {
		let _subjectCategories = await SubjectCategoryService.GetSubjectCategories(false);

		//init subjects array with an empty array in subject categories
		_subjectCategories.forEach((_, index) => {
			_subjectCategories[index].subjects = [];
		});

		//order the subject categories by their pdf order
		_subjectCategories = _.sortBy(_subjectCategories, ["pdfOrder"]);

		let _subjects = await SubjectService.GetSubjects();

		//assign subjects to subjects categories
		_subjects.forEach((subject) => {
			_subjectCategories.forEach((sc, index) => {
				if (sc.id === subject.subjectCategory?.id) {
					_subjectCategories[index].subjects?.push(subject);
				}
			});
		});

		//order the subjects in each subject category by their pdf order
		_subjectCategories.forEach((subjectCategory, index) => {
			_subjectCategories[index].subjects = _.sortBy(subjectCategory.subjects, ["pdfOrder"]);
		});

		setSubjectCategories(_subjectCategories);
	};

	const SetSubjectListInSubjectCategory = (subjects: Subject[], subjectCategoryId: number) => {
		let tmp = _.cloneDeep(subjectCategories);
		tmp.forEach((sc) => {
			if (sc.id === subjectCategoryId) sc.subjects = subjects;
		});
		setSubjectCategories(tmp);
	};

	const Save = async () => {
		if (isSaving) return;
		setIsSaving(true);

		let _subjectCategories = _.cloneDeep(subjectCategories);
		let _subjects: Subject[] = [];

		//set the pdf order in subject categories equal to their index in the list, also make a list with all subjects, used later.
		_subjectCategories.forEach((subjectCategory, index) => {
			_subjectCategories[index].pdfOrder = index;
			subjectCategory.subjects?.forEach((s) => _subjects.push(s));
		});

		await SubjectCategoryService.BulkUpdateOrder(_subjectCategories);

		//set pdf order for all subjects
		_subjects.forEach((subject, index) => {
			_subjects[index].pdfOrder = index;
		});

		await SubjectService.BulkUpdateOrder(_subjects);

		setIsSaving(false);
		onSave();
	};

	const SetCollapseAll = (collapse: boolean) => {
		let _subjCat = _.cloneDeep(subjectCategories);
		_subjCat.forEach((c) => (c.isCollapsed = collapse));
		setSubjectCategories(_subjCat);
	};

	const SetSubjectCategoryCollapse = (subjectCategory: SubjectCategory, collapse: boolean) => {
		let _subjCat = _.cloneDeep(subjectCategories);
		_subjCat.forEach((c) => {
			if (c.id === subjectCategory.id) c.isCollapsed = collapse;
		});
		setSubjectCategories(_subjCat);
	};

	let allAreCollapsed = subjectCategories.filter((c) => c.isCollapsed).length === subjectCategories.length;

	return (
		<div>
			<div style={{ textAlign: "right", marginTop: -16, marginBottom: 8 }}>
				{allAreCollapsed ? (
					<TextButton text={t("Unwrap all")} onClick={() => SetCollapseAll(false)} />
				) : (
					<TextButton text={t("Collapse all")} onClick={() => SetCollapseAll(true)} />
				)}
			</div>
			<div style={{ maxHeight: "70vh", overflowY: "auto", userSelect: "none" }}>
				<ReactSortable list={subjectCategories} setList={setSubjectCategories} animation={200}>
					{subjectCategories.map((subjectCategory) => (
						<div>
							<div className="sortable-list-item" key={subjectCategory.id}>
								{subjectCategory.name}
								{subjectCategory.isCollapsed ? (
									<IconButton icon={<Icon.ChevronDown />} onClick={() => SetSubjectCategoryCollapse(subjectCategory, false)} size={Size.SM} />
								) : (
									<IconButton icon={<Icon.ChevronUp />} onClick={() => SetSubjectCategoryCollapse(subjectCategory, true)} size={Size.SM} />
								)}
							</div>
							{!subjectCategory.isCollapsed && (
								<div style={{ marginLeft: 32 }}>
									<ReactSortable
										list={subjectCategory.subjects}
										setList={(subjects: Subject[]) => {
											SetSubjectListInSubjectCategory(subjects, subjectCategory.id);
										}}
										animation={200}
									>
										{subjectCategory.subjects?.map((subject) => (
											<div className="sortable-list-item" key={subject.id}>
												{subject.name}
											</div>
										))}
									</ReactSortable>
								</div>
							)}
						</div>
					))}
				</ReactSortable>
			</div>

			<div className="info-text">{t("Click & drag items to reorder them.")}</div>
			<div style={{ textAlign: "right" }}>
				<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={Save} loading={isSaving} />
			</div>
		</div>
	);
}
