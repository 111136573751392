import { UserRoles } from '../Constants';
import { Reducer } from 'redux';

export const SET_ACTIVE_USER_ROLE = 'SET_ACTIVE_USER_ROLE';

export const setActiveUserRole = (activeRole: UserRoles) => ({
  type: SET_ACTIVE_USER_ROLE as typeof SET_ACTIVE_USER_ROLE,
  payload: { activeRole },
});

interface UserState {
  activeRole: UserRoles;
}

const initialState: UserState = {
  activeRole: UserRoles.Admin,
};

type UserAction = ReturnType<typeof setActiveUserRole>;

const userReducer: Reducer<UserState, UserAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_USER_ROLE:
      return {
        ...state,
        activeRole: action.payload.activeRole,
      };
    default:
      return state;
  }
};

export default userReducer;