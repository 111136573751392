import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import NormalButton from "../../../components/input/NormalButton";
import { CourseDurationSelectOptions, HFLettersSelectOptions, ManageModalAction, UserRoles } from "../../../Constants";
import Course from "../../../model/Course";
import Subject from "../../../model/Subject";
import User from "../../../model/User";
import * as CourseService from "../../../services/Course";
import * as SubjectService from "../../../services/Subject";
import * as UserService from "../../../services/User";

const modalContainer = document.querySelector("#manageCourseModalContainer");
type ManageCourseModalProps = { action: ManageModalAction; courseId: number; onComplete: Function };

/**
 * Show manage course modal.
 * @param action ManageModalAction.Edit or ManageModalAction.Create.
 * @param courseId id of the course to edit. Required only when editing a course.
 * @param onComplete callback when user saves, creates or deletes a course.
 */
export function ShowManageCourseModal(action: ManageModalAction, courseId: number, onComplete: Function) {
	ReactDOM.render(<ManageCourseModal action={action} courseId={courseId} onComplete={onComplete} />, modalContainer);
}

function ManageCourseModal({ action, courseId, onComplete }: ManageCourseModalProps) {
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [teachers, setTeachers] = useState<{ value: User; label: string }[]>([]);
	const [proofreader, setProofreader] = useState<{ value: User; label: string } | null>();
	const [subject, setSubject] = useState<{ value: Subject; label: string } | null>();
	const [durationHours, setDurationHours] = useState("");
	const [teacherSelectOptions, setTeacherSelectOptions] = useState<{ value: User; label: string }[]>([]);
	const [proofreaderSelectOptions, setProofreaderSelectOptions] = useState<{ value: User; label: string }[]>([]);
	const [subjectSelectOptions, setSubjectSelectOptions] = useState<{ value: Subject; label: string }[]>([]);
	const [isHF, setIsHF] = useState(false);
	const [isSaving, setIsSaving] = useState(false); // toggle loading indicator on save/create button
	const [error, setError] = useState("");
	const availableYearDurations = CourseDurationSelectOptions;
	const [durationOptions, setDurationOptions] = useState(availableYearDurations);
	const [selectedDuration, setSelectedDuration] = useState<{ label: string; value: number } | null | undefined>(availableYearDurations[0]);
	const [selectedLetterOption, setSelectedHFLetterOption] = useState(HFLettersSelectOptions[0]); //be default use the first letter
	const firstInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		firstInput.current?.focus();

		SubjectService.GetSubjects().then((loadedSubjects) => {
			let _subjectSelectOptions: { value: Subject; label: string }[] = [];
			loadedSubjects.forEach((_subject) => {
				_subjectSelectOptions.push({
					value: _subject,
					label: _subject.name,
				});
			});
			setSubjectSelectOptions(_subjectSelectOptions);
		});

		//load the teachers options and proofreaders options in the dropdowns
		UserService.GetAllUsers().then((loadedUsers) => {
			let _teacherSelectOptions: { value: User; label: string }[] = [];
			let _proofreaderSelectOptions: { value: User; label: string }[] = [];
			loadedUsers.forEach((_user) => {
				if (_user.roles.includes(UserRoles.Teacher))
					_teacherSelectOptions.push({
						value: _user,
						label: _user.name,
					});
				if (_user.roles.includes(UserRoles.ProofReader))
					_proofreaderSelectOptions.push({
						value: _user,
						label: _user.name,
					});
			});

			setTeacherSelectOptions(_teacherSelectOptions);
			setProofreaderSelectOptions(_proofreaderSelectOptions);
		});

		if (action === ManageModalAction.Edit)
			CourseService.GetCourse(courseId).then((course) => {
				setName(course.name);
				setIsHF(course.hf);
				if (course.hfLetter !== "") setSelectedHFLetterOption(HFLettersSelectOptions.filter((o) => o.value === course.hfLetter)[0]);
				setDurationHours(course.durationHours == null ? "" : course.durationHours + "");
				if (course.teachers)
					setTeachers(
						course.teachers.map((teacher) => ({
							label: teacher.name,
							value: teacher,
						}))
					);
				if (course.proofreader)
					setProofreader({
						label: course.proofreader?.name,
						value: course.proofreader,
					});
				if (course.subject)
					setSubject({
						label: course.subject.name,
						value: course.subject,
					});
				setSelectedDuration(durationOptions.filter((d) => d.value === course.durationYears)[0]);
			});
	}, [action, courseId]);

	const CloseModal = () => {
		if (modalContainer != null) ReactDOM.unmountComponentAtNode(modalContainer);
	};

	/** Returns a Course with data from the popup fields. */
	const CreateCourseObjectFromFields = (): Course => {
		return {
			id: action === ManageModalAction.Edit ? courseId : -1, //if we are creating the course the id field is not necessary
			name: name,
			subject: subject?.value,
			teachers: teachers.map((t) => t.value),
			durationHours: !isNaN(parseInt(durationHours)) ? parseInt(durationHours) : null,
			hf: isHF,
			durationYears: selectedDuration ? selectedDuration.value : 1,
			hfLetter: selectedLetterOption.value,
			proofreader: proofreader ? proofreader.value : undefined,
		};
	};

	const CreateCourse = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let course = CreateCourseObjectFromFields();
		let result;
		try {
			result = await CourseService.CreateCourse(course);
			console.log(result);
			onComplete();
			CloseModal();
			setIsSaving(false);
		} catch (e) {
			let response = e.response.data;
			console.log("Course create error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const EditCourse = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let course = CreateCourseObjectFromFields();
		let result;
		try {
			result = await CourseService.UpdateCourse(course);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseModal();
		} catch (e) {
			let response = e.response.data;
			console.log("Course update error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const ParseError = (response: { message: string; code: string }) => {
		switch (response.code) {
			case "missing_name":
				setError(t("Please add a course name!"));
				break;
			default:
				setError(response.message);
		}
	};

	return (
		<div className="modal">
			<div className="content" style={{ minWidth: "400px" }}>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>
				<div className="title">{action === ManageModalAction.Edit ? t("Edit course") : t("Create course")}</div>

				<div>
					<table className="table-with-inputs vertically-centered-labels">
						<tbody>
							<tr>
								<td>{t("Name")}</td>
								<td>
									<input ref={firstInput} type="text" placeholder={t("Name")} value={name} onChange={(v) => setName(v.currentTarget.value)} />
								</td>
							</tr>
							<tr>
								<td>{t("Subject")}</td>
								<td>
									<Select
										placeholder={t("Select subject...")}
										options={subjectSelectOptions}
										value={subject}
										onChange={(option) => {
											setSubject(option);
											if (option) {
												setIsHF(option.value.hf);
												if (option.value.hf) {
													//if the subject is HF, set the HF letter to the same letter as the subject
													setSelectedHFLetterOption(HFLettersSelectOptions.filter((o) => o.value === option.value.hfLetter)[0]);
												} else {
													//if the subject is not HF, set the default "No letter" letter
													setSelectedHFLetterOption(HFLettersSelectOptions[0]);
												}
											}
										}}
									/>
								</td>
							</tr>
							<tr>
								<td>{t("Teacher(s)")}</td>
								{/* max width to avoid the seelct to stretch the modal when there are many teachers selected */}
								<td style={{ maxWidth: 300 }}>
									<Select
										placeholder={t("Select teacher(s)...")}
										isMulti={true}
										options={teacherSelectOptions}
										value={teachers}
										onChange={(v) => {
											if (!v) setTeachers([]);
											else setTeachers([...v]);
										}}
										isClearable={true}
									/>
								</td>
							</tr>
							<tr>
								<td>{t("Proofreader")}</td>
								<td>
									<Select
										placeholder={t("Select proofreader...")}
										options={proofreaderSelectOptions}
										value={proofreader}
										onChange={(v) => setProofreader(v)}
										isClearable={true}
									/>
								</td>
							</tr>
							<tr>
								<td>{t("Duration")}</td>
								<td>
									<input type="text" placeholder={t("Hours")} value={durationHours} onChange={(v) => setDurationHours(v.currentTarget.value)} />
								</td>
							</tr>
							<tr>
								<td>{t("Years")}</td>
								<td>
									<Select options={durationOptions} value={selectedDuration} onChange={(v) => setSelectedDuration(v)} isClearable={false} />
								</td>
							</tr>
							<tr>
								<td>{t("HF")}</td>
								<td style={{ display: "flex", alignItems: "center" }}>
									<input
										type="checkbox"
										checked={isHF}
										style={{ margin: 0 }}
										onChange={(e) => {
											setIsHF(e.currentTarget.checked);
										}}
									/>
									{isHF && (
										<div style={{ width: 150, marginLeft: 8 }}>
											<Select
												options={HFLettersSelectOptions}
												value={selectedLetterOption}
												onChange={(v) => setSelectedHFLetterOption(v || HFLettersSelectOptions[0])}
											/>
										</div>
									)}
								</td>
							</tr>
						</tbody>
					</table>

					{error.length > 0 && <div className="error-banner">{error}</div>}

					<div style={{ textAlign: "right" }}>
						{action === ManageModalAction.Edit ? (
							<NormalButton onClick={EditCourse} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
						) : (
							<NormalButton onClick={CreateCourse} loading={isSaving} icon={<Icon.Save />} text={t("Create")} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
