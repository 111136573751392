import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommentFeed from "../../components/ComentFeed";
import Course from "../../model/Course";
import DescriptionSection from "./DescriptionSection";

/**
 * Common modal for teacher and proofreader to edit/proofread the course description.
 */
type CourseDescriptionModalProps = { course: Course;  onSave: () => any };
export default function CourseDescriptionModal({ course,  onSave }: CourseDescriptionModalProps) {
	const { t } = useTranslation();
	const [hasWrittenComment, setHasWrittenComment] = useState(false); // track if a new comment was written since the modal was opened, to allow/prevent rejecting description

	return (
		<div style={{ display: "flex", width: "70vw", height: "80vh" }}>
			{/* DESCRIPTION FIELDS */}
			<div
				className="flexCol1"
				style={{
					flex: 0.7,
					marginRight: 24,
					borderRight: "1px solid rgba(0,0,0,0.12)",
					paddingRight: 24,
				}}
			>
				<div style={{ fontSize: "1.5rem", color: "black", fontWeight: 500, marginBottom: "8px" }}>
					{t("Course description")} {course.name}
				</div>

				<div>
					<b>{t("Duration")}:</b> {course.durationHours} {course.durationHours === 1 ? t("hour") : t("hours")}
					<br />
					<b>{t("Students")}:</b> {course.students?.length} {course.students?.length === 1 ? t("student") : t("students")}
				</div>

				{course?.description && (
					<DescriptionSection hasWrittenComment={hasWrittenComment} description={course.description} onSave={onSave} />
				)}
			</div>
			{/* COMMENTS */}
			<div style={{ flex: 0.3, display: "flex", alignItems: "stretch", justifyContent: "stretch" }}>
				{course.description && (
					<CommentFeed
						descriptionId={course.description.id}
						onCommentAdd={() => {
							setHasWrittenComment(true);
						}}
					/>
				)}
			</div>
		</div>
	);
}
