import React, { useEffect, useState } from "react";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import { useTranslation } from "react-i18next";
import * as EvaluationService from "../../services/Evaluation";
import Evaluation from "../../model/Evaluation";
import * as Icon from "react-feather";
import NormalButton from "../../components/input/NormalButton";
import _ from "lodash";
import InputWithLabel from "../../components/input/InputWithLabel";
import LoadingIndicator from "../../components/LoadingIndicator";

type BulkAbsenceEditProps = { courseId: number; onSave: () => any };

export default function BulkAbsenceEdit({ courseId, onSave }: BulkAbsenceEditProps) {
	const { t } = useTranslation();
	const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		EvaluationService.GetEvaluationsForCourse(courseId).then((_evaluations) => {
			_evaluations.forEach((evaluation) => {
				if (evaluation.attendacePercentage === undefined || evaluation.attendacePercentage === null) {
					evaluation.absencePercentage = undefined;
				} else {
					evaluation.absencePercentage = 100 - evaluation.attendacePercentage;
				}
			});
			setEvaluations(_evaluations);
			setIsLoading(false);
		});
	}, [courseId]);

	const Save = async () => {
		setIsSaving(true);
		await EvaluationService.BulkUpdate(evaluations);
		setIsSaving(false);
		onSave();
	};

	const StudentListHTML = isLoading ? (
		<tr>
			<td colSpan={100} style={{ textAlign: "center" }}>
				<LoadingIndicator />
			</td>
		</tr>
	) : (
		evaluations.map((evaluation, index) => {
			return (
				<>
					{evaluation.student.showAbsence && (
						<tr key={evaluation.id}>
							{/* <td>{index + 1}.</td> */}
							<td>
								<div style={{ display: "flex", alignItems: "center" }}>
									<ProfileColoredCircle size="32px" username={evaluation.student.name} />
									<div style={{ marginLeft: "8px" }}>
										<div>{evaluation.student.name}</div>
									</div>
								</div>
							</td>
							<td>
								<InputWithLabel
									value={evaluation.absencePercentage === undefined || evaluation.absencePercentage === null ? "" : evaluation.absencePercentage.toString()}
									labelIcon={<Icon.Percent />}
									placeholder={t("missing")}
									onInput={(value) => {
										let _evaluations = _.cloneDeep(evaluations);
										if (value === "") {
											_evaluations[index].attendacePercentage = undefined;
											_evaluations[index].absencePercentage = undefined;
										} else {
											let percentage = parseInt(value);
											if (isNaN(percentage)) percentage = 0;
											_evaluations[index].absencePercentage = percentage;
											_evaluations[index].attendacePercentage = 100 - percentage;
										}
										setEvaluations(_evaluations);
									}}
								/>
							</td>
							<td>
								{evaluation.attendacePercentage === null || evaluation.attendacePercentage === undefined
									? t("missing")
									: `${t("Present")} ${evaluation.attendacePercentage}%`}
							</td>
						</tr>
					)}
				</>
			);
		})
	);

	return (
		<div>
			<table className="table scrollable" style={{ width: "780px", margin: "auto" }}>
				<thead>
					<tr>
						{/* <th>#</th> */}
						<th>{t("Student")}</th>
						<th>{t("Absence")}</th>
						<th>{t("Presence")}</th>
					</tr>
				</thead>
				<tbody>{StudentListHTML}</tbody>
			</table>
			<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 12 }}>
				<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={Save} loading={isSaving} />
			</div>
		</div>
	);
}
