import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import TextButton from "../../components/input/TextButton"
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import ManageTaskModal from "../../pages/admin/task/ManageTaskModal";
import { DescriptionStatus, TaskType, TeacherStatusFilterOptions } from "../../Constants";
import Task from "../../model/Task";
import * as TaskService from "../../services/Task";
import { ShowTaskStudentDescriptionModal } from "../common/TaskStudentDescriptionModal";
import { ShowGeneralModal } from "../../components/GeneralModal";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

interface Props {
	taskType: TaskType;
}

/**
 * On this page the teacher can view all the SSOs to which he is assigned, and edit the student descriptions.
 */
export default function TaskPage({ taskType }: Props) {
	const { t } = useTranslation();
	const [taskList, setTaskList] = useState<Task[]>([]);
	const [filterName, setFilterName] = useState(""); // used to filter by the student name from the text input
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator in table header
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const userRole = useSelector((state: RootState) => state.activeRole);
	useEffect(() => {
		LoadTasks();
		setStatusFilterOptions(TeacherStatusFilterOptions);
	}, [taskType]);

	const LoadTasks = async () => {
		setIsLoading(true);
		let _task = await TaskService.GetAllAvailableTasks(taskType, userRole);
		setTaskList(_task);
		setIsLoading(false);
	};

	
	const ShowEditTaskModal = (sso: Task) => {
		ShowGeneralModal({
			title: t(`Edit`) ,
			content: <ManageTaskModal ssoId={sso.id} onSave={LoadTasks} />,
		});
	};

	// sets loading for a sso, so the loading indicator appears in the table row near the edit button
	const SetSSOLoading = (ssoId: number, loading: boolean) => {
		let _tmpEvaluations = [...taskList];
		_tmpEvaluations.filter((e) => e.id === ssoId)[0].loading = loading;
		setTaskList(_tmpEvaluations);
	};

	const ShowTaskStudentDescriptionProofreadModal = async (sso: Task) => {
		SetSSOLoading(sso.id, true);
		ShowTaskStudentDescriptionModal(sso.id, LoadTasks, () => SetSSOLoading(sso.id, false));
	};

	let TaskHTMLList = taskList
		.filter((task) => {
			// filter by student name
			if (!filterName) return true;
			return task.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((task) => {
			// filter by description status
			if (!statusFilterValue) return true;
			return task.studentDescription?.status === statusFilterValue.value;
		})
		.map((task) => {
			return (
				<tr key={task.id} >
					<td onClick={() => ShowTaskStudentDescriptionProofreadModal(task)}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={task.student?.name || ""} />
							<div style={{ marginLeft: 8 }}>
								<div>{task.student?.name || ""}</div>
							</div>
						</div>
					</td>
					<td style={{ position: "relative" }} onClick={() => ShowTaskStudentDescriptionProofreadModal(task)}>
						<DescriptionStatusBadge status={task.studentDescription?.status || DescriptionStatus.Created} />
						{task.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
					<td>
						<TextButton icon={<Icon.Edit />} text={t("Edit title")} onClick={() => ShowEditTaskModal(task)} />
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: "16px", display: "flex" }}>
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>

				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Status")}</th>
					</tr>
				</thead>
				<tbody>{TaskHTMLList}</tbody>
			</table>
			{TaskHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
