import Comment from "../model/Comment";
import CommentComponent from "./Comment";
import { useTranslation } from "react-i18next";
import * as CommentService from "../services/Comment";
import { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import * as Icon from "react-feather";

/**
 * The comment feed is displayd on the right of the description modals, and contains the comments and an input to add new comments
 * @param descriptionId the id of the description for which the comments are loaded
 * @param onCommentAdd callback executed everytime a new comment is added
 */
type CommentFeedProps = { descriptionId: number; onCommentAdd?: (comment: string) => any };
export default function CommentFeed({ descriptionId, onCommentAdd }: CommentFeedProps) {
	const { t } = useTranslation();
	const [comments, setComments] = useState<Comment[]>([]);
	const textInput = useRef(null);

	useEffect(() => {
		CommentService.GetCommentsForDescription(descriptionId).then((c) => {
			setComments(c);
		});
	}, [descriptionId]);

	const CreateComment = async () => {
		let commentText = (textInput.current as any).innerHTML as string;

		//because the comment is comming from a content editable it requires some formatting
		commentText = commentText.replaceAll("<div><br></div>", "\n").replaceAll("<div>", "\n").replaceAll("</div>", "").replaceAll("&nbsp;", " "); //remove this stuff that is created when the user adds a new line or multiple new lines
		commentText = _.unescape(commentText); //convert html codes to characters, ex: &lt; become <

		if (!commentText) return;
		try {
			let comment = await CommentService.CreateComment(
				{
					text: commentText,
				},
				descriptionId
			);
			setComments([...comments, comment]);
			(textInput.current as any).innerHTML = "";
			if (onCommentAdd) onCommentAdd(commentText);
		} catch (e) {
			console.error(e.response);
		}
	};

	return (
		<div className="comment-feed">
			<div className="title">{t("Comments")}</div>
			<div className="comment-list">
				{comments.map((comment) => (
					<CommentComponent key={comment.id} comment={comment} />
				))}
			</div>
			<div className="add-comment">
				<span ref={textInput} className="input" role="textbox" contentEditable={true} data-placeholder={t("Write comment here...")}></span>
				<div className="send-button" onClick={CreateComment}>
					<Icon.Send />
				</div>
			</div>
		</div>
	);
}
