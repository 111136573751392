import React, { useEffect, useRef, useState } from "react";
import { CloseGeneralModal } from "../../../components/GeneralModal";
import { UserRoles } from "../../../Constants";
import User from "../../../model/User";
import YearTask from "../../../model/YearTask";
import * as UserService from "../../../services/User";
import * as YearTaskService from "../../../services/YearTask";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import NormalButton from "../../../components/input/NormalButton";
import * as Icon from "react-feather";
import { useSelector } from 'react-redux';
import { RootState } from "../../../app/store";

interface ManageYearTaskModalProps {
	yearTaskId: number;
	onSave: () => any;
}
export default function ManageYearTaskModal({ yearTaskId, onSave}: ManageYearTaskModalProps) {
	const { t } = useTranslation();
	const [title, setTitle] = useState("");

	const [teacherSelectOptions, setTeacherSelectOptions] = useState<{ value: User; label: string }[]>([]); // value => teacher, label => teacher name
	const [teachersSelectedValues, setTeachersSelectedValues] = useState<{ value: User; label: string }[]>([]); // value => teacher, label => teacher name
	const [proofreaderSelectOptions, setProofreaderSelectOptions] = useState<{ value: User; label: string }[]>([]);
	const [proofreaderSelectValue, setProofreaderSelectValue] = useState<{ value: User; label: string } | null>();
	const [isSaving, setIsSaving] = useState(false); // toggle loading indicator on save button
	const firstInput = useRef<HTMLInputElement>(null);
	const userRole = useSelector((state: RootState) => state.activeRole);
	const isTeacher = userRole === UserRoles.Teacher ;

	useEffect(() => {
		firstInput.current?.focus();
		//load the teachers options in the dropdown
		UserService.GetAllUsers().then((loadedUsers) => {
			let _teacherSelectOptions: { value: User; label: string }[] = [];
			let _proofreaderSelectOptions: { value: User; label: string }[] = [];
			loadedUsers.forEach((_user) => {
				if (_user.roles.includes(UserRoles.Teacher))
					_teacherSelectOptions.push({
						value: _user,
						label: _user.name,
					});
				if (_user.roles.includes(UserRoles.ProofReader))
					_proofreaderSelectOptions.push({
						value: _user,
						label: _user.name,
					});
			});

			setTeacherSelectOptions(_teacherSelectOptions);
			setProofreaderSelectOptions(_proofreaderSelectOptions);
		});
		YearTaskService.GetYearTaskById(yearTaskId).then((_yearTask) => {
			setTitle(_yearTask.title);
			if (_yearTask.teachers)
				setTeachersSelectedValues(
					_yearTask.teachers.map((teacher) => ({
						label: teacher.name,
						value: teacher,
					}))
				);
			if (_yearTask.proofreader)
				setProofreaderSelectValue({
					label: _yearTask.proofreader?.name,
					value: _yearTask.proofreader,
				});
		});
	}, []);

	const SaveYearTask = async () => {
		setIsSaving(true);
		const _yearTask: YearTask = {
			id: yearTaskId,
			title: title,
			teachers: teachersSelectedValues.map((t) => t.value),
			proofreader: proofreaderSelectValue ? proofreaderSelectValue.value : undefined,
		};
		await YearTaskService.UpdateYearTask(_yearTask);
		onSave();
		setIsSaving(false);
		CloseGeneralModal();
	};
	let teacherRow = (
		<tr>
			<td>{t("Teacher(s)")}</td>
			<td style={{ width: 300 }}>
				<Select
					placeholder={t("Select teacher...")}
					isMulti={true}
					options={teacherSelectOptions}
					value={teachersSelectedValues}
					onChange={(v) => {
						if (!v) setTeachersSelectedValues([]);
						else setTeachersSelectedValues([...v]);
					}}
					isClearable={true}
				/>
			</td>
		</tr>)

	let proofreaderRow = (
		<tr>
			<td>{t("Proofreader")}</td>
			<td>
				<Select
					placeholder={t("Select proofreader...")}
					options={proofreaderSelectOptions}
					value={proofreaderSelectValue}
					onChange={(v) => setProofreaderSelectValue(v)}
					isClearable={true}
				/>
			</td>
		</tr>)

	return (
		<div>
			<table className="table-with-inputs vertically-centered-labels">
				<tbody>
					<tr>
						<td>{t("Title")}</td>
						<td>
							<input ref={firstInput} type="text" placeholder={t("Title")} value={title} onChange={(v) => setTitle(v.currentTarget.value)} />
						</td>
					</tr>
					{!isTeacher && teacherRow}
					{!isTeacher && proofreaderRow}
				</tbody>
			</table>
			<div style={{ textAlign: "right" }}>
				<NormalButton onClick={SaveYearTask} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
			</div>
		</div>
	);
}
