import Axios from "./CustomAxios";
import Student from "../model/Student";

export const CreateStudent = async (student: Student): Promise<Student> => {
	return (await Axios.post(`/rsvidnesbyrd/student/create`, student)).data;
};

export const UpdateStudent = async (student: Student): Promise<Student> => {
	return (await Axios.put(`/rsvidnesbyrd/student/update`, student)).data;
};

export const GetAllStudents = async (): Promise<Student[]> => {
	return (await Axios.get(`/rsvidnesbyrd/student/all`)).data;
};

export const GetStudentsForCourse = async (courseId?: number): Promise<Student[]> => {
	return (await Axios.get(`/rsvidnesbyrd/student/all`, { params: { courseId: courseId } })).data;
};

export const GetStudent = async (studentId: number): Promise<Student> => {
	return (await Axios.get(`/rsvidnesbyrd/student/${studentId}`)).data;
};

export const DeleteStudent = async (studentId: number) => {
	return await Axios.delete(`/rsvidnesbyrd/student/${studentId}`);
};
