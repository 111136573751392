/**
 * When the user is authneticated this page is displayed. It contains the side menu and the router which loads the other pages.
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import { TaskType, UserRoles } from "../Constants";
import User from "../model/User";
import Year from "../model/Year";
import * as UserService from "../services/User";
import * as YearService from "../services/Year";

import ManageCoursesPage from "./admin/course/ManageCoursesPage";
import GeneratePdfPage from "./admin/generatePdf/GeneratePdfPage";
import ImportPage from "./admin/ImportPage";
import CurrentSchoolYear from "./admin/manageYear/CurrentSchoolYear";
import ManageYear from "./admin/manageYear/ManageYear";
import AdminOtherPage from "./admin/Other";
import ManageClassesPage from "./admin/schoolClass/ManageClassesPage";
import ManageStudentsPage from "./admin/student/ManageStudentsPage";
import ManageSubjectsPage from "./admin/subject/ManageSubjectsPage";
import ManageTaskPage from "./admin/task/ManageTaskPage";
import ManageUsersPage from "./admin/user/ManageUsersPage";
import ManageYearTaskPage from "./admin/yearTask/ManageYearTaskPage";
import ProofReadCoursesPage from "./proofReader/ProofReadCoursesPage";
import ProofReadStudentsPage from "./proofReader/ProofReadEvaluationsPage";
import ProofReadTaskStudentDescription from "./proofReader/ProofReadTaskStudentDescription";
import ProofReadYearTaskStudentDescription from "./proofReader/ProofReadYearTaskStudentDescription";
import CoursePage from "./teacher/CoursePage";
import TaskPage from "./teacher/TaskPage";
import YearTasks from "./teacher/YearTasks";
import Logo from "../resources/img/logo-16.svg";
import OverviewPage from "./common/OverviewPage";
import { useDispatch} from "react-redux";
import { setActiveUserRole } from "../app/userReducer";


export default function App() {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [user, setUser] = useState<User>();
	const [userRole, setUserRole] = useState<UserRoles>(); //the user can change the roles from the select in the header
	const [currentYear, setCurrentYear] = useState<Year>();
	
	const LoadCurrentYear = () => {
		YearService.GetCurrentSchoolYear().then(setCurrentYear);
	};
	useEffect(() => {
		LoadUser();
	}, []);

	const LoadUser = () => {
		UserService.GetAuthenticatedUser().then((_user) => {
			_user.roles = _user.roles.sort();
			setUser(_user);
			if (_user.roles.length === 0) history.push("/noRoles");
		});
	};

	const OnRoleChange = (role: UserRoles) => {
		setUserRole(role);
		dispatch(setActiveUserRole(role));
	};

	return (
		<Router>
			<div>
				<div className="side-menu">
					<img src={Logo} className="logo" alt="Logo" />
					<span style={{ margin: 'auto' }}> <CurrentSchoolYear LoadCurrentYear={LoadCurrentYear} currentSchoolYear={currentYear} /></span>
					<SideMenu userRole={userRole} user={user} />
					<div className="date">version 1.24 (27-03-2024)</div>
				</div >
				<div className="main">
					<Header onRoleChange={OnRoleChange} user={user} onUserInfoUpdate={LoadUser} />
					<div className="content">
						{/* HOW TO ADD A PAGE: 1. Add it here, 2. Add it in SideMenu.tsx, 3. Add it's title in Header.tsx */}
						<Switch>
							{/* ADMIN */}
							<Route path="/adminOverview">
								<OverviewPage/>
							</Route>
							<Route path="/adminUsers">
								<ManageUsersPage />
							</Route>
							<Route path="/adminSubjects">
								<ManageSubjectsPage />
							</Route>
							<Route path="/adminCourses">
								<ManageCoursesPage />
							</Route>
							<Route path="/adminClasses">
								<ManageClassesPage />
							</Route>
							<Route path="/adminYearTasks">
								<ManageYearTaskPage />
							</Route>
							<Route path="/adminTaskSSO">
								<ManageTaskPage taskType={TaskType.SSO} />
							</Route>
							<Route path="/adminTaskEmneopgaver">
								<ManageTaskPage taskType={TaskType.Emneopgave} />
							</Route>
							
							<Route path="/adminStudents">
								<ManageStudentsPage />
							</Route>
							<Route path="/adminImport">
								<ImportPage />
							</Route>
							<Route path="/adminGeneratePDF">
								<GeneratePdfPage />
							</Route>
							<Route path="/manageYear">
								<ManageYear LoadCurrentYear={LoadCurrentYear} currentSchoolYear={currentYear} />
							</Route>
							<Route path="/adminOther">
								<AdminOtherPage />
							</Route>
							<Route path="/course/:id" render={() => <CoursePage/>}></Route>
							<Route path="/adminEditYearTasks">
								<YearTasks />
							</Route>
							<Route path="/adminEditTaskSSO">
								<TaskPage taskType={TaskType.SSO} />
							</Route>
							<Route path="/adminEditTaskEmneopgaver">
								<TaskPage taskType={TaskType.Emneopgave} />
							</Route>

							{/* PROOFREADER */}
							<Route path="/proofReadOverview">
								<OverviewPage />
							</Route>
							<Route path="/proofReadCourses">
								<ProofReadCoursesPage />
							</Route>
							<Route path="/proofReadEvaluations">
								<ProofReadStudentsPage />
							</Route>
							<Route path="/proofReadYearTasks">
								<ProofReadYearTaskStudentDescription/>
							</Route>
							<Route path="/proofReadTaskSSO">
								<ProofReadTaskStudentDescription taskType={TaskType.SSO}/>
							</Route>
							<Route path="/proofReadTaskEmneopgaver">
								<ProofReadTaskStudentDescription taskType={TaskType.Emneopgave} />
							</Route>
							<Route path="/course/:id" render={() => <CoursePage/>}></Route>

							{/* TEACHER */}
							<Route path="/teacherOverview">
								<OverviewPage/>
							</Route>
							<Route path="/teacherYearTasks">
								<YearTasks />
							</Route>
							<Route path="/teacherTaskSSO">
								<TaskPage taskType={TaskType.SSO} />
							</Route>
							<Route path="/teacherTaskEmneopgaver">
								<TaskPage taskType={TaskType.Emneopgave} />
							</Route>
							<Route path="/course/:id" render={() => <CoursePage/>}></Route>

							{/* displayed for the user with no roles, SideMenu.tsx redirects here */}
							<Route path="/noRoles">
								<div>{t("It looks like you don't have any roles...")}</div>
							</Route>

							{/* this page with loading is loaded by default. The side menu will navigate to the correct page after loading the available side menu links */}
							<Route path="/">
								<div>{t("Loading")}...</div>
							</Route>
						</Switch>
					</div>
				</div>
			</div>
		</Router>
	);
}
