import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/Main";
import WelcomePage from "./pages/Welcome";
import "./i18n";

//css
import "./resources/css/main.scss"; //this css imports all the required css
import "react-datepicker/dist/react-datepicker.css";

//redux
import { Provider } from 'react-redux';
import store from "./app/store";

//set the locale for the date picker and moment
import da from "date-fns/locale/da";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import "moment/locale/da";

registerLocale("da", da);
moment.locale("da");

if (window.localStorage.getItem("credentials")) {
	ReactDOM.render(
		<Provider store={store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
		</Provider>,
		document.getElementById("root")
	);
} else {
	ReactDOM.render(<WelcomePage />, document.getElementById("root"));
}
