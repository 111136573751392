import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import * as SubjectCategoryService from "../../../services/SubjectCategory";
import * as Icon from "react-feather";
import SubjectCategory from "../../../model/SubjectCategory";
import { result } from "lodash";
import TextButton from "../../../components/input/TextButton";
import IconButton from "../../../components/input/IconButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { Size } from "../../../Constants";
import _ from "lodash";

const modalContainer = document.querySelector("#manageSubjectCategoriesModalContainer");
type ManagerCategoriesModalProps = { onComplete: Function };

/**
 * Show manage subject modal.
 * @param action ManageModalAction.Edit or ManageModalAction.Create.
 * @param subjectId id of the subject to edit. Required only when editing a subject.
 * @param onComplete callback when user saves, creates or deletes a subject.
 */
export function ShowManageCategoriesModal(onComplete: Function) {
	ReactDOM.render(<ManageCategoriesModal onComplete={onComplete} />, modalContainer);
}

function ManageCategoriesModal({ onComplete }: ManagerCategoriesModalProps) {
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [viewArchived, setViewArchived] = useState(false); //true = show archived categories, false = view active categories
	const [loading, setLoading] = useState(false);
	const [subjectCategories, setSubjectCategories] = useState<SubjectCategory[]>([]);

	//const [error, setError] = useState("");

	useEffect(() => {
		LoadSubjects();
	}, [viewArchived]);

	const LoadSubjects = async () => {
		setLoading(true);
		let subjects = await SubjectCategoryService.GetSubjectCategories(viewArchived);
		subjects.forEach((s) => (s.originalName = s.name));
		setSubjectCategories(subjects);
		setLoading(false);
	};

	const CloseModal = () => {
		if (modalContainer != null) ReactDOM.unmountComponentAtNode(modalContainer);
	};

	const CreateSubjectCategory = async () => {
		if (name.trim().length === 0) return;
		let subject: SubjectCategory = { name: name, subjectsCount: 0, id: 0, archived: false };
		let result;
		try {
			result = await SubjectCategoryService.CreateSubjectCategory(subject);
			console.log(result);
			setName("");
			LoadSubjects();
		} catch (e) {
			console.error("Failed to create subject category", e.response);
		}
	};

	//Show/hide archived categories
	const ToggleArchivedView = async () => {
		if (loading) return;
		setLoading(true); //also set loading here to avoid any flashes
		setSubjectCategories([]);
		setViewArchived(!viewArchived);
	};

	const ToggleArchiveCategory = async (subjectCategory: SubjectCategory) => {
		subjectCategory.archived = !subjectCategory.archived;
		setSubjectCategories(subjectCategories.filter((s) => s.id !== subjectCategory.id));
		let result: SubjectCategory = await SubjectCategoryService.UpdateSubjectCategory(subjectCategory);
		console.log(result);
	};

	const ToggleCategoryRename = (categoryIndex: number) => {
		let _categories = _.cloneDeep(subjectCategories);
		_categories[categoryIndex].editMode = !_categories[categoryIndex].editMode;
		setSubjectCategories(_categories);
	};

	const CancelCategoryRename = (categoryIndex: number) => {
		let _categories = _.cloneDeep(subjectCategories);
		_categories[categoryIndex].editMode = false;
		_categories[categoryIndex].name = _categories[categoryIndex].originalName || "";
		setSubjectCategories(_categories);
	};

	const SaveCategoryName = async (categoryIndex: number) => {
		let _categories = _.cloneDeep(subjectCategories);
		_categories[categoryIndex].editMode = false;
		_categories[categoryIndex].originalName = _categories[categoryIndex].name;
		SubjectCategoryService.UpdateSubjectCategory(_categories[categoryIndex]);
		setSubjectCategories(_categories);
	};

	const SubjectTableBody = subjectCategories.map((category, index) => {
		return (
			<tr key={category.id}>
				<td style={{ width: 30 }}>{index + 1}.</td>
				<td style={{ width: 200 }}>
					{/* When not in edit mode, show category name and number of subjects */}
					{!category.editMode && (
						<div>
							{category.name}
							<div className="info-text">
								{category.subjectsCount} {category.subjectsCount === 1 ? t("subject") : t("subjects")}
							</div>
						</div>
					)}
					{/* In edit mode, show a field to type new name and save/cancel buttons */}
					{category.editMode && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<input
								type="text"
								style={{ width: 125 }}
								value={category.name}
								onInput={(e) => {
									let _categories = _.cloneDeep(subjectCategories);
									_categories[index].name = e.currentTarget.value;
									setSubjectCategories(_categories);
								}}
							/>
							<IconButton
								icon={<Icon.Check />}
								onClick={() => {
									SaveCategoryName(index);
								}}
								size={Size.SM}
								color="green"
							/>
							<IconButton
								icon={<Icon.X />}
								onClick={() => {
									CancelCategoryRename(index);
								}}
								size={Size.SM}
								color="red"
							/>
						</div>
					)}
				</td>
				<td>
					{!viewArchived && <IconButton icon={<Icon.Edit />} onClick={() => ToggleCategoryRename(index)} />}
					&nbsp;&nbsp;&nbsp;
					<IconButton icon={<Icon.Archive />} onClick={() => ToggleArchiveCategory(category)} />
				</td>
			</tr>
		);
	});

	const SubjectListComponent = (
		<table className="table scrollable" style={{ flex: 1 }}>
			<thead>
				<tr>
					<th style={{ width: 30 }}>#</th>
					<th style={{ width: 200 }}>{t("Category")}</th>
					<th>{t("Actions")}</th>
				</tr>
			</thead>
			<tbody>{SubjectTableBody}</tbody>
		</table>
	);

	const AddSubjectCategoryComponent = (
		<div style={{ marginTop: 12 }}>
			<div className="label">{t("Add category")}</div>
			<div style={{ display: "flex" }}>
				<input value={name} onChange={(e) => setName(e.currentTarget.value)} style={{ flex: 1 }} placeholder={t("Category name") + "..."} />
				<button onClick={CreateSubjectCategory} className="btn blue" style={{ minWidth: 80, marginLeft: 8 }}>
					<Icon.PlusSquare />
					{t("Add")}
				</button>
			</div>
		</div>
	);

	return (
		<div className="modal">
			<div className="content" style={{ width: 500, minHeight: "70vh", display: "flex", flexDirection: "column" }}>
				<div className="title">{t("Manage categories")}</div>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>
				<div style={{ textAlign: "right", marginBottom: 4 }}>
					{!viewArchived && <TextButton text={t("Show archived")} onClick={ToggleArchivedView} />}
					{viewArchived && <TextButton text={t("Show active")} onClick={ToggleArchivedView} />}
				</div>
				{loading && <div style={{ flex: 1 }}></div>}
				{subjectCategories.length === 0 && !loading && (
					<div className="nothing-to-display-banner" style={{ flex: 1 }}>
						{t("There is nothing to display")}
					</div>
				)}
				{SubjectTableBody.length > 0 && SubjectListComponent}
				{!viewArchived && AddSubjectCategoryComponent}
			</div>
		</div>
	);
}
