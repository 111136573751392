import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowErrorPopup } from "../../../components/ErrorPopup";
import IconButton from "../../../components/input/IconButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Course from "../../../model/Course";
import SchoolClass, { GetClassName } from "../../../model/SchoolClass";
import Student from "../../../model/Student";
import * as CourseService from "../../../services/Course";
import * as SchoolClassService from "../../../services/SchoolClass";
import ShowStudentSelectModal, { StudentSelectModalIntent } from "./StudentSelectModal";

const modalContainer = document.querySelector("#manageClassCoursesModalContainer");
interface ManageClassCoursesModalProps {
	schoolClass: SchoolClass;
	allCourses: Course[];
	allStudents: Student[];
	onSave: () => any;
}

export default function ShowManageClassCoursesModal(props: ManageClassCoursesModalProps) {
	ReactDOM.render(
		<ManageClassCoursesModal schoolClass={props.schoolClass} allCourses={props.allCourses} onSave={props.onSave} allStudents={props.allStudents} />,
		modalContainer
	);
}

function ManageClassCoursesModal(props: ManageClassCoursesModalProps) {
	const { t } = useTranslation();
	const [selectedCourses, setSelectedCourses] = useState(props.schoolClass.courses || []);
	const [addCourseSelectOptions, setAddCourseSelectOptions] = useState<{ value: Course; label: string }[]>([]);
	const [selectStudentModalVisible, setSelectedStudentModalVisible] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [waitForCourseAdd, setWaitForCourseAdd] = useState(false);

	useEffect(() => {
		RefreshCourseSelectOptions();
	}, [selectedCourses]); // everytime the select courses are changed, refresh the courses select options

	const CloseModal = () => {
		if (modalContainer != null) ReactDOM.unmountComponentAtNode(modalContainer);
	};

	/** Fills addCourseSelectOptions with courses that aren't already added to the class */
	const RefreshCourseSelectOptions = () => {
		let selectedCourseIds = selectedCourses.map((c) => c.id);

		setAddCourseSelectOptions(
			props.allCourses
				.filter((course) => selectedCourseIds.indexOf(course.id) < 0)
				.map((course) => {
					return { value: course, label: course.name };
				})
		);
	};

	const AddCourse = (courseToAdd: Course) => {
		setWaitForCourseAdd(true);
		// fetch the existing course, because it may contain students from other classes that need to be appended also, besides the students from this class.
		CourseService.GetCourse(courseToAdd.id)
			.then((existingCourse) => {
				courseToAdd.students = props.schoolClass.students;
				courseToAdd.students = [...(courseToAdd.students || []), ...(existingCourse.students || [])];
				courseToAdd.students = _.uniqBy(courseToAdd.students, "id");
				setSelectedCourses([...selectedCourses, courseToAdd]);
				RefreshCourseSelectOptions();
			})
			.catch((e) => {
				console.log("Failed to fetch existing course", e);
				ShowErrorPopup(t("An error occured when adding the course"));
			})
			.finally(() => {
				setWaitForCourseAdd(false);
			});
	};

	const RemoveCourse = (course: Course) => {
		setSelectedCourses(selectedCourses.filter((c) => c.id !== course.id));
	};

	const AddStudentsToCourse = (course: Course, students: Student[]) => {
		let copy = _.clone(selectedCourses); //should be a copy otherwise it did not refresh the UI
		copy.forEach((_course) => {
			if (_course.id === course.id) _course.students = students;
		});
		setSelectedCourses(copy);
	};

	const Save = () => {
		setIsSaving(true);
		let schoolClass = props.schoolClass;
		schoolClass.courses = selectedCourses;
		SchoolClassService.UpdateCourses(schoolClass)
			.then(() => {
				CloseModal();
				setIsSaving(false);
				props.onSave();
			})
			.catch((e) => {
				setIsSaving(false);
				console.error(e);
			});
	};

	const SelectedCoursesHTMLList = selectedCourses
		.sort((a, b) => {
			// sort the courses asc by name
			if (b.name > a.name) return -1;
			if (b.name < a.name) return 1;
			return 0;
		})
		.map((course) => {
			return (
				<tr key={course.id}>
					<td>{course.name}</td>
					<td>
						<button
							onClick={() => {
								ShowStudentSelectModal({
									allStudents: props.allStudents,
									selectedStudents: course.students || [],
									title: `${course.name} ${t("students")}`,
									onSave: (students) => AddStudentsToCourse(course, students),
									onClose: () => {
										setSelectedStudentModalVisible(false);
									},
									intent: StudentSelectModalIntent.ForCourse,
								});
								setSelectedStudentModalVisible(true);
							}}
							className="btn blue text"
						>
							{`${t("Manage")} (${course.students ? course.students.length : 0})`}
						</button>
					</td>
					<td>
						<IconButton onClick={() => RemoveCourse(course)} icon={<Icon.Trash2 />} />
					</td>
				</tr>
			);
		});

	return (
		<div className={`modal ${selectStudentModalVisible ? "hidden" : ""}`}>
			<div className="content">
				<div className="title">
					{t("Manage")} {GetClassName(props.schoolClass)} {t("courses")}
				</div>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>
				<div>
					<div>
						<table className="table scrollable" style={{ width: "500px" }}>
							<thead>
								<tr>
									<th>{t("Course")}</th>
									<th>{t("Students")}</th>
									<th>{t("Remove")}</th>
								</tr>
							</thead>
							<tbody>{SelectedCoursesHTMLList}</tbody>
						</table>
					</div>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 16, marginBottom: 8 }}>
						<b>{t("Add course")}</b>&nbsp;&nbsp;&nbsp;
						<div style={{ minWidth: 250 }}>
							<Select
								isDisabled={waitForCourseAdd}
								value={null}
								placeholder={t("Select course to add...")}
								options={addCourseSelectOptions}
								onChange={(v) => {
									if (v) AddCourse(v.value);
								}}
							/>
						</div>
					</div>
					<div style={{ marginTop: 12, marginBottom: 12 }} className="info-text">
						{t("When adding a course, by default, all the students in the class are placed in the course.")}
						<br />
						{t("When a course is removed, the students from that course that are from this class are also removed from the course")}
					</div>
					<div style={{ textAlign: "right" }}>
						<button
							className="btn blue"
							onClick={() => {
								Save();
							}}
						>
							{!isSaving && <Icon.Save />}
							{isSaving && <LoadingIndicator />}
							{t("Save")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
