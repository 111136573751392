import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowGeneralModal } from "../../../components/GeneralModal";
import TextButton from "../../../components/input/TextButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import { TaskType } from "../../../Constants";
import Task from "../../../model/Task";
import User from "../../../model/User";
import * as SSOService from "../../../services/Task";
import EditDefaultTextsModal from "../EditDefaultTextsModal";
import ManageTaskModal from "./ManageTaskModal";

interface Props {
	taskType: TaskType;
}

/**
 * This page is for managing either SSO or Emneopgaver
 */
export default function ManageTaskPage({ taskType }: Props) {
	const { t } = useTranslation();
	const [taskList, setTaskList] = useState<Task[]>([]);
	const [isLoading, setIsLoading] = useState(false); // toggle the loading indicator in table
	const [filterName, setFilterName] = useState("");
	const [filterTeacherSelectOptions, setFilterTeacherSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => teacher name
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => teacher name
	const [filterProofreaderSelectOptions, setFilterProofreaderSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => proofreader name
	const [proofreaderFilterValue, setProofreaderFilterValue] = useState<{ label: string; value: User } | undefined | null>(); // label => proofreader name

	useEffect(() => {
		LoadTasks();
	}, [taskType]);

	const LoadTasks = async () => {
		setIsLoading(true);
		let _tasks = await SSOService.GetAllAvailableForAdmin(taskType);

		let teachers: User[] = [];
		let proofreaders: User[] = [];

		// populate the subjects, teachers and proofreaders arrays
		_tasks.forEach((_task) => {
			if (_task.teachers) _task.teachers.forEach((t) => teachers.push(t));
			if (_task.proofreader) proofreaders.push(_task.proofreader);
		});

		// only keep the unique values in these arrays
		teachers = _.uniqBy(teachers, "id");
		proofreaders = _.uniqBy(proofreaders, "id");

		// populate the teachers filter
		setFilterTeacherSelectOptions(
			teachers.map((t) => {
				return {
					value: t,
					label: t.name,
				};
			})
		);
		// populate the proofreaders filter
		setFilterProofreaderSelectOptions(
			proofreaders.map((t) => {
				return {
					value: t,
					label: t.name,
				};
			})
		);

		setTaskList(_tasks);
		setIsLoading(false);
	};

	const ShowEditTaskModal = (sso: Task) => {
		let s = "";
		switch (taskType) {
			case TaskType.Emneopgave:
				s = t("Subject task");
				break;
			case TaskType.SSO:
				s = t("SSO");
				break;
		}
		ShowGeneralModal({
			title: t(`Edit`) + " " + s,
			content: <ManageTaskModal ssoId={sso.id} onSave={LoadTasks}/>,
		});
	};

	// show modal to edit default evaluation info and comment
	const ShowEditDefaultTextsModal = () => {
		ShowGeneralModal({
			content: <EditDefaultTextsModal taskType={taskType} />,
			title: t(`taskType.${taskType}`) + " " + t("default texts"),
		});
	};

	const taskHTMLList = taskList
		.filter((task) => {
			//filter by student name
			if (!filterName) return true;
			return task.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((task) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!task.teachers) return true;
			return task.teachers.map((t) => t.id).includes(teacherFilterValue.value.id);
		})
		.filter((task) => {
			// filter by proofreader
			if (!proofreaderFilterValue) return true;
			return task.proofreader?.id === proofreaderFilterValue.value.id;
		})
		.map((sso) => (
			<tr key={sso.id}>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ProfileColoredCircle size="32px" username={sso.student?.name || ""} />
						<div style={{ marginLeft: 8 }}>
							<div>{sso.student?.name}</div>
							<div className="info-text">{sso.title}</div>
						</div>
					</div>
				</td>
				<td>
					<div>{sso.subject?.name}</div>
					<div className="info-text">{sso.subject?.subjectCategory?.name}</div>
				</td>
				<td>
					{sso.teachers
						?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
						.map((t) => (
							<div style={{ display: "flex", alignItems: "center", marginTop: 4, marginBottom: 4 }}>
								<ProfileColoredCircle username={t.name || ""} size="24px" />
								&nbsp;<div>{t.name}</div>
							</div>
						))}
				</td>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ProfileColoredCircle username={sso.proofreader?.name || ""} size="24px" />
						&nbsp; {sso.proofreader?.name}
					</div>
				</td>
				<td>
					<TextButton icon={<Icon.Edit />} text={t("Edit")} onClick={() => ShowEditTaskModal(sso)} />
				</td>
			</tr>
		));

	return (
		<div style={{ display: "inline-block", minWidth: 900 }}>
			<div className="controls-above-table">
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by teacher")} options={filterTeacherSelectOptions} isClearable={true} onChange={setTeacherFilterValue} />
				</div>
				<div className="filter">
					<Select placeholder={t("Filter by proofreader")} options={filterProofreaderSelectOptions} isClearable={true} onChange={setProofreaderFilterValue} />
				</div>
				<div className="actions">
					<button onClick={ShowEditDefaultTextsModal} className="btn blue">
						<Icon.Edit />
						{t("Default texts")}
					</button>
				</div>
			</div>

			<table className="table">
				<thead>
					<tr>
						<th style={{ minWidth: "250px", position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Subject")}</th>
						<th>{t("Teacher(s)")}</th>
						<th>{t("Proofreader")}</th>
						<th>{t("Actions")}</th>
					</tr>
				</thead>
				<tbody>{taskHTMLList}</tbody>
			</table>

			{taskHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}

			{taskType === TaskType.SSO && (
				<div className="info-text">
					{t("SSO are created when the student is added to a 2HF class. If you removed the student from the class, the SSO is deleted.")}
				</div>
			)}

			{taskType === TaskType.Emneopgave && (
				<div className="info-text">
					{t("Emneopgaver are created when the student is added to a 10 class. If you removed the student from the class, the Emneopgaver is deleted.")}
				</div>
			)}
		</div>
	);
}
