import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { UserRoles } from "../Constants";
import Course from "../model/Course";
import User from "../model/User";
import * as CourseService from "../services/Course";
import { GetSchoolData } from "../services/School";

// The user can change the roles from the header dropdown if he/she has multiple roles.
type SideMenuProps = { userRole: UserRoles | undefined; user: User | undefined };

export default function SideMenu({ userRole, user }: SideMenuProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const [showEmneOpgave, setShowEmneOpgave] = useState<boolean>();
	const [showCourseDescription, setShowCourseDescription] = useState<boolean>(false);
	const [teacherCourses, setTeacherCourses] = useState<Course[] | undefined>(); //in case the user has a teacher role, the courses will be preloaded here
	let menuItemsHTML: {} = []; //the list with menu items will be loaded here
	const adminTaskEmne = showEmneOpgave ? 
	(
		<NavLink exact={true} className="link" to="/adminTaskEmneopgaver" key="adminTaskEmneopgaver">
			{t("Emneopgaver")}
		</NavLink>
	)
	: '' ;
	const teacherTaskEmne = showEmneOpgave ? 
	(
		<NavLink exact={true} className="link" to="/teacherTaskEmneopgaver" key="teacherTaskEmneopgaver">
			{t("Emneopgaver")}
		</NavLink>
	)
	: '' ;
	const proofreaderTaskEmne = showEmneOpgave ? 
	(
		<NavLink exact={true} className="link" to="/proofReadTaskEmneopgaver" key="proofReadTaskEmneopgaver">
			{t("Emneopgaver")}
		</NavLink>
	)
	: '' ;
	const LoadSchool = async () => {
		let data = await GetSchoolData();
		setShowEmneOpgave(data.showEmneOpgave);
		setShowCourseDescription(data.showCourseDescriptions || false)
	}
	useEffect(() => {
		//load courses if current user has a teacher role
		if (user && user.roles.includes(UserRoles.Teacher)) CourseService.GetAllCoursesForTeacher(user.id).then(setTeacherCourses);
		LoadSchool();//<-- loaded for setting showEmneOpgave
	}, [user, userRole]);

	//load menu links depending on the role and navigate to the required page
	switch (userRole) {
		case UserRoles.Admin:
			menuItemsHTML = [
				<div className="side-menu-header" key="adminTitle">
					{t("Administrator")}
				</div>,
				<NavLink exact={true} className="link" to="/adminOverview" key="adminOverview">
					{t("Overview")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminUsers" key="adminUsers">
					{t("Users")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminSubjects" key="adminSubjects">
					{t("Subjects")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminCourses" key="adminCourses">
					{t("Courses")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminClasses" key="adminClasses">
					{t("Classes")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminYearTasks" key="adminYearTasks">
					{t("Year task")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminTaskSSO" key="adminTaskSSO">
					{t("SSO")}
				</NavLink>,
				adminTaskEmne,
				<NavLink exact={true} className="link" to="/adminStudents" key="adminStudents">
					{t("Students")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminImport" key="adminImport">
					{t("Import")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminGeneratePDF" key="adminGeneratePDF">
					{t("Generate PDF")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/manageYear" key="manageYear">
					{t("Year")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/adminOther" key="adminOther">
					{t("Other")}
				</NavLink>,
			];
			// main.tsx contains reference to setCurrentYear useState and forces re-render of menu when the year changes/reloads  
			if (window.location.href.indexOf('manageYear') == -1) {
				history.push("/adminOverview");
			}
			break;
		case UserRoles.Teacher:
			if (!teacherCourses) break;
			menuItemsHTML = [
				<div className="side-menu-header" key="teacherTitle">
					{t("Teacher")}
				</div>,
				<NavLink exact={true} className="link" to="/teacherOverview" key="teacherOverview">
					{t("Overview")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/teacherYearTasks" key="teacherYearTasks">
					{t("Year task")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/teacherTaskSSO" key="teacherTaskSSO">
					{t("SSO")}
				</NavLink>,
				teacherTaskEmne,
				<div className="side-menu-header" key="teacherTitle2">
					{t("Courses")}
				</div>,
				...teacherCourses.map((course) => (
					<NavLink className="link" to={`/course/${course.id}`} key={course.id}>
						{course.name}
					</NavLink>
				)),
			];
			history.push(`/teacherOverview`);
			break;
		case UserRoles.ProofReader:
			menuItemsHTML = [
				<div className="side-menu-header" key="proodreaderTitle">
					{t("Proofreader")}
				</div>,
				<NavLink exact={true} className="link" to="/proofReadOverview" key="proofReadOverview">
					{t("Overview")}
				</NavLink>,
				showCourseDescription ? (
				<NavLink className="link" to="/proofReadCourses" key="proofReadCourses">
					{t("Course descriptions")}
				</NavLink>) : null,
				<NavLink className="link" to="/proofReadEvaluations" key="proofReadEvaluations">
					{t("Student evaluations")}
				</NavLink>,
				<NavLink className="link" to="/proofReadYearTasks" key="proofReadYearTasks">
					{t("Year task")}
				</NavLink>,
				<NavLink exact={true} className="link" to="/proofReadTaskSSO" key="proofReadTaskSSO">
					{t("SSO")}
				</NavLink>,
				proofreaderTaskEmne,
			].filter(Boolean);
			history.push("/proofReadOverview");
			break;
	}

	return (
		<div className="content">{menuItemsHTML}</div>
	);
}
