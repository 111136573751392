import Axios from "./CustomAxios";
import Subject from "../model/Subject";

export const CreateSubject = async (subject: Subject): Promise<Subject> => {
	return (await Axios.post(`/rsvidnesbyrd/subject/create`, subject)).data;
};

export const GetSubjects = async (): Promise<Subject[]> => {
	return (await Axios.get(`/rsvidnesbyrd/subject/all`)).data;
};

export const GetSubject = async (subjectId: number): Promise<Subject> => {
	return (await Axios.get(`/rsvidnesbyrd/subject/${subjectId}`)).data;
};

export const UpdateSubject = async (subject: Subject) => {
	return (await Axios.put(`/rsvidnesbyrd/subject/update`, subject)).data;
};

/**
 * Update the pdf order of multiple subjects. Be sure to set the pdfOrder.
 */
export const BulkUpdateOrder = async (subjects: Subject[]): Promise<Subject[]> => {
	return (await Axios.put(`/rsvidnesbyrd/subject/bulkUpdateOrder`, subjects)).data;
};

export const DeleteSubject = async (subjectId: number) => {
	return await Axios.delete(`/rsvidnesbyrd/subject/${subjectId}`);
};
