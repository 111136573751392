import Axios from "./CustomAxios";
import SubjectCategory from "../model/SubjectCategory";

export const CreateSubjectCategory = async (subjectCategory: SubjectCategory): Promise<SubjectCategory> => {
	return (await Axios.post(`/rsvidnesbyrd/subjectCategory/create`, subjectCategory)).data;
};

export const UpdateSubjectCategory = async (subjectCategory: SubjectCategory): Promise<SubjectCategory> => {
	return (await Axios.put(`/rsvidnesbyrd/subjectCategory/update`, subjectCategory)).data;
};

/**
 * Update the pdf order of multiple subject categories. Be sure to set the pdfOrder.
 */
export const BulkUpdateOrder = async (subjectCategories: SubjectCategory[]): Promise<SubjectCategory[]> => {
	return (await Axios.put(`/rsvidnesbyrd/subjectCategory/bulkUpdateOrder`, subjectCategories)).data;
};

export const GetSubjectCategories = async (archived: boolean): Promise<SubjectCategory[]> => {
	return (await Axios.get(`/rsvidnesbyrd/subjectCategory/all?archived=${archived}`)).data;
};
