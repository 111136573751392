import Axios from "./CustomAxios";
import Comment from "../model/Comment";

export const GetCommentsForDescription = async (descriptionId: number): Promise<Comment[]> => {
	return (await Axios.get(`/rsvidnesbyrd/comment/list/${descriptionId}`)).data;
};

/**
 *
 * @param comment the comment to create, should contain only the "text" field
 * @param descriptionId the description to which this comment will be added
 */
export const CreateComment = async (comment: Comment, descriptionId: number): Promise<Comment> => {
	return (await Axios.post(`/rsvidnesbyrd/comment/create/${descriptionId}`, comment)).data.data;
};
