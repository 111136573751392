import { useState } from "react";
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import Student from "../../../model/Student";

const modalContainer = document.querySelector("#selectStudentsModalContainer");
interface StudentSelectModalProps {
	title: string;
	selectedStudents: Student[];
	allStudents: Student[];
	onSave: (students: Student[]) => any;
	onClose: () => any;
	intent: StudentSelectModalIntent;
}
export enum StudentSelectModalIntent {
	ForClass,
	ForCourse,
}

export default function ShowStudentSelectModal(props: StudentSelectModalProps) {
	ReactDOM.render(
		<StudentSelectModal
			title={props.title}
			selectedStudents={props.selectedStudents}
			allStudents={props.allStudents}
			onSave={props.onSave}
			onClose={props.onClose}
			intent={props.intent}
		/>,
		modalContainer
	);
}

function StudentSelectModal({ title, selectedStudents, allStudents, onSave, onClose, intent }: StudentSelectModalProps) {
	const { t } = useTranslation();
	const [_selectedStudents, setSelectedStudents] = useState(selectedStudents);
	const [showOnlySelected, setShowOnlySelected] = useState(false);
	const [filterText, setFilterText] = useState("");

	const CloseModal = () => {
		if (modalContainer != null) ReactDOM.unmountComponentAtNode(modalContainer);
		onClose();
	};

	const StudentsHTML = allStudents
		.filter((student) => {
			if (!showOnlySelected) return true;
			return _selectedStudents.map((s) => s.id).indexOf(student.id) >= 0;
		})
		.filter((student) => {
			if (filterText.length === 0) return true;
			return student.name.toLowerCase().includes(filterText.toLowerCase());
		})
		.map((student) => {
			return (
				<tr key={student.id} className="interactible" onClick={() => OnStudentCheckboxClick(student)}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle username={student.name} size="32px" />
							<div style={{ marginLeft: 8 }}>{student.name}</div>
						</div>
					</td>
					<td style={{ width: 50 }}>
						<input
							type="checkbox"
							checked={_selectedStudents.map((s) => s.id).indexOf(student.id) >= 0}
							onClick={(e) => {
								// prevent propagation to row
								e.stopPropagation();
							}}
							onChange={() => OnStudentCheckboxClick(student)}
						/>
					</td>
				</tr>
			);
		});

	const OnStudentCheckboxClick = (student: Student) => {
		console.log("asd");
		let newSelectedStudents: Student[] = [];
		if (_selectedStudents.map((s) => s.id).indexOf(student.id) >= 0) {
			newSelectedStudents = _selectedStudents.filter((s) => s.id !== student.id);
		} else {
			newSelectedStudents = [..._selectedStudents, student];
		}
		setSelectedStudents(newSelectedStudents);
	};

	const OnAllCheckboxClick = () => {
		if (_selectedStudents.length === allStudents.length) setSelectedStudents([]);
		else setSelectedStudents(allStudents);
	};

	return (
		<div className="modal">
			<div className="content" style={{ width: 700 }}>
				<div className="title">{title}</div>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>

				<div>
					<div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
						<div style={{ flex: 1 }}>
							<input type="text" placeholder={t("Search by name...")} value={filterText} onChange={(e) => setFilterText(e.currentTarget.value)} />
						</div>
						<div>
							<label style={{ display: "flex" }}>
								<input
									type="checkbox"
									checked={showOnlySelected}
									onChange={(e) => {
										setShowOnlySelected(!showOnlySelected);
									}}
								/>
								{t("show only selected")} ({_selectedStudents.length})
							</label>
						</div>
					</div>
					<table className="table scrollable">
						<thead>
							<tr>
								{/* <th style={{ width: 50 }}>#</th> */}
								<th>{t("Student")}</th>
								<th style={{ width: 50 }}>
									<input type="checkbox" onChange={OnAllCheckboxClick} checked={_selectedStudents.length === allStudents.length} />
								</th>
							</tr>
						</thead>
						<tbody>{StudentsHTML}</tbody>
					</table>
				</div>

				<div style={{ marginTop: 12 }}>
					{intent === StudentSelectModalIntent.ForClass && (
						<div className="info-text">
							{t("Adding a new student will also add him/her to existing courses in this class.")}
							<br />
							{t("Removing a student will also remove him/her from all the courses in this class.")}
						</div>
					)}
				</div>

				<div style={{ textAlign: "right", marginTop: 8 }}>
					<button
						className="btn blue"
						onClick={() => {
							onSave(_selectedStudents);
							CloseModal();
						}}
					>
						<Icon.Save />
						{t("Save")}
					</button>
				</div>
			</div>
		</div>
	);
}
