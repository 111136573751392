import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommentFeed from "../../components/ComentFeed";
import { ShowGeneralModal } from "../../components/GeneralModal";
import Task from "../../model/Task";
import * as TaskService from "../../services/Task";
import DescriptionSection from "./DescriptionSection";

/**
 * Show the modal to edit the sso or emngeopgaver student description. Can be used by both teacher and proofreader, and will appear differently for them.
 * @param taskId id the of the task to load
 * @param onSave an action to perform when the modal is saved (refresh the tasks list perhaps)
 * @param onLoadingFinished when the task is loaded and the modal is showed, this callback is called
 */
export const ShowTaskStudentDescriptionModal = async (taskId: number, onSave: () => any, onLoadingFinished: () => any) => {
	const task = await TaskService.GetById(taskId);
	onLoadingFinished();
	ShowGeneralModal({
		content: <TaskStudentDescriptionModal task={task} onSave={onSave} />,
	});
};

/**
 * Common modal body for teacher and proofreader to edit/proofread the student evaluation.
 */
type TaskStudentDescriptionModalProps = { task: Task; onSave: () => any };
export default function TaskStudentDescriptionModal({ task, onSave }: TaskStudentDescriptionModalProps) {
	const { t } = useTranslation();
	const [hasWrittenComment, setHasWrittenComment] = useState(false); // track if a new comment was written since the modal was opened, to allow/prevent rejecting description

	return (
		<div style={{ display: "flex", width: "70vw", height: "80vh" }}>
			{/* DESCRIPTION FIELDS */}
			<div
				className="flexCol1"
				style={{
					flex: 0.7,
					marginRight: 24,
					borderRight: "1px solid rgba(0,0,0,0.12)",
					paddingRight: 24,
				}}
			>
				<div style={{ fontSize: "1.5rem", color: "black", fontWeight: 500, marginBottom: "4px" }}>
					{task.student?.name} ({t(`taskType.${task.type}`)})
				</div>
				{task.studentDescription && (
					<DescriptionSection hasWrittenComment={hasWrittenComment} description={task.studentDescription} onSave={onSave} />
				)}
			</div>
			{/* COMMENTS */}
			<div style={{ flex: 0.3, display: "flex", alignItems: "stretch", justifyContent: "stretch" }}>
				{task.studentDescription && (
					<CommentFeed
						descriptionId={task.studentDescription.id}
						onCommentAdd={() => {
							setHasWrittenComment(true);
						}}
					/>
				)}
			</div>
		</div>
	);
}
