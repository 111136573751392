import Axios from "./CustomAxios";
import SchoolClass from "../model/SchoolClass";

export const CreateSchoolClass = async (schoolClass: SchoolClass): Promise<SchoolClass> => {
	return (await Axios.post(`/rsvidnesbyrd/schoolClass/create`, schoolClass)).data;
};

export const GetAllSchoolClasses = async (): Promise<SchoolClass[]> => {
	return (await Axios.get(`/rsvidnesbyrd/schoolClass/all`)).data;
};

export const GetSchoolClass = async (schoolClassId: number): Promise<SchoolClass> => {
	return (await Axios.get(`/rsvidnesbyrd/schoolClass/${schoolClassId}`)).data;
};

/**
 * This only updates metadata like class level, date etc. It will not update courses or students related to the class
 * @param schoolClass
 */
export const UpdateSchoolClass = async (schoolClass: SchoolClass) => {
	return (await Axios.put(`/rsvidnesbyrd/schoolClass/update`, schoolClass)).data;
};

/**
 * Update (adds or removes) students in class
 * @param schoolClass only the id of the class and the students are required
 */
export const UpdateStudents = async (schoolClass: SchoolClass) => {
	return (await Axios.put(`/rsvidnesbyrd/schoolClass/updateStudents`, schoolClass)).data;
};

/**
 * Update (adds or removes) courses in class
 * @param schoolClass only the id of the class and the courses are required
 */
export const UpdateCourses = async (schoolClass: SchoolClass) => {
	return (await Axios.put(`/rsvidnesbyrd/schoolClass/updateCourses`, schoolClass)).data;
};

export const DeleteSchoolClass = async (schoolClassId: number) => {
	return await Axios.delete(`/rsvidnesbyrd/schoolClass/${schoolClassId}`);
};
