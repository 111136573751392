/** This is a custom axios client that uses oauth2 authentication */
import axios from "axios";
import OAuth2Credentials from "../model/OAuth2Credentials";
import * as Constants from "../Constants";
const axiosApiInstance = axios.create();

/** This custom request interceptor automatically adds the Bearer token and other headers to the request*/
axiosApiInstance.interceptors.request.use(
	async (config) => {
		const credentails: OAuth2Credentials = JSON.parse(window.localStorage.getItem(Constants.LocalStorageKeys.credentials) || "{}");
		config.headers = {
			Authorization: `Bearer ${credentails.access_token}`,
			"Content-Type": "application/json",
		};
		if (config.url && !config.url.includes(Constants.apiUrl))
			//the repeated requests after oauth refresh already have the correct url set here
			config.url = `${Constants.apiUrl}${config.url}`;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

/** This custom response interceptor will try to refresh the token if it is expired. If it fails, it removes the token from localstorage and redirects to login so the user will sign in again */
axiosApiInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const access_token = await refreshAccessToken();
			if (!access_token) {
				//access token could not be refreshed
				window.localStorage.removeItem(Constants.LocalStorageKeys.credentials);
				window.location.href = Constants.clientUrl; //user will sign in again
				return Promise.reject(error);
			}
			axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
			return axiosApiInstance(originalRequest);
		}
		return Promise.reject(error);
	}
);

const refreshAccessToken = async () => {
	console.log("Refresh access_token");
	const oldCredentials: OAuth2Credentials = JSON.parse(window.localStorage.getItem(Constants.LocalStorageKeys.credentials) || "{}");
	let response;
	try {
		response = await axios.post(`${Constants.apiUrl}/rsvidnesbyrd/auth/refreshToken`, { refresh_token: oldCredentials.refresh_token });
		const newCredentails: OAuth2Credentials = response.data;
		window.localStorage.setItem(Constants.LocalStorageKeys.credentials, JSON.stringify(newCredentails));
		console.log("Token refreshed!");
		return newCredentails.access_token;
	} catch (e) {
		console.log("Refresh token failed", e);
		return undefined;
	}
};

export default axiosApiInstance;
