import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NormalButton from "../../components/input/NormalButton";
import { CloseGeneralModal, ShowGeneralModal } from "../../components/GeneralModal";
import CourseDescriptionModal from "../common/CourseDescriptionModal";
import StudentEvaluationList from "./StudentEvaluationList";
import BulkAbsenceEdit from "./BulkAbsenceEdit";
import * as Icon from "react-feather";
import * as CourseService from "../../services/Course";
import Course from "../../model/Course";
import { DescriptionStatus, TeacherStatusFilterOptions } from "../../Constants";
import Select from "react-select";
import * as EvaluationService from "../../services/Evaluation";
import _ from "lodash";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import { GetSchoolData } from "../../services/School";

/**
 * The course page is displayed when a course is selected from the side menu.
 * It contains the list of students in that course, and some course actions, like edit course description.
 */
interface CoursePageParams {
	id: string; //id of the course is a path parameter
}
export default function CoursePage() {
	const { t } = useTranslation();
	const { id } = useParams<CoursePageParams>(); //id of the course from url
	const courseId = parseInt(id);
	const [filterName, setFilterName] = useState("");
	const [loadingEditCourse, setLoadingEditCourse] = useState(false);
	const [showCourseDescription, setShowCourseDescription] = useState<boolean>(false);
	const [showEditAbsence, setShowEditAbsence] = useState<boolean>(false);
	const [course, setCourse] = useState<Course>();
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const [bulkAbsenceButtonStatus, setBulkAbsenceButtonStatus] = useState(""); // the additional text displayed on button to edit bulk absences, either a number 12/20, or done

	useEffect(() => {
		LoadSchoolData();
		LoadCourse();
		LoadNumberOfCompletedAbsences();
		setStatusFilterOptions(TeacherStatusFilterOptions);
	}, [courseId]);

	if (courseId === -1) return <div></div>;

	const LoadCourse = () => {
		if (courseId === -1) return;
		CourseService.GetCourse(courseId).then(setCourse);
	};

	const LoadSchoolData = async () => {
		let data = await GetSchoolData();
		setShowCourseDescription(data.showCourseDescriptions || false)
	};

	/** Refreshes the number displayed on the bulk absence edit button */
	const LoadNumberOfCompletedAbsences = () => {
		EvaluationService.GetEvaluationsForCourse(courseId).then((_evaluations) => {
			_evaluations = _evaluations.filter(e => e.student.showAbsence);
			let total = _evaluations.length;
			let completed = _evaluations.filter((e) => _.isFinite(e.attendacePercentage)).length; // how many evaluations have the attendace
			setBulkAbsenceButtonStatus(total === completed ? `(${t("Done")})` : `${completed}/${total}`);
			setShowEditAbsence(_evaluations.length >= 1)
		});
	};

	const OnBulkAbsenceClick = () => {
		ShowGeneralModal({
			title: t("Edit absences"),
			content: (
				<BulkAbsenceEdit
					courseId={courseId}
					onSave={() => {
						CloseGeneralModal();
						LoadNumberOfCompletedAbsences();
					}}
				/>
			),
		});
	};

	const OnCourseDescriptionEditClick = async () => {
		setLoadingEditCourse(true);
		let course = await CourseService.GetCourse(courseId);
		ShowGeneralModal({
			content: <CourseDescriptionModal course={course} onSave={LoadCourse} />,
		});
		setLoadingEditCourse(false);
	};

	return (
		<div style={{ display: "inline-flex", alignItems: "flex-start" }}>
			<div>
				<div style={{ display: "flex", marginBottom: 16 }}>
					<div className="input-with-search">
						<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
						<Icon.Search />
					</div>
					<div style={{ width: 220, marginLeft: 8 }}>
						<Select
							placeholder={t("Filter by status...")}
							value={statusFilterValue}
							onChange={setStatusFilterValue}
							options={statusFilterOptions}
							isClearable={true}
						/>
					</div>
				</div>
				<StudentEvaluationList courseId={courseId} filterName={filterName} filterStatus={statusFilterValue?.value} />
			</div>
			<div style={{ marginLeft: 24, marginTop: 54, width: 320 }}>
				<div className="card" style={{ display: "flex", flexDirection: "column" }}>
					<div className="title">{t("Course actions")}</div>
					{ showCourseDescription && 
						<NormalButton
						text={`${t("Course description")} (${t(`descriptionStatus.${course?.description?.status}`)})`}
						onClick={OnCourseDescriptionEditClick}
						icon={<Icon.Edit />}
						loading={loadingEditCourse}/>
					}
					{showEditAbsence &&
						<NormalButton text={`${t("Edit absences")} ${bulkAbsenceButtonStatus}`} onClick={OnBulkAbsenceClick} icon={<Icon.Users />} style={{ marginTop: 8 }} />
					}
				</div>
				<div className="card" style={{ marginTop: 24, display: "flex", flexDirection: "column" }}>
					<div className="title">{t("Proofreader")}</div>
					{course?.proofreader ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={course.proofreader.name} />
							<div style={{ marginLeft: "8px" }}>
								<div>{course.proofreader.name}</div>
							</div>
						</div>
					) : (
						<div className="info-text">
							{t("There is no proofreader assigned to this course, all the proofreaders can proofread evaluations for this course.")}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
