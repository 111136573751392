import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CloseGeneralModal } from "../../../components/GeneralModal";
import NormalButton from "../../../components/input/NormalButton";
import { UserRoles } from "../../../Constants";
import Subject from "../../../model/Subject";
import Task from "../../../model/Task";
import User from "../../../model/User";
import * as SubjectService from "../../../services/Subject";
import * as SSOService from "../../../services/Task";
import * as UserService from "../../../services/User";
import { useSelector } from 'react-redux';
import { RootState } from "../../../app/store";

interface ManageTaskModalProps {
	ssoId: number;
	onSave: () => any;
}
export default function ManageTaskModal({ ssoId, onSave }: ManageTaskModalProps) {
	const { t } = useTranslation();
	const [title, setTitle] = useState("");
	const [teacherSelectOptions, setTeacherSelectOptions] = useState<{ value: User; label: string }[]>([]); // value => teacher, label => teacher name
	const [teachersSelectedValues, setTeachersSelectedValues] = useState<{ value: User; label: string }[]>([]); // value => teacher, label => teacher name
	const [proofreaderSelectOptions, setProofreaderSelectOptions] = useState<{ value: User; label: string }[]>([]);
	const [proofreaderSelectValue, setProofreaderSelectValue] = useState<{ value: User; label: string } | null>();
	const [subject, setSubject] = useState<{ value: Subject; label: string } | null>();
	const [subjectSelectOptions, setSubjectSelectOptions] = useState<{ value: Subject; label: string }[]>([]);
	const [isSaving, setIsSaving] = useState(false); // toggle loading indicator on save button
	const firstInput = useRef<HTMLInputElement>(null);
	const userRole = useSelector((state: RootState) => state.activeRole);
	const isTeacher = userRole === UserRoles.Teacher ;

	useEffect(() => {
		firstInput.current?.focus();

		//load the teachers options in the dropdown
		UserService.GetAllUsers().then((loadedUsers) => {
			let _teacherSelectOptions: { value: User; label: string }[] = [];
			let _proofreaderSelectOptions: { value: User; label: string }[] = [];
			loadedUsers.forEach((_user) => {
				if (_user.roles.includes(UserRoles.Teacher))
					_teacherSelectOptions.push({
						value: _user,
						label: _user.name,
					});
				if (_user.roles.includes(UserRoles.ProofReader))
					_proofreaderSelectOptions.push({
						value: _user,
						label: _user.name,
					});
			});

			setTeacherSelectOptions(_teacherSelectOptions);
			setProofreaderSelectOptions(_proofreaderSelectOptions);
		});
		SubjectService.GetSubjects().then((loadedSubjects) => {
			let _subjectSelectOptions: { value: Subject; label: string }[] = [];
			loadedSubjects.forEach((_subject) => {
				_subjectSelectOptions.push({
					value: _subject,
					label: _subject.name,
				});
			});
			setSubjectSelectOptions(_subjectSelectOptions);
		});
		SSOService.GetById(ssoId).then((_sso) => {
			setTitle(_sso.title);
			if (_sso.subject)
				setSubject({
					label: _sso.subject.name,
					value: _sso.subject,
				});
			if (_sso.teachers)
				setTeachersSelectedValues(
					_sso.teachers.map((teacher) => ({
						label: teacher.name,
						value: teacher,
					}))
				);
			if (_sso.proofreader)
				setProofreaderSelectValue({
					label: _sso.proofreader?.name,
					value: _sso.proofreader,
				});
		});
	}, []);

	const SaveSSO = async () => {
		setIsSaving(true);
		const _sso: Task = {
			id: ssoId,
			title: title,
			subject: subject?.value,
			teachers: teachersSelectedValues.map((t) => t.value),
			proofreader: proofreaderSelectValue ? proofreaderSelectValue.value : undefined,
		};
		await SSOService.Update(_sso);
		onSave();
		setIsSaving(false);
		CloseGeneralModal();
	};
	let subjectRow = (
		<tr>
			<td>{t("Subject")}</td>
			<td>
				<Select placeholder={t("Select subject...")} options={subjectSelectOptions} value={subject} onChange={setSubject} />
			</td>
		</tr>
	)
	let teacherRow = (
		<tr>
			<td>{t("Teacher(s)")}</td>
			<td style={{ width: 300 }}>
				<Select
					placeholder={t("Select teacher...")}
					isMulti={true}
					options={teacherSelectOptions}
					value={teachersSelectedValues}
					onChange={(v) => {
						if (!v) setTeachersSelectedValues([]);
						else setTeachersSelectedValues([...v]);
					}}
					isClearable={true}
				/>
			</td>
		</tr>
	)
	let proofreaderRow = (
		<tr>
			<td>{t("Proofreader")}</td>
			<td>
				<Select
					placeholder={t("Select proofreader...")}
					options={proofreaderSelectOptions}
					value={proofreaderSelectValue}
					onChange={(v) => setProofreaderSelectValue(v)}
					isClearable={true}
				/>
			</td>
		</tr>
	)
	return (
		<div>
			<table className="table-with-inputs vertically-centered-labels">
				<tbody>
					<tr>
						<td>{t("Title")}</td>
						<td>
							<input ref={firstInput} type="text" placeholder={t("Title")} value={title} onChange={(v) => setTitle(v.currentTarget.value)} />
						</td>
					</tr>
					{!isTeacher && subjectRow}
					{!isTeacher && teacherRow}
					{!isTeacher && proofreaderRow}
				</tbody>
			</table>
			<div style={{ textAlign: "right" }}>
				<NormalButton onClick={SaveSSO} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
			</div>
		</div>
	);
}
