/**
 * When the user is NOT authneticated this page is displayed
 */
import { useState } from "react";
import axios, { AxiosError } from "axios";
import OAuth2Credentials from "../model/OAuth2Credentials";
import Logo from "../resources/img/logo-16.svg";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";
import { apiUrl } from "../Constants";

export default function WelcomePage() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const { t } = useTranslation();

	const logIn = () => {
		axios
			.create()
			.post(`${apiUrl}/rsvidnesbyrd/auth/login`, {
				username: username,
				password: password,
			})
			.then((result) => {
				let credentials: OAuth2Credentials = result.data;
				window.localStorage.setItem("credentials", JSON.stringify(credentials));
				window.location.reload(); //reload, and the dashboard will be rendered
			})
			.catch((error: AxiosError) => {
				console.log("Log in error", error.response?.status);
				if (error.response?.data["error"] === "invalid_grant") {
					setError(t("Wrong username or password"));
				} else {
					setError(t("Something went wrong, if the error occurs again please contact us."));
				}
			});
	};

	const handleKeywordKeyPress = (e: any) => {
		if (e.key === "Enter") {
			logIn();
		}
	};

	return (
		<div>
			<div style={{ position: "fixed", top: -200, bottom: 0, left: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<img src={Logo} style={{ width: 250 }} alt="Logo" />
					<div style={{ textAlign: "center", color: "black", fontSize: "1.5rem" }}>{t("Log in")}</div>
					<br />
					<div style={{ marginTop: 12 }}>
						<b>{t("Username")}</b>
						<input
							placeholder={t("Username")}
							type="text"
							onKeyPress={handleKeywordKeyPress}
							value={username}
							onInput={(e) => setUsername(e.currentTarget.value)}
							style={{ width: 250 }}
						/>
					</div>
					<div style={{ marginTop: 12 }}>
						<b>{t("Password")}</b>
						<input
							placeholder={t("Password")}
							type="password"
							onKeyPress={handleKeywordKeyPress}
							value={password}
							onInput={(e) => setPassword(e.currentTarget.value)}
							style={{ width: 250 }}
						/>
					</div>
					<button className="btn blue block" onClick={logIn} style={{ width: 250, marginTop: 12 }}>
						<Icon.LogIn />
						{t("Log in")}
					</button>
					{error && (
						<div className="error-banner" style={{ marginTop: 12 }}>
							{" "}
							{error}{" "}
						</div>
					)}
				</div>
			</div>
			<div
				style={{
					position: "fixed",
					bottom: 0,
					left: 0,
					right: 0,
					lineHeight: 2,
					textAlign: "center",
					backgroundColor: "#f2f2f2",
					borderTop: "1px solid #dadce0",
					fontSize: "0.95rem",
				}}
			>
				{t("Created by Greenbyte. Contact us at +45 61 30 48 58 or e-mailinfo@greenbyte.dk")}
			</div>
		</div>
	);
}
