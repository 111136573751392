import Axios from "./CustomAxios";
import Evaluation from "../model/Evaluation";

/**
 * Get evaluations displayed for a proofreader.
 * The API filters and returns absences that aren't asigned to any proofreader, and the absences that are assigned specifically to this proofreader.
 */
export const GetEvaluationsForProofreader = async (): Promise<Evaluation[]> => {
	return (await Axios.get(`/rsvidnesbyrd/evaluation/forProofreader`)).data;
};

/**
 * Get all evaluations for a course, used for teacher when the list of evaluations (students) is displayed in a course.
 */
export const GetEvaluationsForCourse = async (courseId: number): Promise<Evaluation[]> => {
	return (await Axios.get(`/rsvidnesbyrd/evaluation/forCourse/${courseId}`)).data;
};

export const GetEvaluation = async (evaluationId: number): Promise<Evaluation> => {
	return (await Axios.get(`/rsvidnesbyrd/evaluation/${evaluationId}`)).data;
};

export const UpdateEvaluation = async (evaluation: Evaluation) => {
	return (await Axios.put(`/rsvidnesbyrd/evaluation/update`, evaluation)).data;
};

/**
 * Bulk update a list of evaluations.
 * For now this updates only student attendance, and is used in the bulk update attendance modal.
 */
export const BulkUpdate = async (evaluations: Evaluation[]) => {
	return (await Axios.put(`/rsvidnesbyrd/evaluation/bulkUpdate`, evaluations)).data;
};
