import React, { useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import NormalButton from "../../components/input/NormalButton";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import * as ImportService from "../../services/Import";

export default function ImportPage() {
	const [importStarted, setImportStarted] = useState(false);
	const [importFinished, setImportFinished] = useState(false);
	const [importFileName, setImportFileName] = useState("");
	const [importedData, setImportedData] = useState<ImportService.ImportStudentResponse[]>([]);
	const [importError, setImportError] = useState("");
	const [isImporting, setIsImporting] = useState(false);

	const { t } = useTranslation();
	const hiddenFileInput = React.useRef(null);

	//started to work here on soem vizualization before uploading the file, but it is not so important right now.
	// const handleFileUpload = async (file: File) => {
	// 	try {
	// 		let fileContents = (await readUploadedFileAsText(file)) as string;
	// 		let lines = fileContents.split("\n");
	// 		lines.splice(0, 1); //remove 1st line, contains some data about page (maybe it shouldn't be removed later)
	// 		let headers = lines[0].split(";");
	// 		//TODO finish data importing
	// 	} catch (e) {
	// 		console.error(e.message);
	// 	}
	// };

	const handleFileUpload = async (file: File) => {
		setImportStarted(true);
		setImportFinished(false);
		setImportError("");
		setImportFileName(file.name);
		setImportedData([]);
		setIsImporting(true);
		let csvFileContents: string = "";
		try {
			csvFileContents = (await ImportService.ReadUploadedFileAsText(file)) as string;
		} catch (e) {
			setIsImporting(false);
			setImportError(t("Failed to read the csv file"));
			console.error("Failed to read csv file contents", e.message);
			return;
		}
		try {
			let response = await ImportService.ImportFromCSV(csvFileContents);
			setImportFinished(true);
			setImportedData(response);
		} catch (e) {
			if (e.response.data.code === "active_classes_exist") {
				setImportError(t("There are active classes import error."));
			} else if (e.response.data.code === "column_not_found") {
				setImportError(t('Column from which "{data}" is extracted could not be found.').replace("{data}", e.response.data.columnName));
			} else if (e.response.data.code === "cell_parse_exception") {
				setImportError(t('Failed to parse "{data}" column.').replace("{data}", e.response.data.columnName));
			} else {
				setImportError(t("Unexpected import error happened, if it happens again please contact us."));
				console.log("Import failed", e);
			}
		}
		setIsImporting(false);
	};

	//when the import button is clicked the file input will be triggered
	const handleClick = () => {
		let refAny = hiddenFileInput as any;
		refAny.current.click();
	};

	return (
		<div>
			<input
				ref={hiddenFileInput}
				type="file"
				accept=".csv"
				style={{ display: "none" }} //hidden, there is a button the trigger the file input. This is done so it can be customized
				onChange={(e) => {
					if (e.currentTarget.files && e.currentTarget.files.length === 1) {
						handleFileUpload(e.currentTarget.files[0]);
						e.currentTarget.value = ""; //clear the input so new files can be imported without refreshing the page
					}
				}}
			/>
			{/* import button, will be hidden if data was successfuly imported */}
			{!importFinished && (
				<div style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
					<NormalButton icon={<Icon.Upload />} text={t("Upload")} onClick={handleClick} loading={isImporting} />
					&nbsp; {t("Select the .csv file with data to import")}
				</div>
			)}
			{/* info to delete all classes, visible only before starting the import */}
			{!importStarted && (
				<div className="info-banner">
					{t(
						"Before importing the new data, please be sure to close or delete all the active classes, because new classes will be created and conflicts may appear."
					)}
				</div>
			)}
			{/* a small banner to inform the user that the import has started */}
			{importStarted && (
				<div className="info-banner">
					{t("Started to import data from file")} {importFileName}
				</div>
			)}
			{/* inform the user that the import was completed successfully */}
			{importFinished && <div className="info-banner">{t("Import finished! See the imported data below.")}</div>}
			{importError && <div className="error-banner short">{importError}</div>}
			{importFinished && importedData.length === 0 && <div className="info-banner">{t("Looks like nothing was imported...")}</div>}
			{importFinished && importedData.length > 0 && (
				<table className="table">
					<thead>
						<tr>
							{/* <th>#</th> */}
							<th>{t("Name")}</th>
							<th>{t("ID")}</th>
							<th>{t("Birthdate")}</th>
							<th>{t("Start date")}</th>
							<th>{t("Class")}</th>
							<th>{t("Action")}</th>
						</tr>
					</thead>
					<tbody>
						{importedData.map((o, index) => (
							<tr key={o.externalId}>
								{/* <td>{index + 1}.</td> */}
								<td>
									<div style={{ display: "flex", alignItems: "center" }}>
										<ProfileColoredCircle username={o.name} size="24px" />
										<div style={{ marginLeft: 6 }}>{o.name}</div>
									</div>
								</td>
								<td>{o.externalId}</td>
								<td>{o.birthDate}</td>
								<td>{o.startDate}</td>
								<td>{o.className}</td>
								<td>
									{o.action === "skip" && <div>{t("Skipped")}</div>}
									{o.action === "create" && <div>{t("Created")}</div>}
									{o.action === "update" && <div>{t("Updated")}</div>}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
