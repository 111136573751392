import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CloseGeneralModal, ShowGeneralModal } from "../../../components/GeneralModal";
import NormalButton from "../../../components/input/NormalButton";
import { ClassLevelSelectOptions, ManageModalAction, UserRoles } from "../../../Constants";
import i18n from "../../../i18n";
import SchoolClass from "../../../model/SchoolClass";
import User from "../../../model/User";
import * as SchoolClassService from "../../../services/SchoolClass";
import * as UserService from "../../../services/User";

type ManageSchoolClassModalProps = { action: ManageModalAction; schoolClassId: number; onComplete: Function };

/**
 * Show manage school class modal.
 * @param action ManageModalAction.Edit or ManageModalAction.Create.
 * @param subjectId id of the school class to edit. Required only when editing a class.
 * @param onComplete callback when user saves, creates or deletes a school class.
 */
export function ShowManageClassModal(action: ManageModalAction, schoolClassId: number, onComplete: Function) {
	ShowGeneralModal({
		title: action === ManageModalAction.Edit ? i18n.t("Edit class") : i18n.t("Create class"),
		content: <ManageClassModal action={action} schoolClassId={schoolClassId} onComplete={onComplete} />,
	});
}

function ManageClassModal({ action, schoolClassId, onComplete }: ManageSchoolClassModalProps) {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [classLevel, setClassLevel] = useState<{ value: number; label: string } | null>();
	const [classLetter, setClassLetter] = useState("");
	const [isSaving, setIsSaving] = useState(false); // shows/hides loading indicator on save button
	const [teacherSelectOptions, setTeacherSelectOptions] = useState<{ label: string; value: User }[]>([]); // label => teacher name
	const [selectedTeachers, setSelectedTeachers] = useState<{ label: string; value: User }[]>([]); // label => teacher name
	const [error, setError] = useState("");
	const firstInput = useRef<HTMLInputElement>(null);
	const [showAbsence, setShowAbsence] = useState(true);

	useEffect(() => {
		firstInput.current?.focus();

		if (action === ManageModalAction.Edit)
			SchoolClassService.GetSchoolClass(schoolClassId).then((schoolClass) => {
				setClassLevel(ClassLevelSelectOptions.filter((o) => o.value === schoolClass.level)[0]);
				setClassLetter(schoolClass.letter);
				setSelectedTeachers(
					schoolClass.teachers.map((user) => ({
						value: user,
						label: user.name,
					}))
				);
				setShowAbsence(schoolClass.showAbsence);
			});

		// populate the teacher filter
		UserService.GetAllUsers().then((loadedUsers) => {
			setTeacherSelectOptions(
				loadedUsers
					.filter((u) => u.roles.includes(UserRoles.Teacher))
					.map((_user) => ({
						value: _user,
						label: _user.name,
					}))
			);
		});
	}, []);

	/** Returns a SchoolClass with data from the popup fields. */
	const CreateClassObjectFromFields = (): SchoolClass => {
		return {
			id: action === ManageModalAction.Edit ? schoolClassId : -1, //if we are creating the class the id field doesn't matter, it will be set to null on server
			level: classLevel?.value,
			letter: classLetter,
			teachers: selectedTeachers?.map((t) => t.value),
			showAbsence: showAbsence,
		};
	};

	const CreateClass = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let schoolClass = CreateClassObjectFromFields();
		let result;
		try {
			result = await SchoolClassService.CreateSchoolClass(schoolClass);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseGeneralModal();
		} catch (e) {
			let response = e.response.data;
			console.log("School class create error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const EditClass = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let schoolClass = CreateClassObjectFromFields();
		let result;
		try {
			result = await SchoolClassService.UpdateSchoolClass(schoolClass);
			console.log(result);
			onComplete();
			setShowAbsence(schoolClass.showAbsence)
			setIsSaving(false);
			CloseGeneralModal();
		} catch (e) {
			let response = e.response.data;
			console.log("School class update error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const ParseError = (response: { message: string; code: string }) => {
		switch (response.code) {
			case "missing_level":
				setError(t("Please enter the class level!"));
				break;
			case "missing_teacher":
				setError(t("Please select a class teacher!"));
				break;
			default:
				setError(response.message);
		}
	};

	return (
		<div style={{ maxWidth: 500 }}>
			<div>
				<table className="table-with-inputs vertically-centered-labels">
					<tbody>
						<tr>
							<td>{t("Level")}</td>
							<td>
								<Select placeholder={t("Select level...")} options={ClassLevelSelectOptions} value={classLevel} onChange={(v) => setClassLevel(v)} />
							</td>
						</tr>
						<tr>
							<td>{t("Letter")}</td>
							<td>
								<input ref={firstInput} type="text" value={classLetter} onChange={(e) => setClassLetter(e.currentTarget.value)} />
							</td>
						</tr>
						<tr>
							<td>{t("Classleader(s)")}</td>
							<td>
								<Select
									placeholder={t("Select teacher(s)...")}
									options={teacherSelectOptions}
									value={selectedTeachers}
									onChange={(v) => {
										if (!v) setSelectedTeachers([]);
										else setSelectedTeachers([...v]);
									}}
									isClearable={true}
									isMulti={true}
								/>
							</td>
						</tr>
						<tr>
							<td>{t("Show absence")}</td>
							<td style={{ display: "flex", alignItems: "center" }}>
								<input
									type="checkbox"
									checked={showAbsence}
									style={{ margin: 0 }}
									onChange={(e) => {
										setShowAbsence(e.currentTarget.checked);
									}}
								/>
							</td>
						</tr>


					</tbody>
				</table>
			</div>

			{error.length > 0 && <div className="error-banner">{error}</div>}

			<div style={{ textAlign: "right" }}>
				{action === ManageModalAction.Edit ? (
					<NormalButton onClick={EditClass} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
				) : (
					<NormalButton onClick={CreateClass} loading={isSaving} icon={<Icon.Save />} text={t("Create")} />
				)}
			</div>
		</div>
	);
}
