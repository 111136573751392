import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NormalButton from "../../../components/input/NormalButton";
import Year from "../../../model/Year";
import * as YearService from "../../../services/Year";
import * as Icon from "react-feather";
import { ShowConfirmationPopup } from "../../../components/ConfirmationModal";
import TextButton from "../../../components/input/TextButton";
import ShowSwitchYearModal from "./SwitchYearModal";
import CurrentSchoolYear from "./CurrentSchoolYear";

type CurrentSchoolYearProps = { LoadCurrentYear: () => any; currentSchoolYear: Year | undefined };
export default function ManageYear({ LoadCurrentYear, currentSchoolYear }: CurrentSchoolYearProps) {
	const { t } = useTranslation();

	const OnAdvanceYearClick = () => {
		ShowConfirmationPopup({
			message: t("Are you sure you want to advance the school year?"),
			confirmButtonText: t("Advance"),
			onConfirm: AdvanceYear,
			buttonColor: "blue",
		});
	};

	const AdvanceYear = () => {
		YearService.AdvanceYear().then(() => {
			LoadCurrentYear();
		});
	};

	const OnSwitchYearClick = () => {
		ShowSwitchYearModal(LoadCurrentYear);
	};

	return (
		<div>
			<div className="card">
				<div style={{ fontSize: "1.5rem" }}>
					<CurrentSchoolYear LoadCurrentYear={LoadCurrentYear} currentSchoolYear={currentSchoolYear} />
				</div>
				<div style={{ marginTop: 16 }}>{t("Advance year explanation")}</div>
				<div style={{ marginTop: 16, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<NormalButton text={t("Advance")} onClick={OnAdvanceYearClick} icon={<Icon.ArrowRightCircle />} />
					<TextButton text={t("Switch year")} onClick={OnSwitchYearClick} />
				</div>
			</div>
		</div>
	);
}
