import React from "react";
import LoadingIndicator from "../LoadingIndicator";

type TextButtonProps = {
	text: string;
	onClick: () => any;
	icon?: React.ReactNode;
	color?: string;
	loading?: boolean;
	outline?: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	tooltip?: string;
	iconAtEnd?: boolean;
	disabledTooltip?: string;
};

/**
 * @param loading if true, the icon will be hidden (if there is any icon) and a spinning indicator appears
 * @param color if not set is "blue". Available colors: red, green, transparent
 */
export default function NormalButton({ text, onClick, icon, color, loading, outline, disabled, style, tooltip, iconAtEnd, disabledTooltip }: TextButtonProps) {
	let _tooltip: string | undefined;
	if (!disabled) _tooltip = tooltip;
	if (disabled) _tooltip = disabledTooltip;

	return (
		<button
			className={`btn ${color ? color : "blue"} ${outline ? "outline" : ""} ${disabled ? "disabled" : ""} ${iconAtEnd ? "icon-at-end" : ""}`}
			onClick={() => {
				if (!disabled) onClick();
			}}
			style={style ? style : {}}
			title={_tooltip}
		>
			{loading && <LoadingIndicator />}
			{!loading && !iconAtEnd && icon}
			{text}
			{!loading && iconAtEnd && icon}
		</button>
	);
}
