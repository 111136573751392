/**
 * Modal functionality.
 * All the modals on the website contain only the content of the modal.
 * Call ShowGeneralModal to show the modal.
 * ShowGeneralModal can show stacked modals.
 */
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import { Provider } from "react-redux";
import store from "../app/store";


const GeneralModalContainer = document.querySelector("#generalModalContainer");
type GeneralModalProps = { content: React.ReactNode; title?: string; contentStyle?: React.CSSProperties };

/**
 * Show general modal. Support showing multiple modals, above of each other (stacked modals).
 * Works by adding new child elements (containers) to #generalModalContainer and rendering the content of the modal inside them.
 * Also hides the existing modals, so they don't stay in the background.
 */
export function ShowGeneralModal({ content, title, contentStyle }: GeneralModalProps) {
	if (GeneralModalContainer && GeneralModalContainer.lastElementChild) {
		//hide the last modal in the stack, if there is one
		(GeneralModalContainer.lastElementChild as HTMLElement).style["display"] = "none";
	}
	let container = document.createElement("div");
	GeneralModalContainer?.appendChild(container);
	ReactDOM.render( <Provider store={store}><GeneralModal content={content} title={title} contentStyle={contentStyle} /></Provider>, container);
}

/**
 * Closes the last modal in the stack of modals.
 * Works by unmounting the modal in the last child of the #generalModalContainer, and then also removing the last child.
 */
export function CloseGeneralModal() {
	if (GeneralModalContainer && GeneralModalContainer.lastElementChild) {
		let lastChild = GeneralModalContainer.lastElementChild;
		ReactDOM.unmountComponentAtNode(lastChild);
		lastChild.parentElement?.removeChild(lastChild);
		if (GeneralModalContainer.lastElementChild) {
			//show the last modal in the stack, if there is one
			(GeneralModalContainer.lastElementChild as HTMLElement).style["display"] = "block";
		}
	}
}

//this is an attempt to have callbacks when the modal is closed by pressing X (see CourseDescriptionModal.tsx)
//it's not eprfect because it doesn't support stacked modals.
let GeneralModalCloseByPressingXCallback: () => any = () => {};
export const SetGeneralModalCloseByPressingXCallback = (callback: () => any) => {
	GeneralModalCloseByPressingXCallback = callback;
};

function GeneralModal({ content, title, contentStyle }: GeneralModalProps) {
	return (
		<div>
			<div className="modal">
				<div className="content" style={contentStyle}>
					<div
						className="close-button"
						onClick={() => {
							GeneralModalCloseByPressingXCallback();
							GeneralModalCloseByPressingXCallback = () => {};
							CloseGeneralModal();
						}}
					>
						<Icon.X />
					</div>
					{title && <div className="title">{title}</div>}
					{content}
				</div>
			</div>
		</div>
	);
}
