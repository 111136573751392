import { useEffect, useState } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import YearTask from "../../model/YearTask";
import * as YearTaskService from "../../services/YearTask";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { DescriptionStatus, TeacherStatusFilterOptions } from "../../Constants";
import Select from "react-select";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import { ShowYearTaskStudentDescriptionModal } from "../common/YearTaskStudentDescriptionModal";
import { ShowGeneralModal } from "../../components/GeneralModal";
import TextButton from "../../components/input/TextButton";
import ManageYearTaskModal from "../admin/yearTask/ManageYearTaskModal";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

/**
 * On this page the teacher can view all the year tasks to which he is assigned, and edit the student descriptions.
 * By default, all of them will be in "Done" status because they use the default student description from school.
 */
export default function YearTasks() {
	const { t } = useTranslation();
	const [yearTasks, setYearTasks] = useState<YearTask[]>([]);
	const [filterName, setFilterName] = useState(""); // used to filter by the student name from the text input
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator in table header
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const userRole = useSelector((state: RootState) => state.activeRole);
	useEffect(() => {
		LoadYearTasks();
		setStatusFilterOptions(TeacherStatusFilterOptions);
	}, []);

	const EditYearTask = (yearTask: YearTask) => {
		ShowGeneralModal({
			title: t("Edit year task"),
			content: <ManageYearTaskModal yearTaskId={yearTask.id} onSave={LoadYearTasks}/>,
		});
	};
	
	const LoadYearTasks = async () => {
		setIsLoading(true);
		let _yearTasks = await YearTaskService.GetAllAvailableYearTasks(userRole);
		setYearTasks(_yearTasks);
		setIsLoading(false);
	};

	// sets loading for a year task, so the loading indicator appears in the table row near the edit button
	const SetYearTaskLoading = (yearTaskId: number, loading: boolean) => {
		let _tmpEvaluations = [...yearTasks];
		_tmpEvaluations.filter((e) => e.id === yearTaskId)[0].loading = loading;
		setYearTasks(_tmpEvaluations);
	};

	const ShowYearTaskStudentDescriptionProofreadModal = async (yearTask: YearTask) => {
		SetYearTaskLoading(yearTask.id, true);
		ShowYearTaskStudentDescriptionModal(yearTask.id, LoadYearTasks, () => SetYearTaskLoading(yearTask.id, false));
	};

	let YearTaskHTMLList = yearTasks
		.filter((yearTask) => {
			// filter by student name
			if (!filterName) return true;
			return yearTask.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((yearTask) => {
			// filter by description status
			if (!statusFilterValue) return true;
			return yearTask.studentDescription?.status === statusFilterValue.value;
		})
		.map((yearTask) => {
			return (
				<tr key={yearTask.id} >
					<td onClick={() => ShowYearTaskStudentDescriptionProofreadModal(yearTask)}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={yearTask.student?.name || ""} />
							<div style={{ marginLeft: 8 }}>
								<div>{yearTask.student?.name || ""}</div>
							</div>
						</div>
					</td>
					<td style={{ position: "relative" }} onClick={() => ShowYearTaskStudentDescriptionProofreadModal(yearTask)}>
						<DescriptionStatusBadge status={yearTask.studentDescription?.status || DescriptionStatus.Created} />
						{yearTask.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
					<td>
						<TextButton icon={<Icon.Edit />} text={t("Edit title")} onClick={() => EditYearTask(yearTask)} />
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: "16px", display: "flex" }}>
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>

				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Status")}</th>
						<th>{t("Actions")}</th>
					</tr>
				</thead>
				<tbody>{YearTaskHTMLList}</tbody>
			</table>
			{YearTaskHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
