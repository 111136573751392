import ReactDOM from "react-dom";
import * as Icon from "react-feather";

const ErrorPopupContainer = document.querySelector("#errorPopup");

export const ShowErrorPopup = (text: string) => {
	if (ErrorPopupContainer) ReactDOM.unmountComponentAtNode(ErrorPopupContainer);
	ReactDOM.render(<ErrorPopup text={text} />, ErrorPopupContainer);
};

type ErrorPopupProps = { text: string };
function ErrorPopup({ text }: ErrorPopupProps) {
	return (
		<div className="error-popup">
			<Icon.AlertCircle />
			{text}
		</div>
	);
}
