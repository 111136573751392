import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseGeneralModal, ShowGeneralModal } from "../../../components/GeneralModal";
import TextButton from "../../../components/input/TextButton";
import i18n from "../../../i18n";
import Year from "../../../model/Year";
import * as YearService from "../../../services/Year";

export default function ShowSwitchYearModal(onYearSwitch: () => any) {
	ShowGeneralModal({
		title: i18n.t("Switch year"),
		content: <SwitchYearModal onYearSwitch={onYearSwitch} />,
		contentStyle: {
			width: 350,
		},
	});
}

type SwitchYearModalProps = { onYearSwitch: () => any };
function SwitchYearModal({ onYearSwitch }: SwitchYearModalProps) {
	const { t } = useTranslation();
	const [years, setYears] = useState<Year[]>([]);

	useEffect(() => {
		YearService.GetAllYears().then(setYears);
	}, []);

	const SelectYear = async (year: Year) => {
		await YearService.SetCurrentYear(year);
		onYearSwitch();
		CloseGeneralModal();
	};

	return (
		<div>
			<table className="table scrollable">
				<thead>
					<tr>
						<th>{t("Year")}</th>
						<th>{t("Select")}</th>
					</tr>
				</thead>
				<tbody>
					{years.map((year) => (
						<tr key={year.id} style={{ fontWeight: year.currentYear ? "bold" : "normal" }}>
							<td>
								{year.year}-{year.year + 1}
							</td>
							<td>
								<TextButton
									text={t("Select")}
									onClick={() => {
										SelectYear(year);
									}}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="info-text" style={{ marginTop: 12 }}>
				{t("Switch year info")}
			</div>
		</div>
	);
}
