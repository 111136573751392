import React, { useEffect, useState } from "react";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import { useTranslation } from "react-i18next";
import * as EvaluationService from "../../services/Evaluation";
import Evaluation from "../../model/Evaluation";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ShowStudentDescriptionModal } from "../common/StudentDescriptionModal";
import { DescriptionStatus } from "../../Constants";

type StudentEvaluationListProps = { courseId: number; filterName: string; filterStatus: DescriptionStatus | null | undefined };

/**
 * Displays student evaluations for the teacher in a table.
 */
export default function StudentEvaluationList({ courseId, filterName, filterStatus }: StudentEvaluationListProps) {
	const { t } = useTranslation();
	const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator

	useEffect(() => {
		LoadEvaluations();
	}, [courseId]);

	const LoadEvaluations = () => {
		setIsLoading(true);
		EvaluationService.GetEvaluationsForCourse(courseId).then((_evaluations) => {
			setEvaluations(_evaluations);
			setIsLoading(false);
		});
	};

	const ShowEvaluationModal = (evaluation: Evaluation) => {
		SetEvaluationLoading(evaluation.id, true);
		ShowStudentDescriptionModal(evaluation.id, LoadEvaluations, () => SetEvaluationLoading(evaluation.id, false));
	};

	const SetEvaluationLoading = (evaluationId: number, loading: boolean) => {
		let _tmpEvaluations = [...evaluations];
		_tmpEvaluations.filter((e) => e.id === evaluationId)[0].loading = loading;
		setEvaluations(_tmpEvaluations);
	};

	const EvaluationHTMlList = evaluations
		.filter((evaluation) => {
			// filter by student name
			if (!filterName) return true;
			return evaluation.student.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((evaluation) => {
			// filter by dscription status
			if (!filterStatus) return true;
			return evaluation.description?.status === filterStatus;
		})
		.map((evaluation) => {
			return (
				<tr key={evaluation.id} onClick={() => ShowEvaluationModal(evaluation)}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={evaluation.student.name} />
							<div style={{ marginLeft: "8px" }}>
								<div>{evaluation.student.name}</div>
							</div>
						</div>
					</td>
					<td style={{ position: "relative" }}>
						<DescriptionStatusBadge status={evaluation.description.status} />
						{evaluation.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
				</tr>
			);
		});

	return (
		<div style={{ minWidth: 600 }}>
			<table className="table interactible">
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")}
							{isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th style={{ width: 160 }}>{t("Proofread status")}</th>
					</tr>
				</thead>
				<tbody>{EvaluationHTMlList}</tbody>
			</table>
		</div>
	);
}
