import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import NormalButton from "./input/NormalButton";
import i18n from "../i18n";

/**
 * Show a confirmation modal. Has to buttons, to confirm an action and cancel it.
 */
const ConfirmationModalContainer = document.querySelector("#confirmationModalContainer");
type ConfirmationModalProps = { message: React.ReactNode; confirmButtonText: string; buttonColor: string; onConfirm: () => any };
export function ShowConfirmationPopup({ message, confirmButtonText, buttonColor, onConfirm }: ConfirmationModalProps) {
	ReactDOM.render(
		<div>
			<div className="modal">
				<div className="content" style={{ maxWidth: 600, padding: 16 }}>
					<div style={{ fontSize: "1.1rem", marginBottom: 16, textAlign: "center" }}>{message}</div>
					<div style={{ textAlign: "right" }}>
						<NormalButton color={buttonColor} outline={true} text={i18n.t("Cancel")} onClick={CloseConfirmationPopup} />
						&nbsp;&nbsp;&nbsp;
						<NormalButton
							text={confirmButtonText}
							color={buttonColor}
							onClick={() => {
								CloseConfirmationPopup();
								onConfirm();
							}}
						/>
					</div>
				</div>
			</div>
		</div>,
		ConfirmationModalContainer
	);
}

function CloseConfirmationPopup() {
	if (ConfirmationModalContainer) ReactDOM.unmountComponentAtNode(ConfirmationModalContainer);
}

/**
 * Wrapper for the ShowConfirmationPopup to show a delete popup.
 * @param name The name of the entity to delete, this may be the user name, the class name, subject name, etc. The modal shows a text "Please confirm that you want to delete" + name of the entity to delete.
 * @param onDeleteClick the action to perform when the user clicks delete.
 */
export function ShowDeleteModal(name: string, onDeleteClick: () => any, additionalText?: string) {
	ShowConfirmationPopup({
		message: (
			<div>
				<div>
					{i18n.t("Please confirm that you want to delete")} <b>{name}</b>?
				</div>
				{additionalText && <div>{additionalText}</div>}
			</div>
		),
		confirmButtonText: i18n.t("Delete"),
		onConfirm: onDeleteClick,
		buttonColor: "red",
	});
}
