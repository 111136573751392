import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import Select from "react-select";
import { useEffect, useState } from "react";
import * as YearTaskService from "../../services/YearTask";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import * as Icon from "react-feather";
import LoadingIndicator from "../../components/LoadingIndicator";
import { DescriptionStatus, ProofreaderStatusFilterOptions } from "../../Constants";
import YearTask from "../../model/YearTask";
import { ShowYearTaskStudentDescriptionModal } from "../common/YearTaskStudentDescriptionModal";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

/**
 * Displays year tasks student descriptions for the proofreader in a table.
 * By default this page will be empty, because all year tasks will take the default student description from school and be in "Done" status.
 */
export default function ProofReadYearTaskStudentDescription() {
	const { t } = useTranslation();
	const [yearTasks, setYearTasks] = useState<YearTask[]>([]);
	const [teacherFilterOptions, setTeacherFilterOptions] = useState<{ label: string; value: string }[]>([]); // label => name of the teacher, value => id of the teacher
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: string } | null>(); // label => name of the teacher, value => id of the teacher
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const [filterName, setFilterName] = useState(""); // used to filter by the student name from the text input
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator in table header
	const userRole = useSelector((state: RootState) => state.activeRole);
	useEffect(() => {
		LoadYearTasks();
		setStatusFilterOptions(ProofreaderStatusFilterOptions);
	}, []);

	const LoadYearTasks = () => {
		setIsLoading(true);
		YearTaskService.GetAllAvailableYearTasks(userRole).then((_yearTasks) => {
			setYearTasks(_yearTasks);

			// build filter options based on values from the evaluations (ex the course and the teacher)
			let _teacherFilterOptions: { label: string; value: string }[] = [];
			_yearTasks.forEach((_evaluation) => {
				_evaluation.teachers?.forEach((t) => {
					_teacherFilterOptions.push({
						label: t.name,
						value: t.id,
					});
				});
			});

			// remove duplicate values from the filter options
			setTeacherFilterOptions(_.uniqBy(_teacherFilterOptions, "value"));

			setIsLoading(false);
		});
	};

	const ShowYearTaskStudentDescriptionProofreadModal = async (yearTask: YearTask) => {
		SetYearTaskLoading(yearTask.id, true);
		ShowYearTaskStudentDescriptionModal(yearTask.id, LoadYearTasks, () => SetYearTaskLoading(yearTask.id, false));
	};

	// sets loading for a year task, so the loading indicator appears in the table row near the edit button
	const SetYearTaskLoading = (yearTaskId: number, loading: boolean) => {
		let _tmpEvaluations = [...yearTasks];
		_tmpEvaluations.filter((e) => e.id === yearTaskId)[0].loading = loading;
		setYearTasks(_tmpEvaluations);
	};

	let YearTaskHTMLList = yearTasks
		.filter((yearTask) => {
			// filter by student name
			if (!filterName) return true;
			return yearTask.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((evaluation) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!evaluation.teachers) return true;
			return evaluation.teachers.map((t) => t.id).includes(teacherFilterValue.value);
		})
		.filter((yearTask) => {
			// filter by description status
			if (!statusFilterValue) return true;
			return yearTask.studentDescription?.status === statusFilterValue.value;
		})
		.map((yearTask) => {
			return (
				<tr key={yearTask.id} onClick={() => ShowYearTaskStudentDescriptionProofreadModal(yearTask)}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={yearTask.student?.name || ""} />
							<div style={{ marginLeft: 8 }}>
								<div>{yearTask.student?.name || ""}</div>
							</div>
						</div>
					</td>
					<td>{yearTask.teachers?.map((t) => t.name).join(", ")}</td>
					<td style={{ position: "relative" }}>
						<DescriptionStatusBadge status={yearTask.studentDescription?.status || DescriptionStatus.Created} />
						{yearTask.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: "16px", display: "flex" }}>
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by teacher...")}
						value={teacherFilterValue}
						options={teacherFilterOptions}
						onChange={setTeacherFilterValue}
						isClearable={true}
					/>
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Teacher")}</th>
						<th>{t("Status")}</th>
					</tr>
				</thead>
				<tbody>{YearTaskHTMLList}</tbody>
			</table>
			{YearTaskHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
