import { ClassLevelSelectOptions } from "../Constants";
import Course from "./Course";
import Student from "./Student";
import User from "./User";

export default interface SchoolClass {
	id: number;
	level?: number;
	studentsNumber?: number;
	students?: Student[];
	courses?: Course[];
	letter: string;
	teachers: User[];
	showAbsence: boolean;

	// used on client
	loading?: boolean;
}

export const GetClassName = (schoolClass: SchoolClass): string => {
	const level = ClassLevelSelectOptions.filter((o) => o.value === schoolClass.level)[0].label;
	const levelAndLeter = `${level} ${schoolClass.letter}`;
	return levelAndLeter;
};
