import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowDeleteModal } from "../../../components/ConfirmationModal";
import IconButton from "../../../components/input/IconButton";
import NormalButton from "../../../components/input/NormalButton";
import TextButton from "../../../components/input/TextButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import { Size, UserRoles, UserRolesSelectOptions } from "../../../Constants";
import User from "../../../model/User";
import * as UserService from "../../../services/User";
import { ShowCreateUserModal, ShowEditUserModal } from "./ManageUserModal";

/**
 * Displays the list of users in a table for the admin.
 */
export default function ManageUsersPage() {
	const [users, setUsers] = useState<User[]>([]);
	const [roleFilter, setRoleFilter] = useState<{ value: UserRoles; label: string } | undefined | null>(undefined);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		LoadUsers();
	}, []);

	const LoadUsers = () => {
		setLoadingUsers(true);
		UserService.GetAllUsers()
			.then((users) => {
				setUsers(users);
				setLoadingUsers(false);
			})
			.catch((error) => {
				console.log("Failed to load users on users pages", error);
			});
	};

	const EditUser = (userId: string) => {
		ShowEditUserModal(userId, LoadUsers);
	};

	const CreateUser = () => {
		ShowCreateUserModal(LoadUsers);
	};

	const DeleteUser = (user: User) => {
		if (window.location.href.includes("http://localhost:3000")) {
			// eslint-disable-next-line no-restricted-globals
			var result = confirm(
				"You are running the website locally. If you delete this user, it will also be removed from keycloack. If the user is used on the live website also, it will not be available anymore. Are you sure you want to continue it?"
			);
			if (!result) return;
		}

		ShowDeleteModal(
			user.name,
			() => {
				setUsers(users.filter((u) => u.id !== user.id));
				UserService.DeleteUser(user.id).catch((e) => console.error("Failed to delete the user", e));
			},
			t("Detailed user delete explanation")
		);
	};

	let userTableContent = users
		.filter((user) => {
			if (!roleFilter) return true;
			return user.roles.includes(roleFilter.value);
		})
		.map((user) => (
			<tr key={user.id}>
				{/* <td>{index + 1}</td> */}
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ProfileColoredCircle username={user.name} size="35px" />
						<div style={{ marginLeft: "12px" }}>
							{user.name}
							<div className="info-text">{user.roles.length === 0 ? t("no roles") : user.roles.map((r) => t(r)).join(", ")}</div>
						</div>
					</div>
				</td>
				<td>{user.phoneNumber}</td>
				<td>
					<TextButton
						text={t("Edit")}
						icon={<Icon.Edit />}
						onClick={() => {
							EditUser(user.id);
						}}
					/>
				</td>
				<td>
					<IconButton
						size={Size.SM}
						color="red"
						icon={<Icon.Trash2 />}
						onClick={() => {
							DeleteUser(user);
						}}
					/>
				</td>
			</tr>
		));

	return (
		<div style={{ display: "inline-block" }}>
			<div className="controls-above-table">
				<div className="filter">
					<Select isClearable={true} placeholder={t("Filter by role")} options={UserRolesSelectOptions} onChange={setRoleFilter} />
				</div>
				<div className="actions">
					<NormalButton icon={<Icon.UserPlus />} text={t("Create")} onClick={CreateUser} />
				</div>
			</div>
			<table className="table">
				<thead>
					<tr>
						{/* <th>#</th> */}
						<th style={{ minWidth: 250, position: "relative" }}>
							{t("User")} {loadingUsers && <LoadingIndicator inTable={true} />}
						</th>
						<th style={{ minWidth: 150 }}>{t("Phone")}</th>
						<th>{t("Actions")}</th>
						<th>{t("Delete")}</th>
					</tr>
				</thead>
				<tbody>{userTableContent}</tbody>
			</table>
		</div>
	);
}
