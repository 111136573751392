import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import * as EvaluationService from "../../services/Evaluation";
import Evaluation from "../../model/Evaluation";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import * as Icon from "react-feather";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ShowStudentDescriptionModal } from "../common/StudentDescriptionModal";
import { DescriptionStatus, ProofreaderStatusFilterOptions} from "../../Constants";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

/**
 * Displays student evaluations for the proofreader in a table.
 * Only shows the evaluations assigned to the proofreader, or that are not assigned.
 * Only shows the evaluations where description status is "ready for proofread" or "proofread".
 */
export default function ProofReadStudentsPage() {
	const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
	const [teacherFilterOptions, setTeacherFilterOptions] = useState<{ label: string; value: string }[]>([]); // label => name of the teacher, value => id of the teacher
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: string } | null>(); // label => name of the teacher, value => id of the teacher
	const [courseFilterOptions, setCourseFilterOptions] = useState<{ label: string; value: number }[]>([]); // label => name of the course, value => id of the course
	const [courseFilterValue, setCourseFilterValue] = useState<{ label: string; value: number } | null>(); // label => name of the course, value => id of the course
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const [filterName, setFilterName] = useState(""); // used to filter by the student name from the text input
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator
	const userRole = useSelector((state: RootState) => state.activeRole);
	const { t } = useTranslation();

	useEffect(() => {
		LoadEvaluations();
		setStatusFilterOptions(ProofreaderStatusFilterOptions);
	}, [userRole]);

	const LoadEvaluations = () => {
		setIsLoading(true);
		EvaluationService.GetEvaluationsForProofreader().then((_evaluations) => {
			setEvaluations(_evaluations);

			// build filter options based on values from the evaluations (ex the course and the teacher)
			let _teacherFilterOptions: { label: string; value: string }[] = [];
			let _courseFilterOptions: { label: string; value: number }[] = [];
			_evaluations.forEach((_evaluation) => {
				_evaluation.course.teachers?.forEach((t) => {
					_teacherFilterOptions.push({
						label: t.name,
						value: t.id,
					});
				});

				_courseFilterOptions.push({
					label: _evaluation.course.name,
					value: _evaluation.course.id,
				});
			});

			// remove duplicate values from the filter options with the help of lodash
			setTeacherFilterOptions(_.uniqBy(_teacherFilterOptions, "value"));
			setCourseFilterOptions(_.uniqBy(_courseFilterOptions, "value"));

			setIsLoading(false);
		});
	};

	const ShowProofReadEvaluationModal = async (evaluation: Evaluation) => {
		SetEvaluationLoading(evaluation.id, true);
		ShowStudentDescriptionModal(evaluation.id, LoadEvaluations, () => SetEvaluationLoading(evaluation.id, false));
	};

	const SetEvaluationLoading = (evaluationId: number, loading: boolean) => {
		let _tmpEvaluations = [...evaluations];
		_tmpEvaluations.filter((e) => e.id === evaluationId)[0].loading = loading;
		setEvaluations(_tmpEvaluations);
	};

	let EvaluationsHTMlList = evaluations
		.filter((evaluation) => {
			// filter by student name
			if (!filterName) return true;
			return evaluation.student.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((evaluation) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!evaluation.course.teachers) return true;
			return evaluation.course.teachers?.map((t) => t.id).includes(teacherFilterValue.value);
		})
		.filter((evaluation) => {
			// filter by course
			if (!courseFilterValue) return true;
			return evaluation.course.id === courseFilterValue.value;
		})
		.filter((evaluation) => {
			// filter by dscription status
			if (!statusFilterValue) return true;
			return evaluation.description?.status === statusFilterValue.value;
		})
		.map((evaluation) => {
			return (
				<tr key={evaluation.id} onClick={() => ShowProofReadEvaluationModal(evaluation)}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={evaluation.student.name} />
							<div style={{ marginLeft: 8 }}>
								<div>{evaluation.student.name}</div>
							</div>
						</div>
					</td>
					<td>
						{evaluation.course.name}
						<div className="info-text">{evaluation.course.subject?.name}</div>
					</td>
					<td>{evaluation.course.teachers?.map((t) => t.name).join(", ")}</td>
					<td style={{ position: "relative" }}>
						<DescriptionStatusBadge status={evaluation.description.status} />
						{evaluation.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: "16px", display: "flex" }}>
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by teacher...")}
						value={teacherFilterValue}
						options={teacherFilterOptions}
						onChange={setTeacherFilterValue}
						isClearable={true}
					/>
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by course...")}
						value={courseFilterValue}
						options={courseFilterOptions}
						onChange={setCourseFilterValue}
						isClearable={true}
					/>
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Course")}</th>
						<th>{t("Teacher")}</th>
						<th>{t("Status")}</th>
					</tr>
				</thead>
				<tbody>{EvaluationsHTMlList}</tbody>
			</table>
			{EvaluationsHTMlList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
