import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NormalButton from "../../components/input/NormalButton";
import * as Icon from "react-feather";
import { GetSchoolData, SchoolData, UpdateSchoolData, initialSchoolState } from "../../services/School";

export default function AdminOtherPage() {
	const { t } = useTranslation();
	const [isSavingSchoolTab, setIsSavingSchoolTab] = useState(false); //toggle loading indicator on save button
	const [isSavingApTab, setIsSavingApTab] = useState(false); //toggle loading indicator on save button

	const [selectedTab, setSelectedTab] = useState(0);
	
	// these fields are splitted in school data and additional page data as they are in tabs.
	// however on the server they are part of the School class.
	// when the user clicks save, depending on the tab, one of this will be sent to the server.
	const [schoolData, setSchoolData] = useState<SchoolData>(initialSchoolState);
	const [apData, setApData] = useState<SchoolData>({ additionalPageText: "", additionalPageTitle: "", displayAdditionalPage: false});
	const [requireRefresh, setRequireRefresh] = useState<boolean>(false);
	const cardStyle: React.CSSProperties = { display: "flex", marginBottom: 16, justifyContent: "space-between", alignItems: "flex-start" };
	const flexRowStyle: React.CSSProperties = { flex: "1 1 600px",marginBottom: "20px"};
	useEffect(() => {
		LoadData();
	}, []);

	const LoadData = async () => {
		let data = await GetSchoolData();
		setSchoolData({
			name: data.name,
			institutionNumber: data.institutionNumber,
			website: data.website,
			street: data.street,
			city: data.city,
			description: data.description,
			printStartedAtScholl: data.printStartedAtScholl,
			aboutSchoolHeading: data.aboutSchoolHeading,
			showEmneOpgave: data.showEmneOpgave,
			showMVH: data.showMVH,
			useTimesFont: data.useTimesFont,
			italicStatements:data.italicStatements,
			smallerDescriptionlineSpacing:data.smallerDescriptionlineSpacing,
			biggerStatementLineSpacing:data.biggerStatementLineSpacing,
			tableOfContentSizePtc:data.tableOfContentSizePtc,
			roomForSignature:data.roomForSignature,
			useSmallerDescriptionFontSize:data.useSmallerDescriptionFontSize,
			showHFNiveauInHeadings:data.showHFNiveauInHeadings,
			showCourseDescriptions:data.showCourseDescriptions,
			printA5Page : data.printA5Page,
			displaySchoolInfo : data.displaySchoolInfo
		});
		setApData({
			additionalPageText: data.additionalPageText,
			additionalPageTitle: data.additionalPageTitle,
			displayAdditionalPage: data.displayAdditionalPage,
		});
	};

	const Save = async () => {
		switch (selectedTab) {
			case 0:
			case 2:
				// tab 0 updates only stuff related to school
				// and tab 2 settings
				setIsSavingSchoolTab(true);
				await UpdateSchoolData(schoolData);
				setIsSavingSchoolTab(false);
				if(requireRefresh){
					window.location.reload();//reload efter menuen ændrer sig. TODO: find some way to reference Sidemenu.tsx from here and force a reload og customer data to prevent this horrible code.
				}
				break;
			case 1:
				// tab 1 updates additional page 
				setIsSavingApTab(true);
				await UpdateSchoolData(apData);
				setIsSavingApTab(false);
				break;
		}
	};
	// util method to create a label and below it an input type="number"
	const NumberField = (label: string, value: number | undefined, unit: string, onChange: (v: number) => any) => {
		return (
			<div>
				<div  style={{ marginTop: 8 }}>
					{label} <input type="number" style={{ width: "15%" }} value={value} onChange={(e) => onChange(e.currentTarget.valueAsNumber)} /> {unit}
				</div>
			</div>
		);
	};

	// util method to create a label and below it an input type="text"
	const TextField = (label: string, value: string | undefined, onChange: (v: string) => any) => {
		return (
			<div>
				<div className="label" style={{ marginTop: 8 }}>
					{label}
				</div>
				<input type="text" style={{ width: "100%" }} value={value} onChange={(e) => onChange(e.currentTarget.value)} />
			</div>
		);
	};

	// util method to create a label and below it a textarea
	const TextArea = (label: string, value: string | undefined, onChange: (v: string) => any) => {
		return (
			<div>
				<div className="label" style={{ marginTop: 8 }}>
					{label}
				</div>
				<textarea style={{ width: "100%", height: "35vh" }} value={value} onChange={(e) => onChange(e.currentTarget.value)}></textarea>
			</div>
		);
	};

	return (
		<div style={{ width: "35vw" }}>
			<div className="tab-buttons">
				<div className={`${selectedTab === 0 ? "active" : ""}`} onClick={() => setSelectedTab(0)}>
					{t("School info")}
				</div>
				<div className={`${selectedTab === 1 ? "active" : ""}`} onClick={() => setSelectedTab(1)}>
					{t("Additional page")}
				</div>
				<div className={`${selectedTab === 2 ? "active" : ""}`} onClick={() => setSelectedTab(2)}>
					{t("settings page")}
				</div>
			</div>

			{/* school tab */}
			<div style={{ display: selectedTab === 0 ? "block" : "none" }}>
				{TextField(t("Name"), schoolData.name, (v) => setSchoolData({ ...schoolData, name: v }))}
				{TextField(t("Institution number"), schoolData.institutionNumber, (v) => setSchoolData({ ...schoolData, institutionNumber: v }))}
				{TextField(t("Website"), schoolData.website, (v) => setSchoolData({ ...schoolData, website: v }))}
				{TextField(t("Street"), schoolData.street, (v) => setSchoolData({ ...schoolData, street: v }))}
				{TextField(t("City"), schoolData.city, (v) => setSchoolData({ ...schoolData, city: v }))}
				{TextArea(t("About school"), schoolData.description, (v) => setSchoolData({ ...schoolData, description: v }))}
				<div className="info-text">{t("'About School' field is displayed on the 'About school' PDF page")}</div>
				{TextField(t("About school heading"), schoolData.aboutSchoolHeading, (v) => setSchoolData({ ...schoolData, aboutSchoolHeading: v }))}

				{/* display school info checkbox */}
				<label>
					<div className="label" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
						{t("Display school info")}
					</div>
					<input
						type="checkbox"
						style={{ display: "inline-block" }}
						checked={schoolData.displaySchoolInfo}
						onChange={(e) => setSchoolData({ ...schoolData, displaySchoolInfo: e.currentTarget.checked })}
					/>
				</label>
				{/* school info not displayed warning */}
				{schoolData.displaySchoolInfo && (schoolData.description?.length === 0 || schoolData.aboutSchoolHeading?.length === 0) && (
					<div className="warning">{t("School info will not be displayed because it is missing the title or text.")}</div>
				)}
				<div style={{ textAlign: "right", marginTop: 16 }}>
					<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={Save} loading={isSavingSchoolTab} />
				</div>
			</div>

			{/* additional page tab */}
			<div style={{ display: selectedTab === 1 ? "block" : "none" }}>
				{TextField(t("Additional page title"), apData.additionalPageTitle, (v) => setApData({ ...apData, additionalPageTitle: v }))}
				{TextArea(t("Additional page content"), apData.additionalPageText, (v) => setApData({ ...apData, additionalPageText: v }))}
				{/* display additional page checkbox */}
				<label>
					<div className="label" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
						{t("Display additional page")}
					</div>
					<input
						type="checkbox"
						style={{ display: "inline-block" }}
						checked={apData.displayAdditionalPage}
						onChange={(e) => setApData({ ...apData, displayAdditionalPage: e.currentTarget.checked })}
					/>
				</label>
				{/* additional page not displayed warning */}
				{apData.displayAdditionalPage && (apData.additionalPageText?.length === 0 || apData.additionalPageTitle?.length === 0) && (
					<div className="warning">{t("Additional page will not be displayed because it is missing the title or text.")}</div>
				)}
				<div style={{ textAlign: "right", marginTop: 16 }}>
					<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={Save} loading={isSavingApTab} />
				</div>
			</div>
			{/* settings tab */}
			<div style={{ display: selectedTab === 2 ? "block" : "none" }}>
				<div className="card" style={cardStyle} >
					<div style={flexRowStyle}>
						<div className="label">	{t("print settings")}</div>
					
						{/* display "started at school" checkbox */}
						<label>
							<div className="" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
								{t("show started at")}
							</div>
							<input
								type="checkbox"
								style={{ display: "inline-block" }}
								checked={schoolData.printStartedAtScholl}
								onChange={(e) => setSchoolData({ ...schoolData, printStartedAtScholl: e.currentTarget.checked })}
							/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* display "show kind regards" checkbox */}
						<label>
							<div className="" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
								{t("show king regards")}
							</div>
							<input
								type="checkbox"
								style={{ display: "inline-block" }}
								checked={schoolData.showMVH}
								onChange={(e) => setSchoolData({ ...schoolData, showMVH: e.currentTarget.checked })}
							/>
						</label>
					</div>
					{
						!schoolData.showMVH && (
							<div style={flexRowStyle}>
							{/* display "room for signature" checkbox */}
							<label>
								<div className="" style={{ marginLeft:8, marginTop: 8, marginRight: 4, display: "inline-block" }}>
									{t("room for signature")}
								</div>
								<input
									type="checkbox"
									style={{ display: "inline-block" }}
									checked={schoolData.roomForSignature}
									onChange={(e) => setSchoolData({ ...schoolData, roomForSignature: e.currentTarget.checked })}
								/>
							</label>
						</div>
						)
					}
					
					<div style={flexRowStyle}>
						{/* display "use times new roman" checkbox */}
						<label>
							<div className="" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
								{t("use times new roman")}
							</div>
							<input
								type="checkbox"
								style={{ display: "inline-block" }}
								checked={schoolData.useTimesFont}
								onChange={(e) => setSchoolData({ ...schoolData, useTimesFont: e.currentTarget.checked })}
							/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* set italic statements */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("show statements italic")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.italicStatements}
							onChange={(e) => setSchoolData({ ...schoolData, italicStatements: e.currentTarget.checked })}
						/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* set biggerStatementLineSpacing */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("biggerStatementLineSpacing")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.biggerStatementLineSpacing}
							onChange={(e) => setSchoolData({ ...schoolData, biggerStatementLineSpacing: e.currentTarget.checked })}
						/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* set smallerDescriptionlineSpacing */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("smallerDescriptionlineSpacing")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.smallerDescriptionlineSpacing}
							onChange={(e) => setSchoolData({ ...schoolData, smallerDescriptionlineSpacing: e.currentTarget.checked })}
						/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* set useSmallerDescriptionFontSize */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("smaller text size in description")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.useSmallerDescriptionFontSize}
							onChange={(e) => setSchoolData({ ...schoolData, useSmallerDescriptionFontSize: e.currentTarget.checked })}
						/>
						</label>
					</div>
					<div style={flexRowStyle}>
						{/* set table of content text scale % */}
						{NumberField(t("table of content text scale"), schoolData.tableOfContentSizePtc,'%', (v) => setSchoolData({ ...schoolData, tableOfContentSizePtc: v }))}
					</div>
					<div style={flexRowStyle}>
						{/* set showHFNiveauInHeadings */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("show hf niveau in headings")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.showHFNiveauInHeadings}
							onChange={(e) => setSchoolData({ ...schoolData, showHFNiveauInHeadings: e.currentTarget.checked })}
						/>
						</label>
					</div>

					<div style={flexRowStyle}>
						{/* set showCourseDescriptions */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
								{t("show course descriptions")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.showCourseDescriptions}
							onChange={(e) => setSchoolData({ ...schoolData, showCourseDescriptions: e.currentTarget.checked })}
						/>
						</label>
					</div>

					<div style={flexRowStyle}>
						{/* set printA5Page */}
						<label>
							<div className="" style={{marginTop: 8, marginRight: 4, display: "inline-block"}}>
							{t("print A5")}
							</div>
							<input
							type="checkbox"
							style={{ display: "inline-block" }}
							checked={schoolData.printA5Page}
							onChange={(e) => setSchoolData({ ...schoolData, printA5Page: e.currentTarget.checked })}
						/>
						</label>
					</div>

					<div style={flexRowStyle}>
						<div className="label">	{t("general setting")}</div>
						{/* vis emne opgaver*/} 
					
						<label>
							<div className="" style={{ marginTop: 8, marginRight: 4, display: "inline-block" }}>
								{t("show Emneopgaver")}
							</div>
							<input
								type="checkbox"
								style={{ display: "inline-block" }}
								checked={schoolData.showEmneOpgave}
								onChange={(e) => {setSchoolData({ ...schoolData, showEmneOpgave: e.currentTarget.checked });setRequireRefresh(true);}}
							/>
						</label>
						<div style={{ textAlign: "right", marginTop: 16 }}>
						<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={Save} loading={isSavingSchoolTab} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
