type InputWithLabelProps = {
	labelIcon?: React.ReactNode;
	onInput?: (value: string) => any;
	onBlur?: (value: string) => any;
	value: string | number;
	placeholder?: string;
};

export default function InputWithLabel({ labelIcon, onInput, onBlur, value, placeholder }: InputWithLabelProps) {
	return (
		<div className="input-with-label">
			<input
				type="text"
				value={value}
				placeholder={placeholder}
				onBlur={(e) => {
					if (onBlur) onBlur(e.currentTarget.value);
				}}
				onInput={(e) => {
					if (onInput) onInput(e.currentTarget.value);
				}}
			/>
			<div>{labelIcon}</div>
		</div>
	);
}
