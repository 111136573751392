import { UserRoles } from "../Constants";
import Course from "../model/Course";
import Axios from "./CustomAxios";

export const CreateCourse = async (subject: Course): Promise<Course> => {
	return (await Axios.post(`/rsvidnesbyrd/course/create`, subject)).data;
};

export const GetAllCourses = async (): Promise<Course[]> => {
	return (await Axios.get(`/rsvidnesbyrd/course/all`)).data;
};

export const GetCoursesForProofreading = async (): Promise<Course[]> => {
	return (await Axios.get(`/rsvidnesbyrd/course/forProofreading`)).data;
};

export const GetAllCoursesForTeacher = async (teacherId: string): Promise<Course[]> => {
	return (await Axios.get(`/rsvidnesbyrd/course/forTeacher/${teacherId}`)).data;
};

export const GetCourse = async (courseId: number): Promise<Course> => {
	return (await Axios.get(`/rsvidnesbyrd/course/${courseId}`)).data;
};

export const GetCoursesOverviewForTeacher = async (): Promise<any> => {
	return (await Axios.get(`/rsvidnesbyrd/course/teacherOverview`)).data;
};

export const GetCoursesOverviewForProofReader = async (): Promise<any> => {
	return (await Axios.get(`/rsvidnesbyrd/course/proofReaderOverview`)).data;
};

export const GetCoursesOverviewForAdmin = async (): Promise<any> => {
	return (await Axios.get(`/rsvidnesbyrd/course/adminOverview`)).data;
};

export const GetCoursesOverview = async (userRole : UserRoles): Promise<any> => {
	if (userRole === UserRoles.Admin){
		return (await Axios.get(`/rsvidnesbyrd/course/adminOverview`)).data;
	} else if(userRole === UserRoles.Teacher){
		return (await Axios.get(`/rsvidnesbyrd/course/teacherOverview`)).data;
	} else if(userRole === UserRoles.ProofReader){
		return (await Axios.get(`/rsvidnesbyrd/course/proofReaderOverview`)).data;
	}
};

export const UpdateCourse = async (course: Course): Promise<Course> => {
	return (await Axios.put(`/rsvidnesbyrd/course/update`, course)).data;
};

export const DeleteCourse = async (courseId: number) => {
	return await Axios.delete(`/rsvidnesbyrd/course/${courseId}`);
};
