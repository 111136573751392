import { useTranslation } from "react-i18next";
import { DescriptionStatus } from "../Constants";
import * as Icon from "react-feather";

type StatusProgressBarProps = { descriptionStatus: DescriptionStatus | undefined };

export default function StatusProgressBar({ descriptionStatus }: StatusProgressBarProps) {
	let level = 0; // default, DescriptionStatus.Created
	switch (descriptionStatus) {
		case DescriptionStatus.ReadyForProofRead:
			level = 1;
			break;
		case DescriptionStatus.ProofRead:
			level = 2;
			break;
		case DescriptionStatus.Done:
			level = 3;
			break;
	}

	const { t } = useTranslation();
	return (
		<div className="progressBar">
			<div className={`checkpoint ${level >= 0 ? "active" : ""}`}>
				<Icon.Check />
				{t(`descriptionStatus.${DescriptionStatus.Created}`)}
			</div>
			<div className={`line ${level >= 1 ? "active" : ""}`}></div>
			<div className={`checkpoint ${level >= 1 ? "active" : ""}`}>
				<Icon.Check />
				{t(`descriptionStatus.${DescriptionStatus.ReadyForProofRead}`)}
			</div>
			<div className={`line ${level >= 2 ? "active" : ""}`}></div>
			<div className={`checkpoint ${level >= 2 ? "active" : ""}`}>
				<Icon.Check />
				{t(`descriptionStatus.${DescriptionStatus.ProofRead}`)}
			</div>
			<div className={`line ${level >= 3 ? "active" : ""}`}></div>
			<div className={`checkpoint ${level >= 3 ? "active" : ""}`}>
				<Icon.Check />
				{t(`descriptionStatus.${DescriptionStatus.Done}`)}
			</div>
		</div>
	);
}
