import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ShowErrorPopup } from "../../../components/ErrorPopup";
import NormalButton from "../../../components/input/NormalButton";
import Template from "../../../model/Template";
import { GetTemplateForCurrentSchool, UpdateTemplate } from "../../../services/Template";

export default function PdfFields() {
	const { t } = useTranslation();
	const monthNames = moment.months();
	const [monthSelectOptions, setMonthSelectOptions] = useState(monthNames.map((m, index) => ({ label: _.capitalize(m), value: index })));
	const [selectedMonth, setSelectedMonth] = useState<{ label: string; value: number } | undefined>();
	const [educationEndDate, setEducationEndDate] = useState<Date>(new Date());
	const [showSaveButton, setShowSaveButton] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		LoadTemplate();
	}, []);

	const LoadTemplate = async () => {
		let template = await GetTemplateForCurrentSchool();
		setSelectedMonth({ label: _.capitalize(monthNames[template.monthFirstPage]), value: template.monthFirstPage });
		if (typeof template.educationEndDate === "string") setEducationEndDate(new Date(template.educationEndDate));
	};

	const SaveTemplateData = async () => {
		setIsSaving(true);
		const template: Template = {
			monthFirstPage: selectedMonth?.value || 0,
			educationEndDate: educationEndDate,
		};
		try {
			await UpdateTemplate(template);
		} catch (e) {
			console.log("Failed to update template data", e);
			ShowErrorPopup(t("An unexpected error occured."));
		} finally {
			setShowSaveButton(false);
			setIsSaving(false);
		}
	};

	return (
		<div className="card" style={{ marginLeft: 24, marginTop: 62, width: 250 }}>
			<div className="label">{t("Month (shown on the first page)")}</div>
			<Select
				value={selectedMonth}
				options={monthSelectOptions}
				onChange={(v) => {
					if (v) setSelectedMonth(v);
					setShowSaveButton(true);
				}}
			/>
			<div className="label" style={{ marginTop: 16 }}>
				{t("Education finished date (HF-bevis) ")}
			</div>
			<DatePicker
				wrapperClassName="datePicker100Width"
				locale="da"
				dateFormat="dd/MM/yyyy"
				selected={educationEndDate}
				onChange={(date) => {
					setEducationEndDate(date as Date);
					setShowSaveButton(true);
				}}
			/>

			{showSaveButton && (
				<div style={{ marginTop: 8, textAlign: "right" }}>
					<NormalButton text={t("Save")} icon={<Icon.Save />} onClick={SaveTemplateData} loading={isSaving} />
				</div>
			)}
		</div>
	);
}
