import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { ShowErrorPopup } from "../../../components/ErrorPopup";
import NormalButton from "../../../components/input/NormalButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SchoolClass, { GetClassName } from "../../../model/SchoolClass";
import { GenerateArchiveWithPDFForClass } from "../../../services/PdfGenerator";
import * as SchoolClassService from "../../../services/SchoolClass";

/**
 * This modal displays a list of classes so the Admin can download a ZIP with evaluations for an entire class.
 */
type GeneratePDFPerClassModalProps = {};
export const GeneratePDFPerClassModal = ({}: GeneratePDFPerClassModalProps) => {
	const [schoolClasses, setSchoolClasses] = useState<SchoolClass[]>([]);
	const [loading, setLoading] = useState(true); // shows/hides loading indicator
	const { t } = useTranslation();

	useEffect(() => {
		SchoolClassService.GetAllSchoolClasses().then((_schoolClasses) => {
			setSchoolClasses(_schoolClasses);
			setLoading(false);
		});
	}, []);

	const SetClassLoading = (schoolClass: SchoolClass, loading: boolean) => {
		let a = [...schoolClasses];
		a.forEach((a) => {
			if (a.id === schoolClass.id) a.loading = loading;
		});
		setSchoolClasses(a);
	};

	const DownloadForClass = (schoolClass: SchoolClass) => {
		SetClassLoading(schoolClass, true);
		GenerateArchiveWithPDFForClass(schoolClass.id)
			.then((response) => {
				let data = JSON.parse(response.data);
				let zipUrl = data.link;

				// trigger zip download with the help of <a> tag
				const link = document.createElement("a");
				link.href = zipUrl;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((e) => {
				ShowErrorPopup(t("An unexpected error occured."));
				console.log(e.response);
			})
			.finally(() => {
				SetClassLoading(schoolClass, false);
			});
	};

	return (
		<div>
			{loading && (
				<div style={{ textAlign: "center" }}>
					<LoadingIndicator />
				</div>
			)}
			{!loading && (
				<table className="table scrollable" style={{ width: 350 }}>
					<thead>
						<tr>
							<th>{t("Class")}</th>
							<th>{t("Download")}</th>
						</tr>
					</thead>
					<tbody>
						{schoolClasses.map((schoolClass) => (
							<tr key={schoolClass.id}>
								<td>
									{GetClassName(schoolClass)}
									<div className="info-text">
										{schoolClass.studentsNumber} {schoolClass.studentsNumber === 1 ? t("student") : t("students")}
									</div>
								</td>
								<td>
									<NormalButton text={t("Download")} icon={<Icon.Download />} onClick={() => DownloadForClass(schoolClass)} loading={schoolClass.loading} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
