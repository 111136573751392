import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as DescriptionService from "../../services/Description";
import * as Icon from "react-feather";
import { DescriptionStatus, PreviousTextVersionDropdownStyle, UserRoles } from "../../Constants";
import _ from "lodash";
import { CloseGeneralModal, SetGeneralModalCloseByPressingXCallback } from "../../components/GeneralModal";
import StatusProgressBar from "../../components/StatusProgresbar";
import NormalButton from "../../components/input/NormalButton";
import TextButton from "../../components/input/TextButton";
import Description from "../../model/Description";
import Select from "react-select";
import moment from "moment";
import { ShowConfirmationPopup } from "../../components/ConfirmationModal";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

/**
 * The description section is used in various evaluation modals.
 * It contains all the logic, for both the proofreader and the teacher, for editing a description.
 * @param hasWrittenComment becomes true when a comment is written after the modal is opened. Used to prevent rejecting the description if there is no comment (reason).
 */
type DescriptionSectionProps = { description: Description; hasWrittenComment: boolean; onSave: () => any };
export default function DescriptionSection({ description: providedDescription, hasWrittenComment, onSave }: DescriptionSectionProps) {
	const { t } = useTranslation();
	const [description, setDescription] = useState(providedDescription);
	const [text, setText] = useState("");
	const [showPrevious, setShowPrevious] = useState(false);
	const [previousDescriptionStatus, setPreviousDescriptionStatus] = useState<DescriptionStatus>();
	const [canSave, setCanSave] = useState(false); //used to enable/disable save button if there are/aren't changes.
	const [proofreaderCanSkip, setProofreaderCanSkip] = useState(true); // this one is used to detect if the proofreader can skip to done (if he/she hasn't done any changes)
	
	const userRole = useSelector((state: RootState) => state.activeRole);
	
	const isTeacher = userRole === UserRoles.Teacher ;
	const isAdmin = userRole === UserRoles.Admin ;
	const isProofReader = userRole === UserRoles.ProofReader;
	//for the dropdown that allows you to pick what's the previous version you view
	const [prevVersSelectedOption, setPrevVersSelectedOption] = useState<{ label: string; value: number }>();
	const [prevVersSelectOptions, setPrevVersSelectOptions] = useState<{ label: string; value: number }[]>();

	useEffect(() => {
		if (providedDescription.texts.length > 0) {
			setText(providedDescription.texts[0].text);
			let versSelectOptions = providedDescription.texts.map((text, index) => ({
				label: `${text.byTeacher ? t("Teacher") : t("Proofreader")} (${moment(text.createTime).format("LLL")})`,
				value: index,
			}));
			setPrevVersSelectOptions(versSelectOptions);
			setPrevVersSelectedOption(versSelectOptions[0]);

			// the proofreader cannot skip to "Done" status if the last text is written by him
			if (!isTeacher && !providedDescription.texts[0].byTeacher) setProofreaderCanSkip(false);
		}
	}, [providedDescription]);

	//reset the selected previous version dropdown when closing/showing previous versions
	useEffect(() => {
		if (prevVersSelectOptions && prevVersSelectOptions.length > 0) setPrevVersSelectedOption(prevVersSelectOptions[0]);
	}, [showPrevious]);

	const SaveText = async () => {
		await DescriptionService.SaveOrUpdateDescriptionText(description.id, {
			text: text,
			byTeacher: isTeacher,
		});

		// if they went back to edit from another status and they clicked save,
		// then set previous status to undefined, so the status doesn't reset when the modal is closed by pressing X.
		setPreviousDescriptionStatus(undefined);

		setCanSave(false);

		if(isAdmin){
			CloseGeneralModal()
		}
	};

	const ChangeDescriptionStatus = async (status: DescriptionStatus) => {
		setPreviousDescriptionStatus(description.status);
		await DescriptionService.UpdateDescriptionStatus(description.id, status);
		let tempDescription = _.cloneDeep(description);
		tempDescription.status = status;
		setDescription(tempDescription);
	};

	const Edit = async () => {
		setShowPrevious(false);

		//when the teacher edits the description, its status is moved to "Created"
		if (isTeacher) {
			await ChangeDescriptionStatus(DescriptionStatus.Created);
			onSave();
		}

		//when a proofreader edits a description, its status is moved to "ReadyForProofRead"
		if (isProofReader) {
			await ChangeDescriptionStatus(DescriptionStatus.ReadyForProofRead);
			onSave();
		}
	};

	const NextStep = async () => {
		//teacher click "Ready for proofread" button, the description becomes "Ready for proofread"
		if (isTeacher && description.status === DescriptionStatus.Created) {
			await SaveText();
			await ChangeDescriptionStatus(DescriptionStatus.ReadyForProofRead);
			onSave();
			CloseGeneralModal();
		}

		//teacher clicks "Accept proofread" button in "Proofread" status, the description goes to "Done" status
		if (isTeacher && description.status === DescriptionStatus.ProofRead) {
			await ChangeDescriptionStatus(DescriptionStatus.Done);
			onSave();
			CloseGeneralModal();
		}

		// proofreader clicks "Proofread" button in "Ready for proofread" state, the description goes to "Proofread" status
		// or if there aren't any changes, it can go directly to "Done status"
		if (isProofReader && description.status === DescriptionStatus.ReadyForProofRead) {
			if (!proofreaderCanSkip) {
				await SaveText();
				await ChangeDescriptionStatus(DescriptionStatus.ProofRead);
				onSave();
				CloseGeneralModal();
			} else {
				ShowConfirmationPopup({
					message: t("You did not do any changes, so the description will be instantly approved. Are you sure you want to proceeed?"),
					confirmButtonText: t(`descriptionStatus.${DescriptionStatus.Done}`),
					buttonColor: "green",
					onConfirm: async () => {
						await ChangeDescriptionStatus(DescriptionStatus.Done);
						onSave();
						CloseGeneralModal();
					},
				});
			}
		}
	};

	const Reject = async () => {
		// the proofreader can reject the description. This also requires a comment to be written before this.
		if (isProofReader && description.status === DescriptionStatus.ReadyForProofRead && hasWrittenComment) {
			ShowConfirmationPopup({
				message: t("Are you sure you want to reject the text?"),
				confirmButtonText: t("Reject"),
				buttonColor: "red",
				onConfirm: async () => {
					await ChangeDescriptionStatus(DescriptionStatus.Created);
					onSave();
					CloseGeneralModal();
				},
			});
		}
	};

	const OnModalCloseByPressingX = async () => {
		//when the modal is closed by pressing on X,
		//if the proofreader or the teacher are in edit and they came to edit from another status,
		//then restore that description status.

		if (isTeacher && description.status === DescriptionStatus.Created && previousDescriptionStatus) {
			await ChangeDescriptionStatus(previousDescriptionStatus);
		}

		if (isProofReader && description.status === DescriptionStatus.ReadyForProofRead && previousDescriptionStatus) {
			await ChangeDescriptionStatus(previousDescriptionStatus);
		}
	};
	SetGeneralModalCloseByPressingXCallback(OnModalCloseByPressingX);

	let descriptionTextBox = <div className="text-box">{text}</div>;
	let teacherEditInfo = (
		<div className="info-text">
			{t('If you edit the description, the status will move back to "Oprettet" to prevent a proofreader from editing it in the meantime.')}
		</div>
	);
	let proofreaderEditInfo = (
		<div className="info-text">
			{t('If you edit the description, the status will move back to "Klar til korrektur" to prevent a teacher from editing it in the meantime.')}
		</div>
	);
	let previousVersionColumn = (
		<div className="flexCol1" style={{ marginLeft: 16 }}>
			<div className="flexRowBetween" style={{ marginBottom: 4 }}>
				<div style={{ flex: 1, marginRight: 32 }}>
					<Select
						value={prevVersSelectedOption}
						options={prevVersSelectOptions}
						isClearable={false}
						styles={PreviousTextVersionDropdownStyle}
						isSearchable={false}
						onChange={(v) => {
							if (v) setPrevVersSelectedOption(v);
						}}
					/>
				</div>
				<TextButton text={t("Hide")} onClick={() => setShowPrevious(false)} icon={<Icon.ChevronRight />} iconAtEnd={true} />
			</div>
			{prevVersSelectedOption && <div className="text-box">{description.texts[prevVersSelectedOption.value].text}</div>}
		</div>
	);
	//the edit section has the edit text area, and also possibly "View previous version" if there are any
	let editSection = (
		<div className="flexCol1">
			<div className="flexRow1">
				<div className="flexCol1">
					<div className="flexRowBetween" style={{ marginBottom: 4 }}>
						<div className="independent-label" style={{ marginBottom: 0 }}>
							{t("Description")}
						</div>
						{!showPrevious && description.texts.length > 1 && (
							<TextButton text={t("View previous versions")} onClick={() => setShowPrevious(true)} icon={<Icon.ChevronLeft />} />
						)}
					</div>
					<textarea
						style={{ flex: 1, boxSizing: "border-box", resize: "none" }}
						value={text}
						onChange={(e) => {
							setText(e.currentTarget.value);
							setCanSave(true);
							setProofreaderCanSkip(false);
						}}
					></textarea>
				</div>
				{showPrevious && previousVersionColumn}
			</div>
		</div>
	);
	let viewSection = (
		<div className="flexCol1">
			<div className="flexRow1">
				<div className="flexCol1">
					<div className="flexRowBetween" style={{ marginBottom: 4 }}>
						<div className="independent-label" style={{ marginBottom: 0 }}>
							{t("Description")}
						</div>
						{!showPrevious && description.texts.length > 1 && (
							<TextButton text={t("View previous versions")} onClick={() => setShowPrevious(true)} icon={<Icon.ChevronLeft />} />
						)}
					</div>
					{descriptionTextBox}
				</div>
				{showPrevious && previousVersionColumn}
			</div>
		</div>
	);
	let mainContentHTML;
	let buttonsHTML;

	if (description.status === DescriptionStatus.Created) {
		//modal for the teacher when the description is in the "Created" status
		if (isTeacher) {
			mainContentHTML = editSection;
			buttonsHTML = (
				<div className="flexRowEnd">
					<NormalButton
						text={t("Save")}
						icon={<Icon.Save />}
						style={{ marginRight: 12 }}
						disabled={!canSave}
						disabledTooltip={t("There aren't any changes to save.")}
						onClick={SaveText}
					/>
					<NormalButton text={t("Ready for proofread")} icon={<Icon.ChevronRight />} iconAtEnd={true} color="green" onClick={NextStep} />
				</div>
			);
		}

		//modal for the proofreader when description is in the "Created" status.
		//this shouldn't happen, but just in case a proofreader opens a description with this status.
		if (isProofReader) {
			mainContentHTML = <div>{t("Wait for teachers text...")}</div>;
		}
	}

	if (description.status === DescriptionStatus.ReadyForProofRead) {
		if (isTeacher) {
			mainContentHTML = (
				<div className="flexCol1">
					<div className="independent-label">{t("Description")}</div>
					{descriptionTextBox}
					{teacherEditInfo}
				</div>
			);
			buttonsHTML = (
				<div className="flexRowEnd">
					<NormalButton text={t("Edit")} icon={<Icon.Edit />} onClick={Edit} />
				</div>
			);
		}

		// When the description status is ReadyForProofRead (2nd step) the proof reader can do some changes, save them, and mark it as proofreader.
		// The proofreader can also reject it, however to reject it it is required to write a comment with an explanation
		if (isProofReader) {
			mainContentHTML = editSection;
			buttonsHTML = (
				<div style={{ display: "flex" }}>
					<NormalButton
						text={t("Reject")}
						color={"red"}
						icon={<Icon.XSquare />}
						disabled={!hasWrittenComment}
						disabledTooltip={t("Please write a comment if you want to reject the description, and the button will become active.")}
						onClick={Reject}
					/>
					<div style={{ flex: 1 }}>{/* fill space and push buttons to left/right */}</div>
					<NormalButton text={t("Save")} icon={<Icon.Save />} disabled={!canSave} disabledTooltip={t("There aren't any changes to save.")} onClick={SaveText} />
					<NormalButton
						text={t("Proofread")}
						icon={<Icon.ChevronRight />}
						style={{ marginLeft: 12, marginRight: 12 }}
						iconAtEnd={true}
						color={"green"}
						onClick={NextStep}
					/>
				</div>
			);
		}
	}

	if (description.status === DescriptionStatus.ProofRead) {
		// in "Proofread" state the teacher can approve the description, or edit it
		if (isTeacher) {
			mainContentHTML = viewSection;
			buttonsHTML = (
				<div className="flexRowEnd">
					<NormalButton text={t("Edit")} icon={<Icon.Edit />} style={{ marginRight: 12 }} onClick={Edit} />
					<NormalButton text={t("Accept proofread")} icon={<Icon.ChevronRight />} color="green" iconAtEnd={true} onClick={NextStep} />
				</div>
			);
		}

		// in "Proofread" status the proodreader can click "Edit" to go back to "ReadyForProofread" status and edit the description
		if (isProofReader) {
			mainContentHTML = (
				<div className="flexCol1">
					<div className="independent-label">{t("Description")}</div>
					{descriptionTextBox}
					{proofreaderEditInfo}
				</div>
			);
			buttonsHTML = (
				<div className="flexRowEnd">
					<NormalButton text={t("Edit")} icon={<Icon.Edit />} onClick={Edit} />
				</div>
			);
		}
	}

	if (description.status === DescriptionStatus.Done) {
		// in "Done" status, the teacher sees the final text, and can click "Edit" to go back to "Created" state and edit the description.
		if (isTeacher) {
			mainContentHTML = (
				<div className="flexCol1">
					<div className="independent-label">{t("Description")}</div>
					{descriptionTextBox}
					{teacherEditInfo}
				</div>
			);
			buttonsHTML = (
				<div className="flexRowEnd">
					<NormalButton text={t("Edit")} icon={<Icon.Edit />} style={{ marginRight: 12 }} onClick={Edit} />
				</div>
			);
		}
		// normally the proofreader will not be able to open this modal is description is in "Done" state, but just in case someone somehow opens it, show the description
		if (isProofReader) {
			mainContentHTML = <div className="flexCol1">{descriptionTextBox}</div>;
		}
	}

	// the admin can change the text, the status will not be changed
	if(isAdmin){
		mainContentHTML = editSection;
		buttonsHTML = (
			<div className="flexRowEnd">
				<NormalButton
					text={t("Save")}
					icon={<Icon.Save />}
					style={{ marginRight: 12 }}
					disabled={!canSave}
					disabledTooltip={t("There aren't any changes to save.")}
					onClick={SaveText}
				/>
			</div>
		);
	}

	return (
		<div className="flexCol1">
			<StatusProgressBar descriptionStatus={description.status} />
			{mainContentHTML}
			<div style={{ marginTop: 12 }}>{buttonsHTML}</div>
		</div>
	);
}
