import Axios from "./CustomAxios";

/**
 * Generate an archive with PDFs for a class.
 * @param schoolClassId id of the class
 * @param fields some fields that will be placed in the generated PDF
 * @returns the returned JSON contains a key 'link' with the link for the generated archive.
 */
export const GenerateArchiveWithPDFForClass = async (schoolClassId: number) => {
	return (await Axios.post(`/rsvidnesbyrd/pdfGenerator/generateForClass/${schoolClassId}`)).data;
};

/**
 * Generate a complete PDF for a student.
 * @param studentId id of the student
 * @param fields some fields that will be placed in the generated PDF
 * @returns the returned JSON contains a key 'link' with the link for the generated PDF
 */
export const GeneratePdfForStudent = async (studentId: number) => {
	return (await Axios.post(`/rsvidnesbyrd/pdfGenerator/generate/${studentId}`)).data;
};

export const GeneratePdfPreviewForStudent = async (studentId: number, courseId: number) => {
	return (await Axios.post(`/rsvidnesbyrd/pdfGenerator/preview/${studentId}/${courseId}`)).data;
};

export const GenerateEvaluationPdfPreview = async (evaluationId: number) => {
	return (await Axios.post(`/rsvidnesbyrd/pdfGenerator/previewEvaluation/${evaluationId}`)).data;
};