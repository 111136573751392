import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ClassLevelSelectOptions, ManageModalAction, Size } from "../../../Constants";
import { ShowManageClassModal } from "./ManageClassModal";
import * as SchoolClassService from "../../../services/SchoolClass";
import SchoolClass, { GetClassName } from "../../../model/SchoolClass";
import Course from "../../../model/Course";
import Student from "../../../model/Student";
import * as StudentService from "../../../services/Student";
import * as CourseService from "../../../services/Course";
import ShowStudentSelectModal, { StudentSelectModalIntent } from "./StudentSelectModal";
import ShowManageClassCoursesModal from "./ManageClassCoursesModal";
import * as Icon from "react-feather";
import LoadingIndicator from "../../../components/LoadingIndicator";
import IconButton from "../../../components/input/IconButton";
import TextButton from "../../../components/input/TextButton";
import { ShowConfirmationPopup, ShowDeleteModal } from "../../../components/ConfirmationModal";

/**
 * Displays the list of school classes in a table for the admin.
 */
export default function ManageClassesPage() {
	const [schoolClasses, setSchoolClasses] = useState<SchoolClass[]>([]);
	const [filterClassLevel, setFilterClassLevel] = useState<{ value: number; label: string } | null>();

	const [allStudents, setAllStudents] = useState<Student[]>([]);
	const [allCourses, setAllCourses] = useState<Course[]>([]);
	const [loadingClasses, setLoadingClasses] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		LoadClasses();
		StudentService.GetAllStudents().then(setAllStudents);
		CourseService.GetAllCourses().then(setAllCourses);
	}, []);

	const LoadClasses = () => {
		setLoadingClasses(true);
		SchoolClassService.GetAllSchoolClasses().then((sc) => {
			setSchoolClasses(sc);
			setLoadingClasses(false);
		});
	};

	const AddStudentsToClass = (schoolClass: SchoolClass, students: Student[]) => {
		schoolClass.students = students;
		SchoolClassService.UpdateStudents(schoolClass).then((data) => {
			LoadClasses();
		});
	};

	const DeleteClass = (schoolClass: SchoolClass) => {
		ShowDeleteModal(GetClassName(schoolClass), () => {
			setSchoolClasses(schoolClasses.filter((u) => u.id !== schoolClass.id));
			SchoolClassService.DeleteSchoolClass(schoolClass.id).catch((e) => console.error("Failed to delete the school class", e));
		});
	};

	const SchoolClassesHTMLList = schoolClasses
		.filter((sc) => {
			if (!filterClassLevel) return true;
			return filterClassLevel.value === sc.level;
		})
		.map((schoolClass) => {
			return (
				<tr key={schoolClass.id}>
					<td>
						{GetClassName(schoolClass)}
						<div className="info-text">
							{schoolClass.studentsNumber} {schoolClass.studentsNumber === 1 ? t("student") : t("students")}
						</div>
					</td>
					<td>
						<button
							onClick={() =>
								ShowStudentSelectModal({
									allStudents: allStudents,
									selectedStudents: schoolClass.students || [],
									title: `${GetClassName(schoolClass)} ${t("students")}`,
									onSave: (students) => AddStudentsToClass(schoolClass, students),
									onClose: () => {},
									intent: StudentSelectModalIntent.ForClass,
								})
							}
							className="btn blue text"
						>
							<Icon.Users />
							{`${t("Manage")} (${schoolClass.studentsNumber})`}
						</button>
					</td>
					<td>
						<button
							onClick={() =>
								ShowManageClassCoursesModal({
									allCourses: allCourses,
									onSave: () => {
										LoadClasses();
									},
									schoolClass: schoolClass,
									allStudents: allStudents,
								})
							}
							className="btn blue text"
						>
							<Icon.Book />
							{`${t("Manage")} (${schoolClass.courses ? schoolClass.courses.length : 0})`}
						</button>
					</td>
					<td>
						<TextButton text={t("Edit")} icon={<Icon.Edit />} onClick={() => ShowManageClassModal(ManageModalAction.Edit, schoolClass.id, LoadClasses)} />
					</td>
					<td>
						<IconButton
							size={Size.SM}
							color="red"
							icon={<Icon.Trash2 />}
							onClick={() => {
								DeleteClass(schoolClass);
							}}
						/>
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div className="controls-above-table">
				<div className="filter">
					<Select placeholder={t("Filter by level...")} options={ClassLevelSelectOptions} onChange={setFilterClassLevel} isClearable={true} />
				</div>
				<div className="actions">
					<button onClick={() => ShowManageClassModal(ManageModalAction.Create, -1, LoadClasses)} className="btn blue">
						<Icon.PlusSquare />
						{t("Create")}
					</button>
				</div>
			</div>
			<table className="table">
				<thead>
					<tr>
						{/* <th style={{ minWidth: "32px" }}>#</th> */}
						<th style={{ minWidth: 250, position: "relative" }}>
							{t("Class")}
							{loadingClasses && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Students")}</th>
						<th>{t("Courses")}</th>
						<th>{t("Edit")}</th>
						<th>{t("Delete")}</th>
					</tr>
				</thead>
				<tbody>{SchoolClassesHTMLList}</tbody>
			</table>

			{SchoolClassesHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
