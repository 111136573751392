import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { HFLettersSelectOptions, ManageModalAction } from "../../../Constants";
import SubjectCategory from "../../../model/SubjectCategory";
import * as SubjectService from "../../../services/Subject";
import * as SubjectCategoryService from "../../../services/SubjectCategory";
import Subject from "../../../model/Subject";
import * as Icon from "react-feather";
import NormalButton from "../../../components/input/NormalButton";

const modalContainer = document.querySelector("#manageSubjectModalContainer");
type ManagerUserModalProps = { action: ManageModalAction; subjectId: number; onComplete: Function };

/**
 * Show manage subject modal.
 * @param action ManageModalAction.Edit or ManageModalAction.Create.
 * @param subjectId id of the subject to edit. Required only when editing a subject.
 * @param onComplete callback when user saves, creates or deletes a subject.
 */
export function ShowManageSubjectModal(action: ManageModalAction, subjectId: number, onComplete: Function) {
	ReactDOM.render(<ManageSubjectModal action={action} subjectId={subjectId} onComplete={onComplete} />, modalContainer);
}

function ManageSubjectModal({ action, subjectId, onComplete }: ManagerUserModalProps) {
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [subjectCategory, setSubjectCategory] = useState<{ value: SubjectCategory; label: string } | null>();
	const [subjectCategories, setSubjectCategories] = useState<{ value: SubjectCategory; label: string }[]>([]);
	const [isHF, setIsHF] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState("");
	const [selectedLetterOption, setSelectedHFLetterOption] = useState(HFLettersSelectOptions[0]); // use the first letter by default
	const firstInput = useRef<HTMLInputElement>(null);

	//if editing, fetch the user we are editing and fill the fields
	useEffect(() => {
		firstInput.current?.focus();
		LoadSubjectsCategories();
	}, []);

	const LoadSubjectsCategories = () => {
		SubjectCategoryService.GetSubjectCategories(false).then((scs) => {
			let formatted: { value: SubjectCategory; label: string }[] = [];
			scs.forEach((s) => formatted.push({ value: s, label: s.name }));
			setSubjectCategories(formatted);

			if (action === ManageModalAction.Edit)
				SubjectService.GetSubject(subjectId).then((subject) => {
					console.log(subject);
					setName(subject.name);
					setIsHF(subject.hf);
					if (subject.hfLetter !== "") setSelectedHFLetterOption(HFLettersSelectOptions.filter((o) => o.value === subject.hfLetter)[0]);
					setSubjectCategory(formatted.filter((s) => s.value.id === subject.subjectCategory?.id)[0]);
				});
		});
	};

	const CloseModal = () => {
		if (modalContainer != null) ReactDOM.unmountComponentAtNode(modalContainer);
	};

	/** Returns a User with data from the popup fields. */
	const CreateSubjectObjectFromFields = (): Subject => {
		return {
			id: action === ManageModalAction.Edit ? subjectId : -1, //if we are creating the subject the id doesn't metter, it will be set to null on the server anyways
			name: name,
			subjectCategory: subjectCategory?.value,
			hf: isHF,
			hfLetter: selectedLetterOption.value,
		};
	};

	const CreateSubject = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let subject = CreateSubjectObjectFromFields();
		let result;
		try {
			result = await SubjectService.CreateSubject(subject);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseModal();
		} catch (e) {
			let response = e.response.data;
			console.log("Subject create error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const EditSubject = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let subject = CreateSubjectObjectFromFields();
		let result;
		try {
			result = await SubjectService.UpdateSubject(subject);
			console.log(result);
			onComplete();
			setIsSaving(false);
			CloseModal();
		} catch (e) {
			let response = e.response.data;
			console.log("Subject update error", response);
			ParseError(response);
			setIsSaving(false);
		}
	};

	const ParseError = (response: { message: string; code: string }) => {
		switch (response.code) {
			case "missing_name":
				setError(t("Please enter the subject name!"));
				break;
			case "missing_category":
				setError(t("Please select a subject category!"));
				break;
			default:
				setError(response.message);
		}
	};

	return (
		<div className="modal">
			<div className="content" style={{ minWidth: "400px" }}>
				<div className="title">{action === ManageModalAction.Edit ? t("Edit subject") : t("Create subject")}</div>
				<div className="close-button" onClick={CloseModal}>
					<Icon.X />
				</div>
				<div>
					<table className="table-with-inputs vertically-centered-labels">
						<tbody>
							<tr>
								<td>{t("Name")}</td>
								<td>
									<input ref={firstInput} value={name} onChange={(e) => setName(e.currentTarget.value)} type="text" placeholder={t("Subject name...")} />
								</td>
							</tr>
							<tr>
								<td>{t("Category")}</td>
								<td>
									<Select placeholder={t("Select category...")} options={subjectCategories} value={subjectCategory} onChange={(v) => setSubjectCategory(v)} />
								</td>
							</tr>
							<tr>
								<td>{t("HF")}</td>
								<td style={{ display: "flex", alignItems: "center" }}>
									<input type="checkbox" checked={isHF} style={{ margin: 0 }} onChange={(e) => setIsHF(e.currentTarget.checked)} />
									{isHF && (
										<div style={{ width: 150, marginLeft: 8 }}>
											<Select
												options={HFLettersSelectOptions}
												value={selectedLetterOption}
												onChange={(v) => setSelectedHFLetterOption(v || HFLettersSelectOptions[0])}
											/>
										</div>
									)}
								</td>
							</tr>
						</tbody>
					</table>

					{error.length > 0 && <div className="error-banner">{error}</div>}

					<div style={{ textAlign: "right" }}>
						{action === ManageModalAction.Edit ? (
							<NormalButton onClick={EditSubject} loading={isSaving} icon={<Icon.Save />} text={t("Save")} />
						) : (
							<NormalButton onClick={CreateSubject} loading={isSaving} icon={<Icon.Save />} text={t("Create")} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
