import React from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { ShowErrorPopup } from "../../../components/ErrorPopup";
import { ShowGeneralModal } from "../../../components/GeneralModal";
import NormalButton from "../../../components/input/NormalButton";
import i18n from "../../../i18n";
import Student from "../../../model/Student";
import { GenerateEvaluationPdfPreview, GeneratePdfForStudent } from "../../../services/PdfGenerator";
import Evaluation from "../../../model/Evaluation";

/**
 * The PDF preview modal generates a PDF for a student and shows it in an iframe.
 */
export const ShowPDFModalForStudent = async (student: Student, onLoaded: () => any) => {
	try {
		let serverResponse = await GeneratePdfForStudent(student.id);
		let response = JSON.parse(serverResponse.data);
		if (response.status === "success") {
			ShowGeneralModal({
				title: student.name,
				content: <PdfPreviewModal pdfLink={response.link} />,
			});
		} else {
			ShowErrorPopup(i18n.t("PDF generation failed. If it happens again please contact us."));
			console.log("Failed to generate PDF", response);
		}
	} catch (e) {
		if (e.response?.data?.code === "no_evaluations") {
			ShowErrorPopup(
				i18n.t("This student doesn't have any evaluations yet. Please add him/her to a class with courses and the evaluations will be created automatically.")
			);
		} else {
			ShowErrorPopup(i18n.t("PDF generation failed. If it happens again please contact us."));
		}
	}
	onLoaded();
};

export const ShowEvaluationPDFModal = async (evaluation : Evaluation, onLoaded: () => any) => {
	try {
		let serverResponse = await GenerateEvaluationPdfPreview(evaluation.id);
		let response = JSON.parse(serverResponse.data);
		if (response.status === "success") {
			ShowGeneralModal({
				title: evaluation.student.name,
				content: <PdfPreviewModal pdfLink={response.link} />,
			});
		} else {
			ShowErrorPopup(i18n.t("PDF generation failed. If it happens again please contact us."));
			console.log("Failed to generate PDF", response);
		}
	} catch (e : any) {
		if (e.response?.data?.code === "no_evaluations") {
			ShowErrorPopup(
				i18n.t("This student doesn't have any evaluations yet. Please add him/her to a class with courses and the evaluations will be created automatically.")
			);
		} else {
			ShowErrorPopup(i18n.t("PDF generation failed. If it happens again please contact us."));
		}
	}
	onLoaded();
};

type PdfPreviewModalProps = { pdfLink: string };
function PdfPreviewModal({ pdfLink }: PdfPreviewModalProps) {
	const { t } = useTranslation();
	// const [pdfLoaded, setPdfLoaded] = useState(false);
	// const [error, setError] = useState("");
	// const [pdfLink, setPdfLink] = useState("");
	// const { t } = useTranslation();

	// useEffect(() => {
	// 	LoadPdf();
	// }, []);

	// const LoadPdf = async () => {
	// 	try {
	// 		let serverResponse = await GeneratePdfForStudent(student.id);
	// 		let response = JSON.parse(serverResponse.data);
	// 		if (response.status === "success") {
	// 			setPdfLoaded(true);
	// 			setPdfLink(response.link);
	// 		} else {
	// 			setError(t("PDF generation failed. If it happens again please contact us."));
	// 			console.log("Failed to generate PDF", response);
	// 		}
	// 	} catch (e) {
	// 		if (e.response?.data?.code === "no_evaluations") {
	// 			setError(
	// 				t("This student doesn't have any evaluations yet. Please add him/her to a class with courses and the evaluations will be created automatically.")
	// 			);
	// 		} else {
	// 			setError(t("PDF generation failed. If it happens again please contact us."));
	// 		}
	// 	}
	// };

	// const CloseModal = () => {
	// 	if (ManageModalContainer != null) ReactDOM.unmountComponentAtNode(ManageModalContainer);
	// };

	const Download = () => {
		var link = document.createElement("a");
		link.target = "_blank";
		link.href = pdfLink;
		//link.download = `${student.name}.pdf`;
		link.dispatchEvent(new MouseEvent("click"));
	};

	return (
		<div>
			<iframe title="PDF preview" src={pdfLink} style={{ width: "80vw", height: "75vh" }}></iframe>
			<div style={{ textAlign: "right" }}>
				<NormalButton text={t("Download")} onClick={Download} icon={<Icon.Download />} />
			</div>
		</div>
	);

	// return (
	// 	<div className="modal">
	// 		<div className="content">
	// 			<div className="close-button" onClick={CloseModal}>
	// 				<Icon.X />
	// 			</div>
	// 			<div className="title">{student.name}</div>
	// 			{!pdfLoaded && !error && <LoadingIndicator style={{ margin: "auto" }} />}
	// 			{pdfLoaded && (
	// 				<div>
	// 					<iframe title="PDF preview" src={pdfLink} style={{ width: "80vw", height: "75vh" }}></iframe>
	// 					<div style={{ textAlign: "right" }}>
	// 						<button className="btn blue" onClick={Download}>
	// 							{t("Download")}
	// 						</button>
	// 					</div>
	// 				</div>
	// 			)}
	// 			{error && <div className="error-banner">{error}</div>}
	// 		</div>
	// 	</div>
	// );
}
