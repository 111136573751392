import Template from "../model/Template";
import Axios from "./CustomAxios";

export const GetTemplateForCurrentSchool = async (): Promise<Template> => {
	return (await Axios.get(`/rsvidnesbyrd/template/currentSchoolTemplate`)).data;
};

/**
 * The API updates only the existing fields, you don't have to pass all of them.
 */
export const UpdateTemplate = async (template: Template): Promise<Template> => {
	return (await Axios.put(`/rsvidnesbyrd/template/update`, template)).data;
};
