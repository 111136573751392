import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommentFeed from "../../components/ComentFeed";
import Evaluation from "../../model/Evaluation";
import DescriptionSection from "./DescriptionSection";
import * as EvaluationService from "../../services/Evaluation";
import { ShowGeneralModal } from "../../components/GeneralModal";
import { ShowEvaluationPDFModal } from "../admin/generatePdf/PdfPreviewModal";
import TextButton from "../../components/input/TextButton";

/**
 * Show the modal to edit the evaluation student description. Can be used by both teacher and proofreader, and will appear differently for them.
 * @param evaluationid id the of the evaluation to load
 * @param onSave an action to perform when the modal is saved (refresh the evaluation list perhaps)
 * @param onLoadingFinished when the evaluation is loaded and the modal is showed, this callback is called.
 */
export const ShowStudentDescriptionModal = async (evaluationId: number, onSave: () => any, onLoadingFinished: () => any) => {
	let evaluation = await EvaluationService.GetEvaluation(evaluationId);
	onLoadingFinished();
	ShowGeneralModal({
		content: <StudentDescriptionModal evaluation={evaluation} onSave={onSave} />,
	});
};

/**
 * Common modal body for teacher and proofreader to edit/proofread the student evaluation.
 */
type StudentDescriptionModalProps = { evaluation: Evaluation; onSave: () => any };
export default function StudentDescriptionModal({ evaluation, onSave }: StudentDescriptionModalProps) {
	const { t } = useTranslation();
	const [hasWrittenComment, setHasWrittenComment] = useState(false); // track if a new comment was written since the modal was opened, to allow/prevent rejecting description
	const [pdfLoading, setPdfLoading] = useState(false);
	const previewEvaluation = () => {
		setPdfLoading(true)
		ShowEvaluationPDFModal(evaluation, ()=>{setPdfLoading(false)})
	};

	return (
		<div style={{ display: "flex", width: "70vw", height: "80vh" }}>
			{/* DESCRIPTION FIELDS */}
			<div
				className="flexCol1"
				style={{
					flex: 0.7,
					marginRight: 24,
					borderRight: "1px solid rgba(0,0,0,0.12)",
					paddingRight: 24,
				}}
			>
				<div style={{ fontSize: "1.5rem", color: "black", fontWeight: 500, marginBottom: "4px" }}>{evaluation.student.name}</div>
				<div>
					<b>{t("Subject")}:</b> {evaluation.course.name}
					<br />
					<b>{t("Attendance")}:</b>&nbsp;
					{evaluation.attendacePercentage != null && evaluation.attendacePercentage !== undefined ? `${evaluation.attendacePercentage}%` : t("missing")}
				</div>
				<TextButton text={t("Preview PDF")} onClick={previewEvaluation} loading={pdfLoading} />
				<DescriptionSection hasWrittenComment={hasWrittenComment} description={evaluation.description} onSave={onSave} />{" "}
			</div>
			{/* COMMENTS */}
			<div style={{ flex: 0.3, display: "flex", alignItems: "stretch", justifyContent: "stretch" }}>
				{evaluation.description && (
					<CommentFeed
						descriptionId={evaluation.description.id}
						onCommentAdd={() => {
							setHasWrittenComment(true);
						}}
					/>
				)}
			</div>
		</div>
	);
}
