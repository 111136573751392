import { ManageModalAction, Size } from "../../../Constants";
import { ShowManageStudentModal } from "./ManageStudentModal";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import * as StudentService from "../../../services/Student";
import Student from "../../../model/Student";
import * as Icon from "react-feather";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import LoadingIndicator from "../../../components/LoadingIndicator";
import IconButton from "../../../components/input/IconButton";
import { ShowDeleteModal } from "../../../components/ConfirmationModal";
import TextButton from "../../../components/input/TextButton";

/**
 * Displays the list of students in a table for the admin.
 */
export default function ManageStudentsPage() {
	const { t } = useTranslation();
	const [students, setStudents] = useState<Student[]>([]);
	const [loadingStudents, setLoadingStudents] = useState(false);
	const [filterName, setFilterName] = useState("");

	useEffect(() => {
		LoadStudents();
	}, []);

	const LoadStudents = () => {
		setLoadingStudents(true);
		StudentService.GetAllStudents().then((_students) => {
			setStudents(_students);
			setLoadingStudents(false);
		});
	};

	const DeleteStudent = (student: Student) => {
		ShowDeleteModal(student.name, () => {
			setStudents(students.filter((u) => u.id !== student.id));
			StudentService.DeleteStudent(student.id).catch((e) => console.error("Failed to delete the student", e));
		});
	};

	const StudentsHTMLList = students
		.filter((student) => {
			//filter by student name
			if (!filterName) return true;
			return student.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.map((student) => {
			return (
				<tr key={student.id}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={student.name} />
							<div style={{ marginLeft: 8 }}>
								<div>{student.name}</div>
							</div>
						</div>
					</td>
					<td>
						<TextButton icon={<Icon.Edit />} text={t("Edit")} onClick={() => ShowManageStudentModal(ManageModalAction.Edit, student.id, LoadStudents)} />
					</td>
					<td>
						<IconButton
							size={Size.SM}
							color="red"
							icon={<Icon.Trash2 />}
							onClick={() => {
								DeleteStudent(student);
							}}
						/>
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div className="controls-above-table">
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div className="actions">
					<button
						onClick={() => {
							ShowManageStudentModal(ManageModalAction.Create, -1, LoadStudents);
						}}
						className="btn blue"
					>
						<Icon.UserPlus />
						{t("Create")}
					</button>
				</div>
			</div>

			<table className="table">
				<thead>
					<tr>
						<th style={{ minWidth: "250px", position: "relative" }}>
							{t("Student")} {loadingStudents && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Actions")}</th>
						<th>{t("Delete")}</th>
					</tr>
				</thead>
				<tbody>{StudentsHTMLList}</tbody>
			</table>

			{StudentsHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
