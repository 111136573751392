import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Year from "../../../model/Year";

type CurrentSchoolYearProps = { LoadCurrentYear: () => any; currentSchoolYear: Year | undefined };

export default function CurrentSchoolYear({ LoadCurrentYear, currentSchoolYear }: CurrentSchoolYearProps) {
    const { t } = useTranslation();

    useEffect(() => {
        LoadCurrentYear();
    }, []);


    return (<span>
        {t("Current school year")} : {" "}
        {
            currentSchoolYear && (
                <span style={{ fontWeight: "bold" }}>
                    {currentSchoolYear.year}-{currentSchoolYear.year + 1}
                </span>
            )
        }
    </span>);
}