import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommentFeed from "../../components/ComentFeed";
import DescriptionSection from "./DescriptionSection";
import * as YearTaskService from "../../services/YearTask";
import { ShowGeneralModal } from "../../components/GeneralModal";
import YearTask from "../../model/YearTask";

/**
 * Show the modal to edit the year task student description. Can be used by both teacher and proofreader, and will appear differently for them.
 * @param yearTaskId id the of the year task to load
 * @param onSave an action to perform when the modal is saved (refresh the year tasks list perhaps)
 * @param onLoadingFinished when the year task is loaded and the modal is showed, this callback is called
 */
export const ShowYearTaskStudentDescriptionModal = async (yearTaskId: number, onSave: () => any, onLoadingFinished: () => any) => {
	let yearTask = await YearTaskService.GetYearTaskById(yearTaskId);
	onLoadingFinished();
	ShowGeneralModal({
		content: <YearTaskStudentDescriptionModal yearTask={yearTask} onSave={onSave} />,
	});
};

/**
 * Common modal body for teacher and proofreader to edit/proofread the student evaluation.
 */
type YearTaskStudentDescriptionModalProps = { yearTask: YearTask; onSave: () => any };
export default function YearTaskStudentDescriptionModal({ yearTask, onSave }: YearTaskStudentDescriptionModalProps) {
	const { t } = useTranslation();
	const [hasWrittenComment, setHasWrittenComment] = useState(false); // track if a new comment was written since the modal was opened, to allow/prevent rejecting description

	return (
		<div style={{ display: "flex", width: "70vw", height: "80vh" }}>
			{/* DESCRIPTION FIELDS */}
			<div
				className="flexCol1"
				style={{
					flex: 0.7,
					marginRight: 24,
					borderRight: "1px solid rgba(0,0,0,0.12)",
					paddingRight: 24,
				}}
			>
				<div style={{ fontSize: "1.5rem", color: "black", fontWeight: 500, marginBottom: "4px" }}>
					{yearTask.student?.name} ({t("Year task")})
				</div>
				{yearTask.studentDescription && (
					<DescriptionSection hasWrittenComment={hasWrittenComment} description={yearTask.studentDescription} onSave={onSave} />
				)}
			</div>
			{/* COMMENTS */}
			<div style={{ flex: 0.3, display: "flex", alignItems: "stretch", justifyContent: "stretch" }}>
				{yearTask.studentDescription && (
					<CommentFeed
						descriptionId={yearTask.studentDescription.id}
						onCommentAdd={() => {
							setHasWrittenComment(true);
						}}
					/>
				)}
			</div>
		</div>
	);
}
