type LoadingIndicatorProps = { style?: React.CSSProperties; inTable?: boolean };

/**
 * @param inButton if false, the loading indicator will have a predefined size. If true, nothing happens (its size will be set by css)
 * @param inTable if true, the loading indicator will have an absolute position so it goes a little bit outside table. Should be placed in the first td in table header, and that td needs position:relative.
 */
export default function LoadingIndicator({ style, inTable }: LoadingIndicatorProps) {
	let _style = { ...style };
	if (inTable) {
		_style = {
			..._style,
			position: "absolute",
			top: 8,
			left: -40,
		};
	}
	return (
		<div className={`sk-chase`} style={_style}>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
		</div>
	);
}
