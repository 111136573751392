import Select from "react-select";
import { useTranslation } from "react-i18next";
import * as CourseService from "../../services/Course";
import React, { useEffect, useState } from "react";
import Course from "../../model/Course";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ShowGeneralModal } from "../../components/GeneralModal";
import CourseDescriptionModal from "../common/CourseDescriptionModal";
import { DescriptionStatus, ProofreaderStatusFilterOptions } from "../../Constants";
import User from "../../model/User";
import _ from "lodash";

/**
 * Displays course descriptions for the proofreader in a table.
 * Only shows the courses assigned to the proofreader, or that are not assigned.
 * Only shows the descriptions with statuses "ready for proofread" or "proofread".
 */
export default function ProofReadCoursesPage() {
	const { t } = useTranslation();
	const [courses, setCourses] = useState<Course[]>([]); // list of courses, from which the description is extracted.
	const [teacherFilterOptions, setTeacherFilterOptions] = useState<{ value: string; label: string }[]>([]); // value => id of the teacher, label => teacher name
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ value: string; label: string } | null>(); // value => id of the teacher, label => teacher name
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator

	useEffect(() => {
		LoadCourses();
		setStatusFilterOptions(ProofreaderStatusFilterOptions);
	}, []);

	const LoadCourses = () => {
		setIsLoading(true);
		CourseService.GetCoursesForProofreading().then((_courses) => {
			let _allTeachers: User[] = [];
			_courses.forEach((c) => {
				c.teachers?.forEach((t) => _allTeachers.push(t));
			});
			_allTeachers = _.uniqBy(_allTeachers, "id"); // remove duplicate teachers
			setTeacherFilterOptions(
				_allTeachers.map((t) => ({
					value: t.id,
					label: t.name,
				}))
			);
			setCourses(_courses);
			setIsLoading(false);
		});
	};

	const ShowCourseModal = async (courseId: number) => {
		let course = await CourseService.GetCourse(courseId);
		ShowGeneralModal({
			content: <CourseDescriptionModal course={course} onSave={LoadCourses} />,
		});
	};

	const CoursesHTMLList = courses
		.filter((course) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!course.teachers) return true;
			return course.teachers.map((t) => t.id).includes(teacherFilterValue.value);
		})
		.filter((course) => {
			// filter by description status
			if (!statusFilterValue) return true;
			return course.description?.status === statusFilterValue.value;
		})
		.map((course) => {
			return (
				<tr key={course.id} onClick={() => ShowCourseModal(course.id)}>
					<td>
						<div>
							<div>{course.name}</div>
							<div className="info-text">{course.teachers?.map((t) => t.name).join(", ")}</div>
						</div>
					</td>
					<td>{course.description?.status && <DescriptionStatusBadge status={course.description.status} />}</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: 16, display: "flex" }}>
				<div style={{ width: 220 }}>
					<Select
						placeholder={t("Filter by teacher...")}
						value={teacherFilterValue}
						onChange={setTeacherFilterValue}
						options={teacherFilterOptions}
						isClearable={true}
					/>
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "780px" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Course")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Status")}</th>
					</tr>
				</thead>
				<tbody>{CoursesHTMLList}</tbody>
			</table>
			{CoursesHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
