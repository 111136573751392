import Check from "../resources/img/check.png";
import ExclamatiuonMark from "../resources/img/exclamation_mark.png";

export enum SimpleStatusBadgeStatus {
	OK,
	Error,
}

interface Props {
	status: SimpleStatusBadgeStatus;
}

export default function SimpleStatusBadge({ status }: Props) {
	return (
		<div
			style={{
				color: "white",
				backgroundColor: status === SimpleStatusBadgeStatus.OK ? "rgb(76, 175, 80)" : "red",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 20,
				width: 20,
				borderRadius: "50%",
				textAlign: "center",
				fontWeight: "bolder",
			}}
		>
			<img src={status === SimpleStatusBadgeStatus.OK ? Check : ExclamatiuonMark} style={{ height: 18 }} alt="Icon" />
		</div>
	);
}
