import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Cell, Pie, PieChart } from "recharts";
import { DescriptionStatus} from "../Constants";
import User from "../model/User";
import DescriptionStatusBadge, { DescriptionStatusColor } from "./DescriptionStatusBadge";
import SimpleStatusBadge, { SimpleStatusBadgeStatus } from "./ExclamationMarkCircle";
import ProfileColoredCircle from "./ProfileColoredCircle";
import { SchoolData } from "../services/School";

interface Props {
	courseData: any;
	schoolData: SchoolData;
}

const OverviewCard = ({ courseData, schoolData}: Props) => {
	const history = useHistory();
	const { t } = useTranslation();
	const [showCourseDescription] = useState<boolean>(schoolData.showCourseDescriptions || false);
	const outerChartRadius = 50;
	const innerChartRadius = 30;

	const OnCardClick = () => {
		history.push(`/course/${courseData.courseId}`);
	};

	/** Builds a row in the table with the legend for the pie chart */
	const StudentRow = (status: DescriptionStatus, index: number, courseData: any) => {
		return (
			<tr>
				<td style={{ textAlign: "right", paddingRight: 6 }}>{t(`descriptionStatus.${status}`)}</td>
				<td>
					<div className="badge" style={{ backgroundColor: DescriptionStatusColor[index], minWidth: 20, textAlign: "center" }}>
						{courseData[`students_${status}`]}
					</div>
				</td>
			</tr>
		);
	};

	const data = [
		{ name: t(`descriptionStatus.${DescriptionStatus.Created}`), value: parseInt(courseData[`students_${DescriptionStatus.Created}`]) },
		{
			name: t(`descriptionStatus.${DescriptionStatus.ReadyForProofRead}`),
			value: parseInt(courseData[`students_${DescriptionStatus.ReadyForProofRead}`]),
		},
		{ name: t(`descriptionStatus.${DescriptionStatus.ProofRead}`), value: parseInt(courseData[`students_${DescriptionStatus.ProofRead}`]) },
		{ name: t(`descriptionStatus.${DescriptionStatus.Done}`), value: parseInt(courseData[`students_${DescriptionStatus.Done}`]) },
	];

	const cardStyle: React.CSSProperties = { display: "flex", marginBottom: 16, justifyContent: "space-between", alignItems: "flex-start" };
	cardStyle.cursor = "pointer"; 

	return (
		<div className="card" style={cardStyle} onClick={OnCardClick}>
			<div style={{ marginRight: 32, paddingRight: 32, width: 225, borderRight: "1px solid #D8D8D8" }}>
				<div className="title" style={{ marginTop: 0 }}>
					{courseData.courseName}
				</div>
				{/* COURSE DESCRIPTION STATUS */}
				{ showCourseDescription &&
				<div>
					{t("Course description")}: <DescriptionStatusBadge status={courseData.courseDescriptionStatus} /> 
				</div>
				}
				{/* FILLED ABSENCES */}
				{ !(courseData.filledAbsences === 0 && courseData.totalStudentsForAbsence === 0) &&
					<div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
					{t("Absences")}: {courseData.filledAbsences}/{courseData.totalStudentsForAbsence}
					&nbsp;&nbsp;{" "}
					<SimpleStatusBadge status={courseData.filledAbsences === courseData.totalStudentsForAbsence ? SimpleStatusBadgeStatus.OK : SimpleStatusBadgeStatus.Error} />
					</div>
				}
				{/* TEACHER LIST */}
				<div style={{ marginTop: 8 }}>
					<div className="label">{t("Teacher(s)")}</div>
					{(courseData.courseTeacherNames as User[])
						?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
						.map((t) => (
							<div key={t.id} style={{ display: "flex", alignItems: "center", marginTop: 4, marginBottom: 4 }}>
								<ProfileColoredCircle username={t.name || ""} size="24px" />
								&nbsp;<div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{t.name}</div>
							</div>
						))}
				</div>
			</div>
			<div style={{ display: "flex", alignItems: "flex-end" }}>
				<div style={{ marginRight: 12 }}>
					<table>
						<tbody>
							<tr>
								<td style={{ textAlign: "right", paddingRight: 6 }}>
									<b>{t("Student evaluations")}</b>
								</td>
								<td></td>
							</tr>
							{StudentRow(DescriptionStatus.Created, 0, courseData)}
							{StudentRow(DescriptionStatus.ReadyForProofRead, 1, courseData)}
							{StudentRow(DescriptionStatus.ProofRead, 2, courseData)}
							{StudentRow(DescriptionStatus.Done, 3, courseData)}
						</tbody>
					</table>
				</div>
				<PieChart width={outerChartRadius * 2} height={outerChartRadius * 2} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
					<Pie
						data={data}
						cx={outerChartRadius}
						cy={outerChartRadius}
						innerRadius={innerChartRadius}
						outerRadius={outerChartRadius}
						paddingAngle={2}
						dataKey="value"
						isAnimationActive={false}
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={DescriptionStatusColor[index]} />
						))}
					</Pie>
				</PieChart>
			</div>
		</div>
	);
};

export default React.memo(OverviewCard);
