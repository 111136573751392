import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { ShowGeneralModal } from "../../../components/GeneralModal";
import NormalButton from "../../../components/input/NormalButton";
import TextButton from "../../../components/input/TextButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProfileColoredCircle from "../../../components/ProfileColoredCircle";
import Student from "../../../model/Student";
import { GetAllStudents } from "../../../services/Student";
import { GeneratePDFPerClassModal } from "./GeneratePDFPerClassModal";
import PdfFields from "./PdfFields";
import { ShowPDFModalForStudent } from "./PdfPreviewModal";

export default function GeneratePdfPage() {
	const { t } = useTranslation();
	const [students, setStudents] = useState<Student[]>([]);
	const [loadingStudents, setLoadingStudents] = useState(false);
	const [filterName, setFilterName] = useState("");

	useEffect(() => {
		LoadStudents();
	}, []);

	const LoadStudents = () => {
		setLoadingStudents(true);
		GetAllStudents().then((_students) => {
			setStudents(_students);
			setLoadingStudents(false);
		});
	};

	const ShowClassSelectModal = () => {
		ShowGeneralModal({
			content: <GeneratePDFPerClassModal />,
			title: t("Generate PDF per class"),
		});
	};

	const SetStudentLoading = (student: Student, loading: boolean) => {
		let _students = _.cloneDeep(students);
		_students.forEach((s) => {
			if (s.id === student.id) s.loading = loading;
		});
		setStudents(_students);
	};

	const OnStudentClick = (student: Student) => {
		SetStudentLoading(student, true);
		ShowPDFModalForStudent(student, () => SetStudentLoading(student, false));
	};

	const StudentsHTMLList = students
		.filter((student) => {
			//filter by student name
			if (!filterName) return true;
			return student.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.map((student) => {
			return (
				<tr key={student.id}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={student.name} />
							<div style={{ marginLeft: 8 }}>
								<div>{student.name}</div>
							</div>
						</div>
					</td>
					<td>
						{!student.evaluationCount ? (
							<div style={{ color: "rgba(0,0,0,0.4)" }}>{t("No evaluations")}</div>
						) : (
							<TextButton onClick={() => OnStudentClick(student)} text={t("Preview PDF")} loading={student.loading} />
						)}
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-flex", alignItems: "flex-start" }}>
			<div>
				<div className="controls-above-table">
					<div className="input-with-search">
						<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
						<Icon.Search />
					</div>
					<div className="actions"></div>
					<NormalButton text={t("Per class")} onClick={ShowClassSelectModal} icon={<Icon.Users />} style={{ marginLeft: 8 }} />
				</div>
				<table className="table">
					<thead>
						<tr>
							<th style={{ minWidth: "250px", position: "relative" }}>
								{t("Student")} {loadingStudents && <LoadingIndicator inTable={true} />}
							</th>
							<th>{t("Actions")}</th>
						</tr>
					</thead>
					<tbody>{StudentsHTMLList}</tbody>
				</table>
				{StudentsHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
			</div>
			<PdfFields />
		</div>
	);
}
