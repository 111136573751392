import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ManageModalAction, UserRoles, UserRolesSelectOptions } from "../../../Constants";
import User from "../../../model/User";
import * as UserService from "../../../services/User";
import * as Icon from "react-feather";
import NormalButton from "../../../components/input/NormalButton";
import { CloseGeneralModal, ShowGeneralModal } from "../../../components/GeneralModal";
import i18n from "../../../i18n";

export const ShowCreateUserModal = (OnUserModalComplete: () => any) => {
	ShowGeneralModal({
		title: i18n.t("Create user"),
		content: (
			<ManageUserModal
				action={ManageModalAction.Create}
				userId={""}
				onComplete={() => {
					OnUserModalComplete();
					CloseGeneralModal();
				}}
				fromHeader={false}
			/>
		),
		contentStyle: {
			width: 400,
		},
	});
};

export const ShowEditUserModal = (userId: string, OnUserModalComplete: () => any, fromHeader?: boolean) => {
	ShowGeneralModal({
		title: i18n.t("Edit user"),
		content: (
			<ManageUserModal
				action={ManageModalAction.Edit}
				userId={userId}
				onComplete={() => {
					OnUserModalComplete();
					CloseGeneralModal();
				}}
				fromHeader={fromHeader ? true : false}
			/>
		),
		contentStyle: {
			width: 400,
		},
	});
};

/**
 * @param fromHeader means that the modal is opened from header when a user wants to edit his own profile, so the functionality of the modal should be limited (ie. no delete button, no edit role)
 */
type ManagerUserModalProps = { action: ManageModalAction; userId: string; onComplete: Function; fromHeader: boolean };
function ManageUserModal({ action, userId, onComplete, fromHeader }: ManagerUserModalProps) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [roles, setRoles] = useState<{ label: string; value: UserRoles }[]>([]); //used directly in <Select/>
	const [error, setError] = useState("");
	const [isSaving, setIsSaving] = useState(false);
	const { t } = useTranslation();
	const firstInput = useRef<HTMLInputElement>(null);

	//if editing, fetch the user we are editing and fill the fields
	useEffect(() => {
		firstInput.current?.focus();
		if (action === ManageModalAction.Edit)
			UserService.GetUser(userId).then((user) => {
				setUsername(user.username);
				setName(user.name);
				setEmail(user.email);
				setPhoneNumber(user.phoneNumber);
				setRoles(UserRolesSelectOptions.filter((u) => user.roles.includes(u.value)));
			});
	}, [action, userId]);

	/** Returns a User with data from the popup fields. */
	const CreateUserObjectFromFields = (): User => {
		return {
			id: action === ManageModalAction.Edit ? userId : "", //if we are creating the user the id field is not necessary
			username: username,
			password: password,
			roles: roles.map((r) => r.value),
			name: name,
			email: email,
			phoneNumber: phoneNumber,
		};
	};

	const CreateUser = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let user = CreateUserObjectFromFields();
		let result;
		try {
			result = await UserService.CreateUser(user);
			console.log(result);
			onComplete();
			setIsSaving(false);
		} catch (e) {
			setIsSaving(false);
			let response = e.response.data;
			console.log("User create error", response);
			DisplayError(response);
		}
	};

	const EditUser = async () => {
		if (isSaving) return;
		setIsSaving(true);
		let user = CreateUserObjectFromFields();
		let result;
		try {
			result = await UserService.UpdateUser(user);
			console.log(result);
			onComplete();
			setIsSaving(false);
		} catch (e) {
			setIsSaving(false);
			let response = e.response.data;
			console.log("User update error", response);
			DisplayError(response);
		}
	};

	const DisplayError = (response: any) => {
		switch (response.code) {
			case "username_in_use":
				setError(t("Username already in use"));
				break;
			case "email_in_use":
				setError(t("There is already a registered user with this email"));
				break;
			case "missing_name":
				setError(t("Person name is missing"));
				break;
			case "missing_username":
				setError(t("Username is missing"));
				break;
			case "username_or_email_in_use":
				setError(t("Username or email already in use"));
				break;
			default:
				setError(response.message);
		}
	};

	return (
		<div>
			<table className="table-with-inputs vertically-centered-labels">
				<tbody>
					<tr className="title">
						<td></td>
						<td>{t("Login details")}</td>
					</tr>
					<tr>
						<td>{t("Username")}</td>
						<td>
							<input ref={firstInput} value={username} onChange={(e) => setUsername(e.currentTarget.value)} type="text" placeholder={t("Username")} />
						</td>
					</tr>
					<tr>
						<td>{t("Password")}</td>
						<td>
							<input value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password" placeholder={t("Password")} />
							{action === ManageModalAction.Edit && <div className="info-text">{t("To change the password, type a new password and click Save.")}</div>}
						</td>
					</tr>
					<tr className="title">
						<td></td>
						<td>{t("Personal details")}</td>
					</tr>
					<tr>
						<td>{t("Name")}</td>
						<td>
							<input value={name} onChange={(e) => setName(e.currentTarget.value)} type="text" placeholder={t("Name")} />
						</td>
					</tr>
					<tr>
						<td>{t("Email")}</td>
						<td>
							<input value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="text" placeholder={t("Email")} />
						</td>
					</tr>
					<tr>
						<td>{t("Phone")}</td>
						<td>
							<input value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} type="text" placeholder={t("Phone")} />
						</td>
					</tr>
					{!fromHeader && (
						<tr>
							<td>{t("Role")}</td>
							<td>
								<Select
									isMulti
									value={roles}
									onChange={(value) => {
										if (!value) {
											setRoles([]);
										} else {
											setRoles(value.map((a) => a));
										}
									}}
									options={UserRolesSelectOptions}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{error.length > 0 && <div className="error-banner">{error}</div>}

			<div style={{ textAlign: "right" }}>
				{action === ManageModalAction.Edit ? (
					<NormalButton text={t("Save")} loading={isSaving} icon={<Icon.Save />} onClick={EditUser} />
				) : (
					<NormalButton text={t("Create")} loading={isSaving} icon={<Icon.Save />} onClick={CreateUser} />
				)}
			</div>
		</div>
	);
}
