import * as _ from "lodash";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DescriptionStatusBadge from "../../components/DescriptionStatusBadge";
import LoadingIndicator from "../../components/LoadingIndicator";
import ProfileColoredCircle from "../../components/ProfileColoredCircle";
import { DescriptionStatus, ProofreaderStatusFilterOptions, TaskType} from "../../Constants";
import Task from "../../model/Task";
import * as SSOService from "../../services/Task";
import { ShowTaskStudentDescriptionModal } from "../common/TaskStudentDescriptionModal";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/store";

interface Props {
	taskType: TaskType;
}

/**
 * Displays SSO student descriptions for the proofreader in a table.
 */
export default function ProofReadTasktudentDescription({ taskType }: Props) {
	const { t } = useTranslation();
	const [ssoList, setSSOList] = useState<Task[]>([]);
	const [teacherFilterOptions, setTeacherFilterOptions] = useState<{ label: string; value: string }[]>([]); // label => name of the teacher, value => id of the teacher
	const [teacherFilterValue, setTeacherFilterValue] = useState<{ label: string; value: string } | null>(); // label => name of the teacher, value => id of the teacher
	const [statusFilterOptions, setStatusFilterOptions] = useState<{ value: DescriptionStatus; label: string }[]>([]); // value => status key, label => status name
	const [statusFilterValue, setStatusFilterValue] = useState<{ value: DescriptionStatus; label: string } | null>(); // value => status key, label => status name
	const [filterName, setFilterName] = useState(""); // used to filter by the student name from the text input
	const [isLoading, setIsLoading] = useState(false); // shows/hides the loading indicator in table header
	const userRole = useSelector((state: RootState) => state.activeRole);

	useEffect(() => {
		LoadSSOs();
		setStatusFilterOptions(ProofreaderStatusFilterOptions);
	}, [taskType, userRole]);

	const LoadSSOs = () => {
		setIsLoading(true);
		SSOService.GetAllAvailableTasks(taskType , userRole).then((_sso) => {
			setSSOList(_sso);

			// build filter options based on values from the evaluations (ex the course and the teacher)
			let _teacherFilterOptions: { label: string; value: string }[] = [];
			_sso.forEach((_evaluation) => {
				_evaluation.teachers?.forEach((t) => {
					_teacherFilterOptions.push({
						label: t.name,
						value: t.id,
					});
				});
			});

			// remove duplicate values from the filter options
			setTeacherFilterOptions(_.uniqBy(_teacherFilterOptions, "value"));

			setIsLoading(false);
		});
	};

	const ShowSSOStudentDescriptionProofreadModal = async (sso: Task) => {
		SetSSOLoading(sso.id, true);
		ShowTaskStudentDescriptionModal(sso.id, LoadSSOs, () => SetSSOLoading(sso.id, false));
	};

	// sets loading for a sso, so the loading indicator appears in the table row near the edit button
	const SetSSOLoading = (ssoId: number, loading: boolean) => {
		let _tmp = [...ssoList];
		_tmp.filter((e) => e.id === ssoId)[0].loading = loading;
		setSSOList(_tmp);
	};

	let SSOHTMLList = ssoList
		.filter((sso) => {
			// filter by student name
			if (!filterName) return true;
			return sso.student?.name.toLowerCase().includes(filterName.toLowerCase());
		})
		.filter((sso) => {
			// filter by teacher
			if (!teacherFilterValue) return true;
			if (!sso.teachers) return true;
			return sso.teachers.map((t) => t.id).includes(teacherFilterValue.value);
		})
		.filter((sso) => {
			// filter by description status
			if (!statusFilterValue) return true;
			return sso.studentDescription?.status === statusFilterValue.value;
		})
		.map((sso) => {
			return (
				<tr key={sso.id} onClick={() => ShowSSOStudentDescriptionProofreadModal(sso)}>
					<td>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfileColoredCircle size="32px" username={sso.student?.name || ""} />
							<div style={{ marginLeft: 8 }}>
								<div>{sso.student?.name || ""}</div>
							</div>
						</div>
					</td>
					<td>{sso.teachers?.map((t) => t.name).join(", ")}</td>
					<td style={{ position: "relative" }}>
						<DescriptionStatusBadge status={sso.studentDescription?.status || DescriptionStatus.Created} />
						{sso.loading && <LoadingIndicator style={{ position: "absolute", right: 16 }} />}
					</td>
				</tr>
			);
		});

	return (
		<div style={{ display: "inline-block" }}>
			<div style={{ marginBottom: "16px", display: "flex" }}>
				<div className="input-with-search">
					<input type="text" placeholder={t("Filter by name...")} value={filterName} onChange={(e) => setFilterName(e.currentTarget.value)} />
					<Icon.Search />
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by teacher...")}
						value={teacherFilterValue}
						options={teacherFilterOptions}
						onChange={setTeacherFilterValue}
						isClearable={true}
					/>
				</div>
				<div style={{ width: 220, marginLeft: 8 }}>
					<Select
						placeholder={t("Filter by status...")}
						value={statusFilterValue}
						onChange={setStatusFilterValue}
						options={statusFilterOptions}
						isClearable={true}
					/>
				</div>
			</div>
			<table className="table interactible" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ position: "relative" }}>
							{t("Student")} {isLoading && <LoadingIndicator inTable={true} />}
						</th>
						<th>{t("Teacher")}</th>
						<th>{t("Status")}</th>
					</tr>
				</thead>
				<tbody>{SSOHTMLList}</tbody>
			</table>
			{SSOHTMLList.length === 0 && <div className="nothing-to-display-banner"> {t("There is nothing to display")}</div>}
		</div>
	);
}
